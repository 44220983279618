import React from 'react';

function Footer(props) {
    const themeStyles = {
        '--color-sell': '#F6465D',
        '--color-textSell': '#F6465D',
        '--color-sellHover': '#FF707E',
        '--color-depthSellBg': '#35141D',
        '--color-buy': '#0ECB81',
        '--color-textBuy': '#0ECB81',
        '--color-buyHover': '#32D993',
        '--color-depthBuyBg': '#102821',
        '--color-line': '#2B3139',
        '--color-outlineHover': '#6A4403',
        '--color-primary': '#FCD535',
        '--color-primaryHover': '#F0B90B',
        '--color-error': '#F6465D',
        '--color-errorBg': '#35141D',
        '--color-success': '#0ECB81',
        '--color-successBg': '#102821',
        '--color-disable': '#474D57',
        '--color-iconNormal': '#848E9C',
        '--color-textWhite': '#FFFFFF',
        '--color-textGray': '#EAECEF',
        '--color-textBlack': '#0B0E11',
        '--color-textPrimary': '#EAECEF',
        '--color-textSecondary': '#B7BDC6',
        '--color-textThird': '#848E9C',
        '--color-textDisabled': '#5E6673',
        '--color-textBrand': '#F0B90B',
        '--color-textToast': '#A37200',
        '--color-bg': '#181A20',
        '--color-bg1': '#181A20',
        '--color-bg2': '#0B0E11',
        '--color-bg3': '#2B3139',
        '--color-bg4': '#5E6673',
        '--color-bg6': '#202630',
        '--color-bg7': '#191A1F',
        '--color-popupBg': '#1E2329',
        '--color-badgeBg': '#2D2A20',
        '--color-selectedBg': '#1E2026',
        '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
        '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
        '--color-BasicBg': '#181A20',
        '--color-SecondaryBg': '#0B0E11',
        '--color-Input': '#2B3139',
        '--color-InputLine': '#474D57',
        '--color-CardBg': '#1E2329',
        '--color-Vessel': '#1E2329',
        '--color-Line': '#2B3139',
        '--color-DisableBtn': '#2B3139',
        '--color-DisabledText': '#5E6673',
        '--color-DisableText': '#5E6673',
        '--color-TertiaryText': '#848E9C',
        '--color-SecondaryText': '#B7BDC6',
        '--color-PrimaryText': '#EAECEF',
        '--color-RedGreenBgText': '#FFFFFF',
        '--color-EmphasizeText': '#FF693D',
        '--color-TextOnGray': '#EAECEF',
        '--color-TextOnYellow': '#202630',
        '--color-IconNormal': '#848E9C',
        '--color-LiteBg1': '#202630',
        '--color-LiteBg2': '#191A1F',
        '--color-BtnBg': '#FCD535',
        '--color-PrimaryYellow': '#F0B90B',
        '--color-TextLink': '#F0B90B',
        '--color-TextToast': '#A37200',
        '--color-DepthSellBg': '#35141D',
        '--color-SellHover': '#FF707E',
        '--color-Sell': '#F6465D',
        '--color-TextSell': '#F6465D',
        '--color-DepthBuyBg': '#102821',
        '--color-BuyHover': '#32D993',
        '--color-Buy': '#2EBD85',
        '--color-TextBuy': '#2EBD85',
        '--color-Error': '#F6465D',
        '--color-SuccessBg': '#102821',
        '--color-Success': '#2EBD85',
        '--color-TagBg': '#474D57',
        '--color-Grid': '#2B3139',
        '--color-Placeholder': '#474D57',
        '--color-ToastBg': '#707A8A',
        '--color-TwoColorIcon': '#CACED3',
        '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
        '--color-BadgeBg': 'rgba(240,185,11,0.1)',
      };
      return (
        <div id="__APP_FOOTER" style={{fontFamily: 'Plex, sans-serif'}}>
  <div className="theme-root dark _CWC" style={{themeStyles}}>
    <footer className="hidden-in-bnc-app footer-wrapper dark-color common-widget-css_wrap theme-root">
      <div className="footer-content">
        <div className="footer-community">
          <h3 className="footer-navlist-title">Comunidad</h3>
          <div className="footer-icongroup">
            <a href="https://discord.gg/jE4wt8g2H2" target="_self">
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-h-discord" />
              </svg>
            </a>
            <a href="https://t.me/SuiteiOSSpanish" target="_self">
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-social-telegram-f" />
              </svg>
            </a>
            <a href="https://www.tiktok.com/@SuiteiOS?lang=en" target="_self">
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-h-tiktok" />
              </svg>
            </a>
            <a href="https://www.facebook.com/SuiteiOS" target="_self">
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-h-facebook" />
              </svg>
            </a>
            <a href="https://twitter.com/SuiteiOSArg" target="_self">
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-social-x-f" />
              </svg>
            </a>
            <a href="https://www.reddit.com/r/SuiteiOS" target="_self">
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-h-reddit" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/SuiteiOS.argentina/"
              target="_self"
            >
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-h-instagram" />
              </svg>
            </a>
            <a
              href="https://coinmarketcap.com/exchanges/SuiteiOS/"
              target="_self"
            >
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-h-coinmarketcap" />
              </svg>
            </a>
            <a href="https://www.youtube.com/SuiteiOSespanol" target="_self">
              <svg
                className="footer-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <use xlinkHref="#icon-h-youtube" />
              </svg>
            </a>
          </div>
          <div className="lngAndCurrency-selectgroup  mt-48">
            <div className="block footer-community-lang">
              <div className="bn-tooltips-wrap">
                <div className="bn-tooltips-ele">
                  <div className="footer-select-display">
                    <div className="typography-Subtitle4 align-center">
                      <svg
                        className="bn-svg mr-8"
                        size={24}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.23 20.403a9.011 9.011 0 005.684-7.153h-3.942c-.147 2.86-.793 5.388-1.741 7.153zm-.757-7.153c-.178 4.102-1.217 7.25-2.473 7.25-1.256 0-2.295-3.148-2.473-7.25h4.946zm0-2.5H9.527C9.705 6.648 10.744 3.5 12 3.5c1.256 0 2.295 3.148 2.473 7.25zm2.499 0h3.942a9.01 9.01 0 00-5.683-7.153c.948 1.765 1.594 4.293 1.741 7.153zm-9.936 0c.147-2.862.793-5.392 1.743-7.156a9.01 9.01 0 00-5.693 7.156h3.95zm0 2.5h-3.95a9.01 9.01 0 005.693 7.157c-.95-1.765-1.596-4.295-1.743-7.157z"
                          fill="currentColor"
                        />
                      </svg>
                      <span>Español (Uruguay)</span>
                    </div>
                  </div>
                </div>
                <div
                  className="bn-bubble bn-bubble__unset shadow bn-tooltips"
                  style={{
                    transform: "translate(0px, 0px)",
                    bottom: "100%",
                    left: 0
                  }}
                >
                  <div
                    className="bn-bubble-content"
                    style={{ marginBottom: 4 }}
                  >
                    <div className="footer-select-options-group">
                      <div className="bn-textField bn-textField__line data-size-small">
                        <input
                          placeholder="Buscar"
                          className="bn-textField-input"
                          spellCheck="false"
                          autoComplete=""
                          defaultValue=""
                        />
                      </div>
                      <div className="footer-select-options-body">
                        <div className="footer-select-options typography-Subtitle4 active">
                          Español (Argentina)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          العربية
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          العربية (البحرين)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          български
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Čeština
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Dansk
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Deutsch (Schweiz)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Ελληνικά
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (UAE)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (Australia)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (Bahrain)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (India)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (Japan)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (Kazakhstan)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (Nigeria)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (New Zealand)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          English (South Africa)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Español (España)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Español (Latinoamérica)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Español (México)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Français
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Français (Afrique)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          magyar nyelv
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Bahasa Indonesia
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Italiano
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          日本語
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Қазақша (Қазақстан)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Қазақша
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          ລາວ
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          latviešu valoda
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Polski
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Português
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Português (Brasil)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Română
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Русский
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Русский (Казахстан)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Русский (Украина)
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          සිංහල
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Slovenčina
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Slovenščina
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Svenska
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Türkçe
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Українська
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          Tiếng Việt
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          简体中文
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          繁體中文
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-community-currency block">
              <div className="bn-tooltips-wrap">
                <div className="bn-tooltips-ele">
                  <div className="footer-select-display">
                    <div className="typography-Subtitle4 align-center">
                      <svg
                        className="bn-svg mr-8"
                        size={24}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.336 8.577c-.769.15-1.1.558-1.1 1.146 0 .573.331.874 1.1 1.086V8.577zM12.678 15.393c.71-.196 1.041-.663 1.041-1.236 0-.438-.18-.89-1.04-1.147v2.383z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 21a9 9 0 100-18 9 9 0 000 18zm.86-3.948V18.5h-1.72v-1.387c-1.523-.136-2.533-.755-3.287-1.584l1.357-1.237c.573.604 1.282 1.01 2.126 1.131V12.71c-2.262-.468-3.182-1.463-3.182-2.926 0-1.583 1.04-2.64 2.986-2.865V5.5h1.72v1.418c1.206.135 2.096.603 2.76 1.357l-1.373 1.237c-.362-.408-.845-.74-1.569-.905v2.503c2.112.483 3.122 1.342 3.122 2.88 0 1.479-.935 2.73-2.94 3.062z"
                          fill="currentColor"
                        />
                      </svg>
                      <span>USD-$</span>
                    </div>
                  </div>
                </div>
                <div
                  className="bn-bubble bn-bubble__unset shadow bn-tooltips"
                  style={{
                    transform: "translate(0px, 0px)",
                    bottom: "100%",
                    left: 0
                  }}
                >
                  <div
                    className="bn-bubble-content"
                    style={{ marginBottom: 4 }}
                  >
                    <div className="footer-select-options-group">
                      <div className="bn-textField bn-textField__line data-size-small">
                        <input
                          placeholder="Buscar"
                          className="bn-textField-input"
                          spellCheck="false"
                          autoComplete=""
                          defaultValue=""
                        />
                      </div>
                      <div className="footer-select-options-body">
                        <div className="footer-select-options typography-Subtitle4 active">
                          USD-$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          AED-د.إ
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          ARS-ARS$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          AUD-A$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          BDT-৳
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          BGN-лв
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          BHD-.د.ب
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          BOB-$b
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          BRL-R$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          CAD-C$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          CHF-CHF
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          CNY-¥
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          COP-COL$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          CZK-Kč
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          DKK-KR
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          EGP-E£
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          EUR-€
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          GBP-£
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          HKD-HKD
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          HRK-kn
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          HUF-Ft
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          IDR-Rp
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          INR-₹
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          JPY-¥
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          KES-KSh
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          KWD-ك.د
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          KZT-₸
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          MAD-م.د.
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          MNT-₮
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          MXN-Mex$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          NZD-NZ$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          OMR-ر.ع.
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          PEN-S/.
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          PHP-₱
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          PKR-₨
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          PLN-zł
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          QAR-ر.ق
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          RON-lei
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          RUB-₽
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          SAR-ر.س
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          SEK-kr
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          THB-฿
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          TRY-₺
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          TWD-NT$
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          UAH-₴
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          UGX-USh
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          VES-Bs
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          VND-₫
                        </div>
                        <div className="footer-select-options typography-Subtitle4">
                          ZAR-R
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-theme-switch align-center typography-Subtitle4">
              <div className="typography-Subtitle4 align-center">
                <span className="footer-theme-switch-text">Tema</span>
                <div className="bn-switch bn-switch__regular data-size-middle checked">
                  <div className="bn-switch-dot">
                    <svg
                      className="bn-svg switch-icon"
                      size={16}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.968 12.768a7 7 0 01-9.735-9.735 9 9 0 109.735 9.735z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lngAndCurrency-selectgroup-m mt-48">
            <div className="flex">
              <div className="lngAndCurrency-selectgroup-m-language align-center typography-Subtitle4">
                <svg
                  className="bn-svg mr-8"
                  size={24}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.23 20.403a9.011 9.011 0 005.684-7.153h-3.942c-.147 2.86-.793 5.388-1.741 7.153zm-.757-7.153c-.178 4.102-1.217 7.25-2.473 7.25-1.256 0-2.295-3.148-2.473-7.25h4.946zm0-2.5H9.527C9.705 6.648 10.744 3.5 12 3.5c1.256 0 2.295 3.148 2.473 7.25zm2.499 0h3.942a9.01 9.01 0 00-5.683-7.153c.948 1.765 1.594 4.293 1.741 7.153zm-9.936 0c.147-2.862.793-5.392 1.743-7.156a9.01 9.01 0 00-5.693 7.156h3.95zm0 2.5h-3.95a9.01 9.01 0 005.693 7.157c-.95-1.765-1.596-4.295-1.743-7.157z"
                    fill="currentColor"
                  />
                </svg>
                Español (Uruguay)
              </div>
              <div className="align-center typography-Subtitle4">
                <svg
                  className="bn-svg mr-8"
                  size={24}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.336 8.577c-.769.15-1.1.558-1.1 1.146 0 .573.331.874 1.1 1.086V8.577zM12.678 15.393c.71-.196 1.041-.663 1.041-1.236 0-.438-.18-.89-1.04-1.147v2.383z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21a9 9 0 100-18 9 9 0 000 18zm.86-3.948V18.5h-1.72v-1.387c-1.523-.136-2.533-.755-3.287-1.584l1.357-1.237c.573.604 1.282 1.01 2.126 1.131V12.71c-2.262-.468-3.182-1.463-3.182-2.926 0-1.583 1.04-2.64 2.986-2.865V5.5h1.72v1.418c1.206.135 2.096.603 2.76 1.357l-1.373 1.237c-.362-.408-.845-.74-1.569-.905v2.503c2.112.483 3.122 1.342 3.122 2.88 0 1.479-.935 2.73-2.94 3.062z"
                    fill="currentColor"
                  />
                </svg>
                USD
              </div>
            </div>
            <div className="footer-theme-switch align-center typography-Subtitle4">
              <div className="typography-Subtitle4 align-center">
                <span className="footer-theme-switch-text">Tema</span>
                <div className="bn-switch bn-switch__regular data-size-middle checked">
                  <div className="bn-switch-dot">
                    <svg
                      className="bn-svg switch-icon"
                      size={16}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.968 12.768a7 7 0 01-9.735-9.735 9 9 0 109.735 9.735z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-navlist">
          <div className="footer-navlist-group">
            <div className="footer-navlist-sub">
              <h3 className="footer-navlist-title" style={{fontFamily:'Plex'}}>
                Sobre Nosotros
                <div className="footer-navlist-expand-icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </h3>
              <ul className="footer-navlist-item-list">
                <li className="footer-navlist-item">
                  <a
                    id="footer_menu_item_ba-About"
                    href="./about"
                    target="_self"
                    className="typography-Body3 color-textprimary"
                  >
                    Acerca
                  </a>
                </li>
                <li className="footer-navlist-item">
                  <a
                    id="footer_menu_item_ba-joinUs"
                    href="./careers"
                    target="_self"
                    className="typography-Body3 color-textprimary"
                  >
                    Carreras
                  </a>
                </li>
                <li className="footer-navlist-item">
                  <a
                    id="footer_menu_item_ba-Press"
                    href="./newsroom"
                    target="_self"
                    className="typography-Body3 color-textprimary"
                  >
                    Prensa
                  </a>
                </li>
                <li className="footer-navlist-item">
                  <a
                    id="footer_menu_item_ba-Terms"
                    href="./terms"
                    target="_self"
                    className="typography-Body3 color-textprimary"
                  >
                    Términos
                  </a>
                </li>
                <li className="footer-navlist-item">
                  <a
                    id="footer_menu_item_ba-Privacy"
                    href="./privacy"
                    target="_self"
                    className="typography-Body3 color-textprimary"
                  >
                    Privacidad
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-navlist-group">
            <div className="footer-navlist-sub">
              <h3 className="footer-navlist-title" style={{fontFamily:'Plex'}}>
                Servicios
                <div className="footer-navlist-expand-icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </h3>
              <ul className="footer-navlist-item-list">
              </ul>
            </div>
          </div>
          <div className="footer-navlist-group">
            <div className="footer-navlist-sub">
              <h3 className="footer-navlist-title" style={{fontFamily:'Plex'}}>
                Tecnicos
                <div className="footer-navlist-expand-icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </h3>
              <ul className="footer-navlist-item-list">
              </ul>
            </div>
          </div>
          <div className="footer-navlist-group">
            <div className="footer-navlist-sub">
              <h3 className="footer-navlist-title" style={{fontFamily:'Plex'}}>
                Academia
                <div className="footer-navlist-expand-icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </h3>
              <ul className="footer-navlist-item-list">
                
              </ul>
            </div>
            <div className="footer-navlist-sub">
              <h3 className="footer-navlist-title" style={{fontFamily:'Plex'}}>
                Revendedores
                <div className="footer-navlist-expand-icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </h3>
              <ul className="footer-navlist-item-list">
              </ul>
            </div>
          </div>
          <div className="footer-navlist-group">
            <div className="footer-navlist-sub">
              <h3 className="footer-navlist-title" style={{fontFamily:'Plex'}}>
                Marketplace
                <div className="footer-navlist-expand-icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </h3>
              <ul className="footer-navlist-item-list">
              </ul>
            </div>
            <div className="footer-navlist-sub">
              <h3 className="footer-navlist-title" style={{fontFamily:'Plex'}}>
                Square
                <div className="footer-navlist-expand-icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </h3>
              <ul className="footer-navlist-item-list">
              
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright" style={{fontFamily:'PlexRegular'}}>
        <div className="footer-copyright-line" />
        <div className="footer-copyright-text footer-copyright-enterprise text-center typography-Body3">
          <span>SuiteiOS</span>
          <span className="no-wrap">© {/* */}2024</span>
          <span
            className="footer-copyright-cookieprefrence"
            data-ot-trigger="true"
          >
            Preferencias de cookies
          </span>
        </div>
      </div>
    </footer>
  </div>
</div>
      )
    };

export default Footer;