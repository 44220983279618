import React, { useState, useEffect } from 'react';
import gif from "./assets/img/Animation.gif";

const SuccessPayment = () => {
    const [orderID, setOrderID] = useState(''); // Almacena el OrderID

    useEffect(() => {
        const fetchData = () => {
            const storedOrderID = localStorage.getItem('orderID');
            if (storedOrderID) {
                setOrderID(storedOrderID);
            } else {
                console.error('Order ID not found in localStorage');
            }
        };

        fetchData(); 
    }, []);

    return (
        <div className='centered-container'>
            <img src={gif} alt="Animation" />
            <h1 className='paymentCompleted'>¡Pago Completado!</h1>
            {orderID ? (
                <>
                    <h3 className='mt-6 orderID'>OrderID: {orderID}</h3>
                    <h3 className='mt-6 orderID tecnic'>Presentar OrderID al técnico</h3>
                </>
            ) : (
                <h3 className='mt-6 orderID'>Cargando OrderID...</h3>
            )}
        </div>
    );
}

export default SuccessPayment;
