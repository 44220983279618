import React from 'react';

import googleIcon from "./assets/img/google-icon.png";
import appleIcon from "./assets/img/apple-icon.png";

import imgLogo from "./assets/img/suiteios_logo.png";
import PageTitle from '../Home/PageTitle';

function Register(props) {
    const themeStyles = {
        '--color-sell': '#F6465D',
        '--color-textSell': '#F6465D',
        '--color-sellHover': '#FF707E',
        '--color-depthSellBg': '#35141D',
        '--color-buy': '#0ECB81',
        '--color-textBuy': '#0ECB81',
        '--color-buyHover': '#32D993',
        '--color-depthBuyBg': '#102821',
        '--color-line': '#2B3139',
        '--color-outlineHover': '#6A4403',
        '--color-primary': '#FCD535',
        '--color-primaryHover': '#F0B90B',
        '--color-error': '#F6465D',
        '--color-errorBg': '#35141D',
        '--color-success': '#0ECB81',
        '--color-successBg': '#102821',
        '--color-disable': '#474D57',
        '--color-iconNormal': '#848E9C',
        '--color-textWhite': '#FFFFFF',
        '--color-textGray': '#EAECEF',
        '--color-textBlack': '#0B0E11',
        '--color-textPrimary': '#EAECEF',
        '--color-textSecondary': '#B7BDC6',
        '--color-textThird': '#848E9C',
        '--color-textDisabled': '#5E6673',
        '--color-textBrand': '#F0B90B',
        '--color-textToast': '#A37200',
        '--color-bg': '#181A20',
        '--color-bg1': '#181A20',
        '--color-bg2': '#0B0E11',
        '--color-bg3': '#2B3139',
        '--color-bg4': '#5E6673',
        '--color-bg6': '#202630',
        '--color-bg7': '#191A1F',
        '--color-popupBg': '#1E2329',
        '--color-badgeBg': '#2D2A20',
        '--color-selectedBg': '#1E2026',
        '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
        '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
        '--color-BasicBg': '#181A20',
        '--color-SecondaryBg': '#0B0E11',
        '--color-Input': '#2B3139',
        '--color-InputLine': '#474D57',
        '--color-CardBg': '#1E2329',
        '--color-Vessel': '#1E2329',
        '--color-Line': '#2B3139',
        '--color-DisableBtn': '#2B3139',
        '--color-DisabledText': '#5E6673',
        '--color-DisableText': '#5E6673',
        '--color-TertiaryText': '#848E9C',
        '--color-SecondaryText': '#B7BDC6',
        '--color-PrimaryText': '#EAECEF',
        '--color-RedGreenBgText': '#FFFFFF',
        '--color-EmphasizeText': '#FF693D',
        '--color-TextOnGray': '#EAECEF',
        '--color-TextOnYellow': '#202630',
        '--color-IconNormal': '#848E9C',
        '--color-LiteBg1': '#202630',
        '--color-LiteBg2': '#191A1F',
        '--color-BtnBg': '#FCD535',
        '--color-PrimaryYellow': '#F0B90B',
        '--color-TextLink': '#F0B90B',
        '--color-TextToast': '#A37200',
        '--color-DepthSellBg': '#35141D',
        '--color-SellHover': '#FF707E',
        '--color-Sell': '#F6465D',
        '--color-TextSell': '#F6465D',
        '--color-DepthBuyBg': '#102821',
        '--color-BuyHover': '#32D993',
        '--color-Buy': '#2EBD85',
        '--color-TextBuy': '#2EBD85',
        '--color-Error': '#F6465D',
        '--color-SuccessBg': '#102821',
        '--color-Success': '#2EBD85',
        '--color-TagBg': '#474D57',
        '--color-Grid': '#2B3139',
        '--color-Placeholder': '#474D57',
        '--color-ToastBg': '#707A8A',
        '--color-TwoColorIcon': '#CACED3',
        '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
        '--color-BadgeBg': 'rgba(240,185,11,0.1)',
      };
  return (
    <>
    <PageTitle title="Registrarse | SuiteiOS"/>
    <div style={themeStyles} className='theme-root dark'>
    <>
  <div id="__APP">
    <div id="wrap_app" className="bn-flex css-lcpaow">
      <main className="main css-5oj4n">
        <div className="content-layout">
          <div
            className="content-card "
            style={{ border: "1px solid var(--color-line)" }}
          >
            <div className="icon-wrap">
              <img
                className="default-icon block"
                src={imgLogo}
                width='83px!important'
                height={24}
/>
            </div>
            <div className="flex justify-between items-center mb-8">
              <div className="card-page-title !mb-[0px]" style={{fontFamily:'Plex'}}>Te damos la bienvenida a SuiteiOS              </div>
              
            </div>
            <form action="#">
              <div className="bn-formItem">
                <div className="bn-formItem-label" style={{fontFamily:'Plex'}}>
                  Email/número de teléfono
                </div>
                <div>
                  <div className="css-4cffwv">
                    <div className="bn-textField bn-textField__line data-size-large username-input-field css-8x1t0r">
                      <input
                      style={{fontFamily:'Plex'}}
                        className="bn-textField-input !w-[inherit]"
                        placeholder="Email o teléfono (sin código de país)"
                        type="text"
                        name="username"
                        autoCapitalize="off"
                        data-e2e="input-username"
                        spellCheck="false"
                        autoComplete="username"
                        defaultValue=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bn-formItem" style={{marginTop:15}}>
                <div className="bn-formItem-label" style={{fontFamily:'Plex'}}>
                  Contraseña
                </div>
                <div>
                  <div className="css-4cffwv">
                    <div className="bn-textField bn-textField__line data-size-large username-input-field css-8x1t0r">
                      <input
                      style={{fontFamily:'Plex'}}
                        className="bn-textField-input !w-[inherit]"
                        placeholder="Contraseña"
                        type="text"
                        name="password"
                        autoCapitalize="off"
                        data-e2e="input-password"
                        spellCheck="false"
                        autoComplete="password"
                        defaultValue=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bn-formItem mt-4">
  <div
  style={{fontFamily:'PlexRegular'}}
    name="agreement"
    className="bn-checkbox bn-checkbox__square data-size-md items-start agreement-checkbox"
  >
    <div className="bn-checkbox-icon">
      <svg
        fill="BasicBg"
        viewBox="0 0 24 25"
        xmlns="http://www.w3.org/2000/svg"
        className="bn-svg"
      >
        <path
          d="M19.357 4.687L9.301 14.743l-4.656-4.657-3.03 3.031L9.3 20.804 22.388 7.717l-3.03-3.03z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div className="typography-body3">
  Al crear una cuenta, acepto los{" "}
  <a
    className="decoration-dotted md:decoration-solid underline text-[--color-PrimaryText] inline hover:text-[--color-SecondaryText] hover:cursor-pointer"
    href="./terms"
    target="_blank"
    data-e2e="lnk-terms-of-service"
  >
    Términos de servicio
  </a>{" "}
  y la{" "}
  <a
    className="decoration-dotted md:decoration-solid underline text-[--color-PrimaryText] inline hover:text-[--color-SecondaryText] hover:cursor-pointer"
    href="./privacy"
    target="_blank"
    id="RegisterForm-a-termsOfUse2"
  >
    Política de privacidad
  </a>{" "}
  de SuiteiOS.
</div>
  </div>
</div>

              <button
                className="bn-button bn-button__primary data-size-large mt-6"
                type="button"
                data-e2e="btn-accounts-form-submit"
                style={{ width: "100%", fontFamily:'Plex'}}
              >
                Siguiente
              </button>
            </form>
            <div className="css-49vimp">
              <div className="bn-flex css-xpcra5">
                <div className="css-3piyph" />
                <div
                  data-bn-type="text"
                  className="text-[--color-SecondaryText] typography-body3 css-15aftra"
                >
                  o
                </div>
                <div className="css-3piyph" />
              </div>
              <div className="bn-flex css-yyvsvt">
                <div className="sign-with css-wgsgq7">
                  <div id="gSignInWrapper" data-e2e="btn-google-login">
                    <div id="googleLoginBtn" className="css-lro9rj">
                      <button className="bn-button bn-button__icon data-size-large third-login-button">
                        <img
                          className="bn-lazy-img css-fki3rl"
                          src={googleIcon}
                        />
                        <div className="bn-flex css-ario60" style={{fontFamily:'Plex'}}>
                          Continuar con Google
                        </div>
                      </button>
                      <div id="buttonDiv" className="css-1t1dwsw">
                        <div
                          className="S9gUrf-YoZ4jf"
                          style={{ position: "relative" }}
                        >
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sign-with css-16vu25q">
                  <button
                    className="bn-button bn-button__icon data-size-large third-login-button"
                    id="apple-login-btn"
                    data-e2e="btn-apple-login"
                  >
                    <img
                      className="bn-lazy-img css-fki3rl"
                      src={appleIcon}
                    />
                    <div className="bn-flex css-p9hz2v"  style={{fontFamily:'Plex'}}>
                      Continuar con Apple
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mt-4 max-md:mt-6 typography-body2"
            data-e2e="btn-create-SuiteiOS-account"
          >
            o
            <button style={{fontFamily:'Plex'}} className="bn-button bn-button__text bn-button__text__yellow data-size-small !px-1 !min-w-min undefined">
              <a style={{fontFamily:'Plex'}} className='bn-button bn-button__text bn-button__text__yellow data-size-small !px-1 !min-w-min undefined' href="./login">              Inicia sesion
              </a>
            </button>
          </div>
        </div>
      </main>
      <div className="flex flex-col items-center foot">
        <div className="flex items-center justify-center h-[70px] w-[425px] text-[--color-textSecondary]">
          <div className="bn-flex css-1kps7g2">
            <div className="bn-tooltips-wrap css-m0nbpx">
              <div className="bn-tooltips-ele">
                <div
                  id="header_menu_current_lng"
                  data-e2e="btn-language-icon"
                  className="bn-flex css-dpvb9s"
                >
                  <svg
                    className="bn-svg css-1s0vphz"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.23 20.403a9.011 9.011 0 005.684-7.153h-3.942c-.147 2.86-.793 5.388-1.741 7.153zm-.757-7.153c-.178 4.102-1.217 7.25-2.473 7.25-1.256 0-2.295-3.148-2.473-7.25h4.946zm0-2.5H9.527C9.705 6.648 10.744 3.5 12 3.5c1.256 0 2.295 3.148 2.473 7.25zm2.499 0h3.942a9.01 9.01 0 00-5.683-7.153c.948 1.765 1.594 4.293 1.741 7.153zm-9.936 0c.147-2.862.793-5.392 1.743-7.156a9.01 9.01 0 00-5.693 7.156h3.95zm0 2.5h-3.95a9.01 9.01 0 005.693 7.157c-.95-1.765-1.596-4.295-1.743-7.157z"
                      fill="currentColor"
                    />
                  </svg>
                  <div style={{fontFamily:'PlexRegular'}} className="inline text-[--color-textSecondary] hover:text-[--color-textBrand] css-vurnku">
                    Español (Uruguay)
                  </div>
                </div>
              </div>
              <div
                className="bn-bubble bn-bubble__unset shadow data-font-14 bn-tooltips"
                style={{
                  transform: "translate(-50%, 0px)",
                  bottom: "100%",
                  left: "50%"
                }}
              >
                <div className="bn-bubble-content" style={{ marginBottom: 4 }}>
                  <div className="bn-flex css-1tzfn88">
                    <div className="header-lngCur-content onlyone onlyoneLng  css-vurnku">
                      <div className="header-lngCur-item css-vurnku">
                        <div className="header-select-options-group-flat language-select css-vurnku">
                          <div className="typography-Body3 color-t_third css-vurnku">
                            Idioma
                          </div>
                          <div className="bn-textField bn-textField__line data-size-small data-gap-size-normal">
                            <input
                              data-e2e="input-search-language"
                              placeholder="Search"
                              className="bn-textField-input"
                              spellCheck="false"
                              autoComplete=""
                              defaultValue=""
                            />
                          </div>
                          <div className="header-select-options-group-flat-options  css-jmlio4">
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4 active css-vurnku"
                              data-e2e="link-language-es-AR"
                            >
                              Español (Argentina)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-ar"
                            >
                              العربية
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-ar-BH"
                            >
                              العربية (البحرين)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-bg"
                            >
                              български
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-cs"
                            >
                              Čeština
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-de"
                            >
                              Deutsch (Schweiz)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-da-DK"
                            >
                              Dansk
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en"
                            >
                              English
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-es"
                            >
                              Español (España)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-es-LA"
                            >
                              Español (Latinoamérica)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-AU"
                            >
                              English (Australia)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-IN"
                            >
                              English (India)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-NG"
                            >
                              English (Nigeria)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-es-MX"
                            >
                              Español (México)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-el"
                            >
                              Ελληνικά
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-KZ"
                            >
                              English (Kazakhstan)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-ZA"
                            >
                              English (South Africa)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-NZ"
                            >
                              English (New Zealand)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-BH"
                            >
                              English (Bahrain)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-AE"
                            >
                              English (UAE)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-en-JP"
                            >
                              English (Japan)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-fr"
                            >
                              Français
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-fr-AF"
                            >
                              Français (Afrique)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-hu"
                            >
                              magyar nyelv
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-it"
                            >
                              Italiano
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-id"
                            >
                              Bahasa Indonesia
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-ja"
                            >
                              日本語
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-kk-KZ"
                            >
                              Қазақша (Қазақстан)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-kz"
                            >
                              Қазақша
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-lv"
                            >
                              latviešu valoda
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-lo-LA"
                            >
                              ລາວ
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-pl"
                            >
                              Polski
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-pt-BR"
                            >
                              Português (Brasil)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-pt"
                            >
                              Português
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-ru"
                            >
                              Русский
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-ro"
                            >
                              Română
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-ru-UA"
                            >
                              Русский (Украина)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-ru-KZ"
                            >
                              Русский (Казахстан)
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-sv"
                            >
                              Svenska
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-sk"
                            >
                              Slovenčina
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-sl"
                            >
                              Slovenščina
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-si-LK"
                            >
                              සිංහල
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-tr"
                            >
                              Türkçe
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-uk-UA"
                            >
                              Українська
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-vi"
                            >
                              Tiếng Việt
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-zh-CN"
                            >
                              简体中文
                            </div>
                            <div
                              data-bn-type="text"
                              className="header-select-options typography-Subtitle4  css-vurnku"
                              data-e2e="link-language-zh-TC"
                            >
                              繁體中文
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  style={{fontFamily:'PlexRegular', fontSize:14}}
            className="mx-4 cursor-pointer hover:text-[--color-textBrand]"
            id="Cookie_Preference"
            data-ot-trigger="true"
            data-e2e="btn-one-trust-cookie"
          >
            Cookies
          </div>
          <div style={{fontFamily:'PlexRegular', fontSize:14}} className="mx-4 cursor-pointer hover:text-[--color-textBrand]">
            Términos
          </div>
          <div style={{fontFamily:'PlexRegular', fontSize:14}} className="mx-4 cursor-pointer hover:text-[--color-textBrand]">
            Privacidad
          </div>
        </div>
      </div>
    </div>
  </div>
  <style
    dangerouslySetInnerHTML={{
      __html: "#ot-sdk-btn-floating {\n    display: none;\n}"
    }}
  />
  <div id="onetrust-consent-sdk">
    <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
    <div
      id="onetrust-pc-sdk"
      className="otPcCenter ot-hide ot-fade-in otRelFont"
      lang="es"
      aria-label="Preference center"
      role="region"
    >
      <div
        role="dialog"
        aria-modal="true"
        style={{ height: "100%" }}
        aria-label="Centro de preferencia de la privacidad"
      >
        {/* Close Button */}
        <div className="ot-pc-header">
          {/* Logo Tag */}
          <div
            className="ot-pc-logo"
            role="img"
            aria-label="Logotipo de la empresa"
          >
            <img
              alt="Logotipo de la empresa"
              src={imgLogo}
              width='83px!important'
              height={24}
            />
          </div>
        </div>
        {/* Close Button */}
        <div id="ot-pc-content" className="ot-pc-scrollbar">
          <div className="ot-optout-signal ot-hide">
            <div className="ot-optout-icon">
              <svg xmlns="http://www.w3.org/2000/svg">
                <path
                  className="ot-floating-button__svg-fill"
                  d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                  fill="#FFF"
                  fillRule="evenodd"
                />
              </svg>
            </div>
            <span />
          </div>
          <h2 id="ot-pc-title">Centro de preferencia de la privacidad</h2>
          <div id="ot-pc-desc">
            Cuando visita cualquier sitio web, el mismo podría obtener o guardar
            información en su navegador, generalmente mediante el uso de
            cookies. Esta información puede ser acerca de usted, sus
            preferencias o su dispositivo, y se usa principalmente para que el
            sitio funcione según lo esperado. Por lo general, la información no
            lo identifica directamente, pero puede proporcionarle una
            experiencia web más personalizada. Ya que respetamos su derecho a la
            privacidad, usted puede escoger no permitirnos usar ciertas cookies.
            Haga clic en los encabezados de cada categoría para saber más y
            cambiar nuestras configuraciones predeterminadas. Sin embargo, el
            bloqueo de algunos tipos de cookies puede afectar su experiencia en
            el sitio y los servicios que podemos ofrecer.
            <br />
            <a
              href="https://www.SuiteiOS.com/es/cookie-privacy"
              className="privacy-notice-link"
              rel="noopener"
              target="_blank"
              aria-label="Más información sobre su privacidad, se abre en una nueva pestaña"
            >
              Más información
            </a>
          </div>
          <button id="accept-recommended-btn-handler">Permitirlas todas</button>
          <section className="ot-sdk-row ot-cat-grp">
            <h3 id="ot-category-title">
              Gestionar las preferencias de consentimiento
            </h3>
            <div
              className="ot-accordion-layout ot-cat-item ot-vs-config"
              data-optanongroupid="C0001"
            >
              <button
                aria-expanded="false"
                ot-accordion="true"
                aria-controls="ot-desc-id-C0001"
                aria-labelledby="ot-header-id-C0001 ot-status-id-C0001"
              />
              {/* Accordion header */}
              <div className="ot-acc-hdr ot-always-active-group">
                <div className="ot-plus-minus">
                  <span />
                  <span />
                </div>
                <h4 className="ot-cat-header" id="ot-header-id-C0001">
                  Cookies estrictamente necesarias
                </h4>
                <div id="ot-status-id-C0001" className="ot-always-active">
                  Activas siempre
                </div>
              </div>
              {/* accordion detail */}
              <div className="ot-acc-grpcntr ot-acc-txt">
                <p
                  className="ot-acc-grpdesc ot-category-desc"
                  id="ot-desc-id-C0001"
                >
                  Estas cookies son necesarias para que el sitio web funcione y
                  no se pueden desactivar en nuestros sistemas. Usualmente están
                  configuradas para responder a acciones hechas por usted para
                  recibir servicios, tales como ajustar sus preferencias de
                  privacidad, iniciar sesión en el sitio, o llenar formularios.
                  Usted puede configurar su navegador para bloquear o alertar la
                  presencia de estas cookies, pero algunas partes del sitio web
                  no funcionarán. Estas cookies no guardan ninguna información
                  personal identificable.
                </p>
                <div className="ot-hlst-cntr">
                  <button
                    className="ot-link-btn category-host-list-handler"
                    aria-label="El botón Detalles de cookies abre el menú Lista de cookies"
                    data-parent-id="C0001"
                  >
                    Información de las cookies‎
                  </button>
                </div>
              </div>
            </div>
            <div
              className="ot-accordion-layout ot-cat-item ot-vs-config"
              data-optanongroupid="C0003"
            >
              <button
                aria-expanded="false"
                ot-accordion="true"
                aria-controls="ot-desc-id-C0003"
                aria-labelledby="ot-header-id-C0003"
              />
              {/* Accordion header */}
              <div className="ot-acc-hdr">
                <div className="ot-plus-minus">
                  <span />
                  <span />
                </div>
                <h4 className="ot-cat-header" id="ot-header-id-C0003">
                  Cookies de funcionalidad
                </h4>
                <div className="ot-tgl">
                  <input
                    type="checkbox"
                    name="ot-group-id-C0003"
                    id="ot-group-id-C0003"
                    role="switch"
                    className="category-switch-handler"
                    data-optanongroupid="C0003"
                    aria-labelledby="ot-header-id-C0003"
                  />{" "}
                  <label className="ot-switch" htmlFor="ot-group-id-C0003">
                    <span className="ot-switch-nob" />{" "}
                    <span className="ot-label-txt">
                      Cookies de funcionalidad
                    </span>
                  </label>{" "}
                </div>
              </div>
              {/* accordion detail */}
              <div className="ot-acc-grpcntr ot-acc-txt">
                <p
                  className="ot-acc-grpdesc ot-category-desc"
                  id="ot-desc-id-C0003"
                >
                  Estas cookies permiten que el sitio ofrezca una mejor
                  funcionalidad y personalización. Pueden ser establecidas por
                  nosotros o por terceras partes cuyos servicios hemos agregado
                  a nuestras páginas. Si no permite estas cookies algunos de
                  nuestros servicios no funcionarán correctamente.
                </p>
                <div className="ot-hlst-cntr">
                  <button
                    className="ot-link-btn category-host-list-handler"
                    aria-label="El botón Detalles de cookies abre el menú Lista de cookies"
                    data-parent-id="C0003"
                  >
                    Información de las cookies‎
                  </button>
                </div>
              </div>
            </div>
            <div
              className="ot-accordion-layout ot-cat-item ot-vs-config"
              data-optanongroupid="C0004"
            >
              <button
                aria-expanded="false"
                ot-accordion="true"
                aria-controls="ot-desc-id-C0004"
                aria-labelledby="ot-header-id-C0004"
              />
              {/* Accordion header */}
              <div className="ot-acc-hdr">
                <div className="ot-plus-minus">
                  <span />
                  <span />
                </div>
                <h4 className="ot-cat-header" id="ot-header-id-C0004">
                  Cookies dirigidas
                </h4>
                <div className="ot-tgl">
                  <input
                    type="checkbox"
                    name="ot-group-id-C0004"
                    id="ot-group-id-C0004"
                    role="switch"
                    className="category-switch-handler"
                    data-optanongroupid="C0004"
                    aria-labelledby="ot-header-id-C0004"
                  />{" "}
                  <label className="ot-switch" htmlFor="ot-group-id-C0004">
                    <span className="ot-switch-nob" />{" "}
                    <span className="ot-label-txt">Cookies dirigidas</span>
                  </label>{" "}
                </div>
              </div>
              {/* accordion detail */}
              <div className="ot-acc-grpcntr ot-acc-txt">
                <p
                  className="ot-acc-grpdesc ot-category-desc"
                  id="ot-desc-id-C0004"
                >
                  Estas cookies pueden estar en todo el sitio web, colocadas por
                  nuestros socios publicitarios. Estos negocios pueden
                  utilizarlas para crear un perfil de sus intereses y mostrarle
                  anuncios relevantes en otros sitios. No almacenan información
                  personal directamente, sino que se basan en la identificación
                  única de su navegador y dispositivo de acceso al Internet. Si
                  no permite estas cookies, tendrá menos publicidad dirigida.
                </p>
                <div className="ot-hlst-cntr">
                  <button
                    className="ot-link-btn category-host-list-handler"
                    aria-label="El botón Detalles de cookies abre el menú Lista de cookies"
                    data-parent-id="C0004"
                  >
                    Información de las cookies‎
                  </button>
                </div>
              </div>
            </div>
            <div
              className="ot-accordion-layout ot-cat-item ot-vs-config"
              data-optanongroupid="C0002"
            >
              <button
                aria-expanded="false"
                ot-accordion="true"
                aria-controls="ot-desc-id-C0002"
                aria-labelledby="ot-header-id-C0002"
              />
              {/* Accordion header */}
              <div className="ot-acc-hdr">
                <div className="ot-plus-minus">
                  <span />
                  <span />
                </div>
                <h4 className="ot-cat-header" id="ot-header-id-C0002">
                  Cookies de rendimiento
                </h4>
                <div className="ot-tgl">
                  <input
                    type="checkbox"
                    name="ot-group-id-C0002"
                    id="ot-group-id-C0002"
                    role="switch"
                    className="category-switch-handler"
                    data-optanongroupid="C0002"
                    aria-labelledby="ot-header-id-C0002"
                  />{" "}
                  <label className="ot-switch" htmlFor="ot-group-id-C0002">
                    <span className="ot-switch-nob" />{" "}
                    <span className="ot-label-txt">Cookies de rendimiento</span>
                  </label>{" "}
                </div>
              </div>
              {/* accordion detail */}
              <div className="ot-acc-grpcntr ot-acc-txt">
                <p
                  className="ot-acc-grpdesc ot-category-desc"
                  id="ot-desc-id-C0002"
                >
                  Estas cookies nos permiten contar las visitas y fuentes de
                  circulación para poder medir y mejorar el desempeño de nuestro
                  sitio. Nos ayudan a saber qué páginas son las más o menos
                  populares, y ver cuántas personas visitan el sitio. Toda la
                  información que recogen estas cookies es agregada y, por lo
                  tanto, anónima. Si no permite estas cookies no sabremos cuándo
                  visitó nuestro sitio, y por lo tanto no podremos saber cuándo
                  lo visitó.
                </p>
                <div className="ot-hlst-cntr">
                  <button
                    className="ot-link-btn category-host-list-handler"
                    aria-label="El botón Detalles de cookies abre el menú Lista de cookies"
                    data-parent-id="C0002"
                  >
                    Información de las cookies‎
                  </button>
                </div>
              </div>
            </div>
            {/* Groups sections starts */}
            {/* Group section ends */}
            {/* Accordion Group section starts */}
            {/* Accordion Group section ends */}
          </section>
        </div>
        <section id="ot-pc-lst" className="ot-hide ot-hosts-ui ot-pc-scrollbar">
          <div id="ot-pc-hdr">
            <div id="ot-lst-title">
              <button
                className="ot-link-btn back-btn-handler"
                aria-label="Back"
              >
                <svg
                  id="ot-back-arw"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 444.531 444.531"
                  xmlSpace="preserve"
                >
                  <title>Back Button</title>
                  <g>
                    <path
                      fill="#656565"
                      d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
              l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
              c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
              s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
              L213.13,222.409z"
                    />
                  </g>
                </svg>
              </button>
              <h3>Lista de cookies</h3>
            </div>
            <div className="ot-lst-subhdr">
              <div className="ot-search-cntr">
                <p role="status" className="ot-scrn-rdr" />
                <input
                  id="vendor-search-handler"
                  type="text"
                  name="vendor-search-handler"
                  placeholder="Buscar..."
                  aria-label="Búsqueda en la lista de cookies"
                />{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 -30 110 110"
                  aria-hidden="true"
                >
                  <title>Search Icon</title>
                  <path
                    fill="#2e3644"
                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
      s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
      c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
      s-17-7.626-17-17S14.61,6,23.984,6z"
                  />
                </svg>
              </div>
              <div className="ot-fltr-cntr">
                <button
                  id="filter-btn-handler"
                  aria-label="Filter"
                  aria-haspopup="true"
                >
                  <svg
                    role="presentation"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 402.577 402.577"
                    xmlSpace="preserve"
                  >
                    <title>Filter Icon</title>
                    <g>
                      <path
                        fill="#fff"
                        d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
C402.765,25.895,404.093,19.231,400.858,11.427z"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div id="ot-anchor" />
              <section id="ot-fltr-modal">
                <div id="ot-fltr-cnt">
                  <button id="clear-filters-handler">Clear</button>
                  <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                    <div className="ot-fltr-opts">
                      <div className="ot-fltr-opt">
                        <div className="ot-chkbox">
                          <input
                            id="chkbox-id"
                            type="checkbox"
                            className="category-filter-handler"
                          />{" "}
                          <label htmlFor="chkbox-id">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                      </div>
                    </div>
                    <div className="ot-fltr-btns">
                      <button id="filter-apply-handler">Apply</button>{" "}
                      <button id="filter-cancel-handler">Cancel</button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <section id="ot-lst-cnt" className="ot-host-cnt ot-pc-scrollbar">
            <div id="ot-sel-blk">
              <div className="ot-sel-all">
                <div className="ot-sel-all-hdr">
                  <span className="ot-consent-hdr">Consent</span>{" "}
                  <span className="ot-li-hdr">Leg.Interest</span>
                </div>
                <div className="ot-sel-all-chkbox">
                  <div className="ot-chkbox" id="ot-selall-hostcntr">
                    <input
                      id="select-all-hosts-groups-handler"
                      type="checkbox"
                    />{" "}
                    <label htmlFor="select-all-hosts-groups-handler">
                      <span className="ot-label-txt">checkbox label</span>
                    </label>{" "}
                    <span className="ot-label-status">label</span>
                  </div>
                  <div className="ot-chkbox" id="ot-selall-vencntr">
                    <input
                      id="select-all-vendor-groups-handler"
                      type="checkbox"
                    />{" "}
                    <label htmlFor="select-all-vendor-groups-handler">
                      <span className="ot-label-txt">checkbox label</span>
                    </label>{" "}
                    <span className="ot-label-status">label</span>
                  </div>
                  <div className="ot-chkbox" id="ot-selall-licntr">
                    <input id="select-all-vendor-leg-handler" type="checkbox" />{" "}
                    <label htmlFor="select-all-vendor-leg-handler">
                      <span className="ot-label-txt">checkbox label</span>
                    </label>{" "}
                    <span className="ot-label-status">label</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ot-sdk-row">
              <div className="ot-sdk-column">
                <ul id="ot-host-lst" />
              </div>
            </div>
          </section>
        </section>
        <div className="ot-pc-footer ot-pc-scrollbar">
          <div className="ot-btn-container">
            <button className="ot-pc-refuse-all-handler">
              Rechazarlas todas
            </button>{" "}
            <button className="save-preference-btn-handler onetrust-close-btn-handler">
              Confirmar mis preferencias
            </button>
          </div>
          {/* Footer logo */}
          <div className="ot-pc-footer-logo">
            <a
              href="https://www.onetrust.com/products/cookie-consent/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Powered by OneTrust Se abre en una nueva pestaña"
            >
              <img
                alt="Powered by Onetrust"
                src="https://cdn.cookielaw.org/logos/static/powered_by_logo.svg"
                title="Powered by OneTrust Se abre en una nueva pestaña"
              />
            </a>
          </div>
        </div>
        {/* Cookie subgroup container */}
        {/* Vendor list link */}
        {/* Cookie lost link */}
        {/* Toggle HTML element */}
        {/* Checkbox HTML */}
        {/* plus minus*/}
        {/* Arrow SVG element */}
        {/* Accordion basic element */}
        <span className="ot-scrn-rdr" aria-atomic="true" aria-live="polite" />
        {/* Vendor Service container and item template */}
      </div>
      <iframe
        className="ot-text-resize"
        sandbox="allow-same-origin"
        title="onetrust-text-resize"
        style={{ position: "absolute", top: "-50000px", width: "100em" }}
        aria-hidden="true"
      />
    </div>
  </div>
  <div />
  <div id="turnstile-container" />
  <div />
  <div />
  <div />
  <iframe
    src="about:blank"
    id="tmx_tags_iframe"
    title="empty"
    tabIndex={-1}
    aria-disabled="true"
    aria-hidden="true"
    data-time={1724513058027}
    style={{
      width: 0,
      height: 0,
      border: 0,
      position: "absolute",
      top: "-5000px"
    }}
  />
</>

    </div>
    </>
  );
}

export default Register;