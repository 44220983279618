import React from 'react';
import Header from './Header';

import Footer from './Footer';
import pressHeaderBg from "../Newsroom/assets/img/press-header-bg-1.svg";
import PageTitle from './PageTitle';

function Newsroom() {
  const themeStyles = {
      '--color-sell': '#F6465D',
      '--color-textSell': '#F6465D',
      '--color-sellHover': '#FF707E',
      '--color-depthSellBg': '#35141D',
      '--color-buy': '#0ECB81',
      '--color-textBuy': '#0ECB81',
      '--color-buyHover': '#32D993',
      '--color-depthBuyBg': '#102821',
      '--color-line': '#2B3139',
      '--color-outlineHover': '#6A4403',
      '--color-primary': '#FCD535',
      '--color-primaryHover': '#F0B90B',
      '--color-error': '#F6465D',
      '--color-errorBg': '#35141D',
      '--color-success': '#0ECB81',
      '--color-successBg': '#102821',
      '--color-disable': '#474D57',
      '--color-iconNormal': '#848E9C',
      '--color-textWhite': '#FFFFFF',
      '--color-textGray': '#EAECEF',
      '--color-textBlack': '#0B0E11',
      '--color-textPrimary': '#EAECEF',
      '--color-textSecondary': '#B7BDC6',
      '--color-textThird': '#848E9C',
      '--color-textDisabled': '#5E6673',
      '--color-textBrand': '#F0B90B',
      '--color-textToast': '#A37200',
      '--color-bg': '#181A20',
      '--color-bg1': '#181A20',
      '--color-bg2': '#0B0E11',
      '--color-bg3': '#2B3139',
      '--color-bg4': '#5E6673',
      '--color-bg6': '#202630',
      '--color-bg7': '#191A1F',
      '--color-popupBg': '#1E2329',
      '--color-badgeBg': '#2D2A20',
      '--color-selectedBg': '#1E2026',
      '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
      '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
      '--color-BasicBg': '#181A20',
      '--color-SecondaryBg': '#0B0E11',
      '--color-Input': '#2B3139',
      '--color-InputLine': '#474D57',
      '--color-CardBg': '#1E2329',
      '--color-Vessel': '#1E2329',
      '--color-Line': '#2B3139',
      '--color-DisableBtn': '#2B3139',
      '--color-DisabledText': '#5E6673',
      '--color-DisableText': '#5E6673',
      '--color-TertiaryText': '#848E9C',
      '--color-SecondaryText': '#B7BDC6',
      '--color-PrimaryText': '#EAECEF',
      '--color-RedGreenBgText': '#FFFFFF',
      '--color-EmphasizeText': '#FF693D',
      '--color-TextOnGray': '#EAECEF',
      '--color-TextOnYellow': '#202630',
      '--color-IconNormal': '#848E9C',
      '--color-LiteBg1': '#202630',
      '--color-LiteBg2': '#191A1F',
      '--color-BtnBg': '#FCD535',
      '--color-PrimaryYellow': '#F0B90B',
      '--color-TextLink': '#F0B90B',
      '--color-TextToast': '#A37200',
      '--color-DepthSellBg': '#35141D',
      '--color-SellHover': '#FF707E',
      '--color-Sell': '#F6465D',
      '--color-TextSell': '#F6465D',
      '--color-DepthBuyBg': '#102821',
      '--color-BuyHover': '#32D993',
      '--color-Buy': '#2EBD85',
      '--color-TextBuy': '#2EBD85',
      '--color-Error': '#F6465D',
      '--color-SuccessBg': '#102821',
      '--color-Success': '#2EBD85',
      '--color-TagBg': '#474D57',
      '--color-Grid': '#2B3139',
      '--color-Placeholder': '#474D57',
      '--color-ToastBg': '#707A8A',
      '--color-TwoColorIcon': '#CACED3',
      '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
      '--color-BadgeBg': 'rgba(240,185,11,0.1)',
    };
  return (
    <>
        <PageTitle title="Novedades | SuiteiOS"/>
    <Header/>
    <div style={{themeStyles}} className='theme-root dark _CWC'> 
    <div id="__APP" style={{fontFamily:'PlexBold'}}>
  <div className="css-9wjvt8">
    <div className="css-tq0shg">
      <main className="css-1wr4jig">
        <div className="css-o3g9qf">
          <div className="bg-bg1 css-15owl46">
            <div className="css-1b1nwec">
              <div className="css-8ctup5">
                <div className="css-rjhyqc">
                  <a
                    data-bn-type="text"
                    href="/about"
                    id="aboutNav-a-about"
                    className="css-1j3htfx"
                  >
                    Acerca de
                  </a>
                  <a
                    data-bn-type="text"
                    href="/careers"
                    id="aboutNav-a-careers"
                    className="css-1pll6jz"
                  >
                    Empleo
                  </a>
                  <a
                    data-bn-type="text"
                    href="/press"
                    id="aboutNav-a-press"
                    className="css-11b06lo"
                  >
                    Prensa
                  </a>
                  <a
                    data-bn-type="text"
                    href="/community"
                    id="aboutNav-a-community"
                    className="css-1pll6jz"
                  >
                    Comunidad
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-bg1 css-cpn49t">
              <div className="css-1ps5b3k">
                <div className="css-1rqoctz">
                  <div data-bn-type="text" className="css-l2xytx">
                    SuiteiOS Newsroom
                  </div>
                  <div data-bn-type="text" className="css-qsmkfn" style={{fontFamily:'PlexRegular'}}>Tu fuente de las últimas novedades de 
                    SuiteiOS. Accede a nuestros anuncios oficiales, publicaciones de blog, 
                    actualizaciones, recursos multimedia y mucho más. Cuando se trata de soluciones 
                    de desbloqueo y recuperación de dispositivos iOS, SuiteiOS siempre está 
                    a la vanguardia de la industria.
                  </div>
                </div>
                <img
                  src={pressHeaderBg}
                  alt="Community Join Group"
                  className="css-1bhuokx"
                />
              </div>
            </div>
          </div>
          <div className="css-oo12fz" style={{fontFamily:'Plex'}}>
            <div className="css-1tgg46u">
              <div className="css-wz04zn">
                <div className="css-11j7f9k">
                  <div className="css-1epj8wi">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="css-hxx8pe"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22 6H2v14h20V6zM4 10h16V8H4v2zm8 2h8v2h-8v-2zm0 4h4v2h-4v-2zm-8-4h6v6H4v-6z"
                        fill="url(#blog-g_svg__paint0_linear)"
                      />
                      <path d="M2 3.998h20v2H2v-2z" fill="#76808F" />
                      <defs>
                        <linearGradient
                          id="blog-g_svg__paint0_linear"
                          x1={2}
                          y1={13}
                          x2={22}
                          y2={13}
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F0B90B" />
                          <stop offset={1} stopColor="#F8D33A" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div data-bn-type="text" className="css-9wp2zh">
                      Blog de SuiteiOS
                    </div>
                  </div>
                  <div className="css-1epj8wi">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="css-hxx8pe"
                    >
                      <path d="M3 13h2V8H3v5z" fill="#76808F" />
                      <path
                        d="M17 6H5v9H17l4 4V2l-4 4z"
                        fill="url(#announcement-g_svg__paint0_linear)"
                      />
                      <path d="M14 15H7v7h4v-5h3v-2z" fill="#76808F" />
                      <defs>
                        <linearGradient
                          id="announcement-g_svg__paint0_linear"
                          x1="13.001"
                          y1={19}
                          x2="13.001"
                          y2={2}
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F0B90B" />
                          <stop offset={1} stopColor="#F8D33A" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div data-bn-type="text" className="css-9wp2zh">
                      Anuncios
                    </div>
                  </div>
                  <div className="css-1epj8wi">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="css-hxx8pe"
                    >
                      <path
                        d="M11.5 17a4.5 4.5 0 10-9 0 4.5 4.5 0 009 0z"
                        fill="url(#all-g_svg__paint0_linear_1341_1)"
                      />
                      <path
                        d="M17 12l-5-5 5-5 5 5-5 5z"
                        fill="url(#all-g_svg__paint1_linear_1341_1)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21 21v-8h-8v8h8z"
                        fill="#76808F"
                      />
                      <path fill="#76808F" d="M11 11H3V3h8z" />
                      <defs>
                        <linearGradient
                          id="all-g_svg__paint0_linear_1341_1"
                          x1={7}
                          y1="21.5"
                          x2={7}
                          y2="12.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F0B90B" />
                          <stop offset={1} stopColor="#F8D33A" />
                        </linearGradient>
                        <linearGradient
                          id="all-g_svg__paint1_linear_1341_1"
                          x1={17}
                          y1={12}
                          x2={17}
                          y2={2}
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F0B90B" />
                          <stop offset={1} stopColor="#F8D33A" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div data-bn-type="text" className="css-9wp2zh">
                      Activos multimedia
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="css-1liqh49">
            <div className="css-wz04zn">
              <div data-bn-type="text" className="css-1un0815">
                Acerca de SuiteiOS
              </div>
              <div data-bn-type="text" className="css-1k40pge" style={{fontFamily:'PlexRegular'}}>
              SuiteiOS es la plataforma líder en soluciones de desbloqueo y recuperación de 
              dispositivos iOS, ofreciendo un conjunto completo de herramientas y 
              servicios que permiten a los propietarios legítimos recuperar el control de sus 
              dispositivos de manera segura y eficiente.
              </div>
              <a
                data-bn-type="link"
                href="/about"
                target="_blank"
                className="css-m2io66"
              >
                <button className="bn-button bn-button__primary data-size-large min-w-[114px]" style={{fontFamily:'Plex'}}>
                  Aprender más
                </button>
              </a>
            </div>
          </div>
          <div id="section-announcement" className="css-1ldg4m">
            <div className="css-wz04zn">
              <div className="css-d9nrkd">
                <div data-bn-type="text" className="css-nkwk8n" style={{fontFamily:'Plex'}}>
                  Anuncios oficiales
                </div>
                <div data-bn-type="text" className="css-116hwja" style={{fontFamily:'PlexRegular'}}>
                Mantente al día con las actualizaciones de productos de SuiteiOS, los nuevos servicios de desbloqueo, las oportunidades para técnicos, y los anuncios más importantes.
                </div>
                <a
                style={{fontFamily:'Plex'}}
                  data-bn-type="link"
                  className="t-btnLink3 pl-0 text-TextLink css-m2io66"
                  id="announcements-a-viewAll"
                  href="/support/announcement"
                >
                  Ver más
                </a>
              </div>
              </div>
              </div>
          <div id="section-blog" className="css-fyh9vj">
            <div className="css-wz04zn">
              <div className="css-d9nrkd">
                <div data-bn-type="text" className="css-nkwk8n" style={{fontFamily:'Plex'}}>
                  Blog de SuiteiOS
                </div>
                <div data-bn-type="text" className="css-116hwja" style={{fontFamily:'PlexRegular'}}>
                  Entérate de lo que está sucediendo en el ecosistema de
                  SuiteiOS.
                </div>
                <a
                style={{fontFamily:'Plex'}}
                  data-bn-type="link"
                  className="t-btnLink3 pl-0 text-TextLink css-m2io66"
                  id="announcements-a-viewAll"
                  href="blog"
                >
                  Ver más
                </a>
              </div>
              <div className="css-jzrk1e">
                
                <a
                  data-bn-type="link"
                  href="/blog/5161725594793518415/La recesión del mercado: ¿qué sucedió y qué podemos esperar?"
                  target="_blank"
                  id="blog-a-5161725594793518000"
                  className="css-m2io66"
                >
                  <div className="css-1tku8g2">
                    <img
                      src=""
                      alt="La recesión del mercado: ¿qué sucedió y qué podemos esperar?"
                      className="css-6ejboj"
                    />
                    <div data-bn-type="text" className="css-gctrrj">
                      <div className="LinesEllipsis  " style={{fontFamily:'Plex'}}>
                        La recesión del mercado: ¿qué sucedió y qué podemos
                        esperar?
                        <wbr />
                      </div>
                    </div>
                    <div data-bn-type="text" className="css-mt0jb6">
                      <div className="LinesEllipsis LinesEllipsis--clamped ">
                        Los mercados bursátiles y de criptomonedas han
                        experimentado una volatilidad significativa en los últim
                        <wbr />
                        <span className="LinesEllipsis-ellipsis">...</span>
                      </div>
                    </div>
                    <div className="css-vurnku">
                      <div data-bn-type="text" className="css-18kqmhp" style={{fontFamily:'PlexRegular'}}>
                        Aug 07{/* */}, {/* */}2024
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="css-1vuarhv">
            <div className="css-wz04zn">
              <div className="css-d9nrkd">
                <div data-bn-type="text" className="css-nkwk8n" style={{fontFamily:'Plex'}}>
                  Contáctanos
                </div>
                <div data-bn-type="text" className="css-116hwja" style={{fontFamily:'PlexRegular'}}>
                  SuiteiOS Newsroom está disponible para periodistas de todo el
                  mundo. Comunícate con nosotros en{" "}
                  <a href="mailto:newsroom@suiteios.com">newsroom@suiteios.com</a>
                  <br />
                  Ten en cuenta que actualmente el equipo de Relaciones Públicas
                  y Comunicaciones de SuiteiOS solo puede responder a las
                  consultas de medios de comunicación. Nos esforzamos por
                  responder las consultas válidas lo antes posible. <br />
                  <br />
                  ¿Necesitas atención al cliente? Visita nuestro{" "}
                  <a href="/es-LA/support" target="_blank">
                    Centro de soporte
                  </a>
                  .
                </div>
                <a
                  data-bn-type="link"
                  className="t-btnLink3 pl-0 text-TextLink css-m2io66"
                  id="announcements-a-viewAll"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>

</div>

    <Footer/>
    </>
  );
}

export default Newsroom;