import React from 'react';
import Header from './Header';

import Footer from './Footer';

import hero from '../Careers/assets/images/hero.svg';
import collaboration2 from '../Careers/assets/images/collaboration2.svg';
import freedom3 from '../Careers/assets/images/freedom3.svg';
import hardcore3 from '../Careers/assets/images/hardcore3.svg';
import userfocused2 from '../Careers/assets/images/user-focused2.svg';
import humility3 from '../Careers/assets/images/humility3.svg';
import PageTitle from './PageTitle';

function Careers() {
  const themeStyles = {
      '--color-sell': '#F6465D',
      '--color-textSell': '#F6465D',
      '--color-sellHover': '#FF707E',
      '--color-depthSellBg': '#35141D',
      '--color-buy': '#0ECB81',
      '--color-textBuy': '#0ECB81',
      '--color-buyHover': '#32D993',
      '--color-depthBuyBg': '#102821',
      '--color-line': '#2B3139',
      '--color-outlineHover': '#6A4403',
      '--color-primary': '#FCD535',
      '--color-primaryHover': '#F0B90B',
      '--color-error': '#F6465D',
      '--color-errorBg': '#35141D',
      '--color-success': '#0ECB81',
      '--color-successBg': '#102821',
      '--color-disable': '#474D57',
      '--color-iconNormal': '#848E9C',
      '--color-textWhite': '#FFFFFF',
      '--color-textGray': '#EAECEF',
      '--color-textBlack': '#0B0E11',
      '--color-textPrimary': '#EAECEF',
      '--color-textSecondary': '#B7BDC6',
      '--color-textThird': '#848E9C',
      '--color-textDisabled': '#5E6673',
      '--color-textBrand': '#F0B90B',
      '--color-textToast': '#A37200',
      '--color-bg': '#181A20',
      '--color-bg1': '#181A20',
      '--color-bg2': '#0B0E11',
      '--color-bg3': '#2B3139',
      '--color-bg4': '#5E6673',
      '--color-bg6': '#202630',
      '--color-bg7': '#191A1F',
      '--color-popupBg': '#1E2329',
      '--color-badgeBg': '#2D2A20',
      '--color-selectedBg': '#1E2026',
      '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
      '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
      '--color-BasicBg': '#181A20',
      '--color-SecondaryBg': '#0B0E11',
      '--color-Input': '#2B3139',
      '--color-InputLine': '#474D57',
      '--color-CardBg': '#1E2329',
      '--color-Vessel': '#1E2329',
      '--color-Line': '#2B3139',
      '--color-DisableBtn': '#2B3139',
      '--color-DisabledText': '#5E6673',
      '--color-DisableText': '#5E6673',
      '--color-TertiaryText': '#848E9C',
      '--color-SecondaryText': '#B7BDC6',
      '--color-PrimaryText': '#EAECEF',
      '--color-RedGreenBgText': '#FFFFFF',
      '--color-EmphasizeText': '#FF693D',
      '--color-TextOnGray': '#EAECEF',
      '--color-TextOnYellow': '#202630',
      '--color-IconNormal': '#848E9C',
      '--color-LiteBg1': '#202630',
      '--color-LiteBg2': '#191A1F',
      '--color-BtnBg': '#FCD535',
      '--color-PrimaryYellow': '#F0B90B',
      '--color-TextLink': '#F0B90B',
      '--color-TextToast': '#A37200',
      '--color-DepthSellBg': '#35141D',
      '--color-SellHover': '#FF707E',
      '--color-Sell': '#F6465D',
      '--color-TextSell': '#F6465D',
      '--color-DepthBuyBg': '#102821',
      '--color-BuyHover': '#32D993',
      '--color-Buy': '#2EBD85',
      '--color-TextBuy': '#2EBD85',
      '--color-Error': '#F6465D',
      '--color-SuccessBg': '#102821',
      '--color-Success': '#2EBD85',
      '--color-TagBg': '#474D57',
      '--color-Grid': '#2B3139',
      '--color-Placeholder': '#474D57',
      '--color-ToastBg': '#707A8A',
      '--color-TwoColorIcon': '#CACED3',
      '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
      '--color-BadgeBg': 'rgba(240,185,11,0.1)',
    };
  return (
    <>
    <PageTitle title="Empleos | SuiteiOS"/>
    <Header/>
    <div style={{themeStyles}} className='theme-root dark _CWC'> 
    <div id="__APP" style={{fontFamily:'PlexBold'}}>
  <div className="css-9wjvt8">
    <div className="css-tq0shg">
      <main className="css-1wr4jig">
        <div className="mx-auto w-full bg-bg1 pb-20 mobile:px-m tablet:px-xl pc:max-w-[1440px] pc:px-xl pc1280:px-[120px]">
          <div className="bn-tabs bn-tabs__primary-gray mt-2">
            <div className="bn-tab-list bn-tab-list__primary-gray" style={{fontFamily:'Plex'}}>
              <div className="bn-tab bn-tab__primary-gray bn-mobile:subtitle4 caption1">
                <a href="/about" className="mobile:subtitle4 caption1">
                  Acerca de
                </a>
              </div>
              <div className="bn-tab bn-tab__primary-gray active bn-mobile:subtitle4 caption1">
                <a href="/careers" className="mobile:subtitle4 caption1">
                  Empleo
                </a>
              </div>
              <div className="bn-tab bn-tab__primary-gray bn-mobile:subtitle4 caption1">
                <a href="/press" className="mobile:subtitle4 caption1">
                  Prensa
                </a>
              </div>
              <div className="bn-tab bn-tab__primary-gray bn-mobile:subtitle4 caption1">
                <a href="/community" className="mobile:subtitle4 caption1">
                  Comunidad
                </a>
              </div>
            </div>
            <div className="bn-tab-pane-list">
              <div
                tabIndex={0}
                style={{ display: "none" }}
                className="bn-tab-pane"
              />
              <div tabIndex={1} className="bn-tab-pane active" />
              <div
                tabIndex={2}
                style={{ display: "none" }}
                className="bn-tab-pane"
              />
              <div
                tabIndex={3}
                style={{ display: "none" }}
                className="bn-tab-pane"
              />
            </div>
          </div>
          <div>
            <div className="bn-flex w-full flex-col items-center justify-between pc:flex-row [@media(max-width:1024px)]:mt-10">
              <div className="bn-flex order-2 w-full flex-1 flex-col items-center mobile:text-center pc:order-1 pc:items-start">
                <div className="noMobile:t-title2 t-headline1">
                  Empleos en SuiteiOS
                </div>
                <div className="mobile:t-body1-1 t-body1 mt-4 text-t-third">
                Únete a nosotros y contribuye a la seguridad y acceso a dispositivos iCloud de nuestros usuarios.
                </div>
                <a
                  rel="noreferrer noopener"
                  href="/careers/job-openings?team=All"
                  className="bn-button bn-button__primary data-size-middle mobile:mt-6 mobile:w-full noMobile:mt-10"
                >
                  Ver vacantes
                </a>
              </div>
              <img
                className="order-1 mobile:mt-10 pc:order-2 pc:mr-20"
                fetchpriority="high"
                src={hero}
                width="380px"
                height="380px"
              />
            </div>
          </div>
          <div className="mt-20">
            <div className="mb-10">
              <div className="t-headline1 text-t-primary">Nuestros valores</div>
              <div className="t-body1-1 mt-4 text-t-third">
                Los valores centrales de SuiteiOS guían nuestro comportamiento,
                decisiones y acciones, lo que permite la colaboración unificada
                entre nuestros diversos equipos internacionales.
              </div>
            </div>
            <div
              className="bn-grid grid-cols-1 gap-6 pc:grid-cols-2 pc:grid-rows-3"
              style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <div className="bn-flex group items-center justify-between gap-6 rounded-xl mobile:p-6 noMobile:py-4 noMobile:px-10 border border-solid border-line mobile:flex-col-reverse mobile:items-center pc:first:row-span-2 pc:first:flex-col pc:first:justify-start pc:first:pt-10 pc:first:pb-5 relative [@media(max-width:1024px)]:order-1">
                <div className="w-full">
                  <div className="subtitle1">Centrado en el usuario</div>
                  <div className="mt-2">
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Protegemos a nuestros usuarios colocando en primer lugar
                      las necesidades de nuestros usuarios y brindando un
                      servicio de calidad.
                    </span>
                  </div>
                </div>
                <img
                  width={172}
                  className="bn-lazy-img flex-shrink-0 pc:absolute pc:right-10 pc:bottom-0 pc:h-[265px] pc:object-cover"
                  src={userfocused2}
                />
              </div>
              <div className="bn-flex group items-center justify-between gap-6 rounded-xl mobile:p-6 noMobile:py-4 noMobile:px-10 border border-solid border-line mobile:flex-col-reverse mobile:items-center pc:first:row-span-2 pc:first:flex-col pc:first:justify-start pc:first:pt-10 pc:first:pb-5 relative [@media(max-width:1024px)]:order-3">
                <div className="w-full">
                  <div className="subtitle1">Colaboración</div>
                  <div className="mt-2">
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Nos comunicamos abiertamente.
                    </span>
                    <br />
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Trabajamos en equipos hacia objetivos mutuos que nos
                      permitan construir el ecosistema entre todos.
                    </span>
                  </div>
                </div>
                <img
                  width={172}
                  className="bn-lazy-img flex-shrink-0"
                  src={collaboration2}
                />
              </div>
              <div className="bn-flex group items-center justify-between gap-6 rounded-xl mobile:p-6 noMobile:py-4 noMobile:px-10 border border-solid border-line mobile:flex-col-reverse mobile:items-center pc:first:row-span-2 pc:first:flex-col pc:first:justify-start pc:first:pt-10 pc:first:pb-5 relative [@media(max-width:1024px)]:order-4">
                <div className="w-full">
                  <div className="subtitle1">Fortaleza</div>
                  <div className="mt-2">
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Somos impulsados por los resultados. Hacemos las cosas.
                    </span>
                    <br />
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Somos apasionados y trabajamos duro.
                    </span>
                    <br />
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Cuando fallamos, aprendemos rápido y nos recuperamos.
                    </span>
                  </div>
                </div>
                <img
                  width={172}
                  className="bn-lazy-img flex-shrink-0"
                  src={hardcore3}
                />
              </div>
              <div className="bn-flex group items-center justify-between gap-6 rounded-xl mobile:p-6 noMobile:py-4 noMobile:px-10 border border-solid border-line mobile:flex-col-reverse mobile:items-center pc:first:row-span-2 pc:first:flex-col pc:first:justify-start pc:first:pt-10 pc:first:pb-5 relative [@media(max-width:1024px)]:order-2">
                <div className="w-full">
                  <div className="subtitle1">Libertad</div>
                  <div className="mt-2">
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Ejercemos de forma responsable y autónoma.
                    </span>
                    <br />
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Impulsamos a quienes nos rodean.
                    </span>
                    <br />
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Nuestro equipo es diverso y desafiamos la situación
                      actual.
                    </span>
                  </div>
                </div>
                <img
                  width={172}
                  className="bn-lazy-img flex-shrink-0"
                  src={humility3}
                />
              </div>
              <div className="bn-flex group items-center justify-between gap-6 rounded-xl mobile:p-6 noMobile:py-4 noMobile:px-10 border border-solid border-line mobile:flex-col-reverse mobile:items-center pc:first:row-span-2 pc:first:flex-col pc:first:justify-start pc:first:pt-10 pc:first:pb-5 relative [@media(max-width:1024px)]:order-5">
                <div className="w-full">
                  <div className="subtitle1">Humildad</div>
                  <div className="mt-2">
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Aceptamos los comentarios críticos.
                    </span>
                    <br />
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Tratamos a todos por igual.
                    </span>
                    <br />
                    <span className="mobile:t-body1 t-body1-1 text-t-third mobile:w-full">
                      Somos modestos respecto a nuestros éxitos.
                    </span>
                  </div>
                </div>
                <img
                  width={172}
                  className="bn-lazy-img flex-shrink-0"
                  src={freedom3}
                />
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div
              className="bn-grid min-h-[204px] items-center gap-6 rounded-xl border border-solid border-line py-6 mobile:grid-cols-1 mobile:px-6 noMobile:grid-cols-3"
              style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <div className="text-center mobile:text-left">
                <div className="t-title2">0</div>
                <div className="mobile:tertiary-num1 subtitle1 mt-2 text-t-third">
                  Nacionalidades
                </div>
              </div>
              <div className="text-center mobile:text-left">
                <div className="t-title2">0</div>
                <div className="mobile:tertiary-num1 subtitle1 mt-2 text-t-third">
                  Empleados
                </div>
              </div>
              <div className="text-center mobile:text-left">
                <div className="t-title2">0</div>
                <div className="mobile:tertiary-num1 subtitle1 mt-2 text-t-third">
                  Ubicaciones
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 pc:mt-[120px]" id="teams">
            <div className="mb-10">
              <div className="t-headline1 text-t-primary">Escoge tu equipo</div>
              <div className="t-body1-1 mt-4 text-t-third">
                Selecciona un equipo que sea más relevante según tus intereses y
                experiencia para ver las ofertas de trabajo.
              </div>
            </div>
            <div
              className="bn-grid grid-cols-[repeat(auto-fit,minmax(348px,_1fr))] gap-6"
              style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <a
                href="/careers/department?name=Business%20Development"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 2h2v2H3V2zm15 1h3v13h-3V3zm-2 4h-3v9h3V7zm-5 3H8v6h3v-6zm10 8H3v3h18v-3zM5 6H3v2h2V6zm-2 4h2v2H3v-2zm2 4H3v2h2v-2z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Desarrollo de negocios</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Communications"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2c5.523 0 10 4.477 10 10h-2a8 8 0 00-8-8h-.018V2H12zm-.207 8.793L10 9h3.586l-1.793 1.793zM17 7v9l2.218 2.218c-3.472 3.47-8.96 3.694-12.692.67L4.414 21 3 19.586l2.112-2.112c-3.024-3.731-2.8-9.22.67-12.692L8 7h9zm-3.793 5.207L15 14v-3.586l-1.793 1.793z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Comunicaciones</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Customer%20Support"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.002 17v-5a9.113 9.113 0 00-.055-1 9.001 9.001 0 00-17.945 1v5h5v-6H5.578a6.502 6.502 0 0112.848 0h-2.424v6h.899a6.988 6.988 0 01-3.289 1.814 2 2 0 10.217 2A9.007 9.007 0 0019.486 17h1.516z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Atención al Cliente</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Data%20%26%20Research"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 12a9 9 0 10-18 0 9 9 0 0018 0zm-9 6.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm1.25-7.75H16v2.5h-2.75V16h-2.5v-2.75H8v-2.5h2.75V8h2.5v2.75z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Datos e investigación</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Editorial%20%26%20Video"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm8.934 3.5H10v-7h1.934L16 12l-4.066 3.5z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Editorial y vídeo</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Engineering"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.009 3H2.99v3H21.01V3zM2.99 8H21.01v13H2.99V8zm2.302 3.707L8.086 14.5l-2.793 2.793 1.414 1.414 4.207-4.207-4.207-4.207-1.414 1.414zm13.414 5h-6.133v2h6.133v-2z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Ingeniería</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Finance%20%26%20Administration"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.336 8.577c-.769.15-1.1.558-1.1 1.146 0 .573.331.874 1.1 1.086V8.577zM12.678 15.393c.71-.196 1.041-.663 1.041-1.236 0-.438-.18-.89-1.04-1.147v2.383z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21a9 9 0 100-18 9 9 0 000 18zm.86-3.948V18.5h-1.72v-1.387c-1.523-.136-2.533-.755-3.287-1.584l1.357-1.237c.573.604 1.282 1.01 2.126 1.131V12.71c-2.262-.468-3.182-1.463-3.182-2.926 0-1.583 1.04-2.64 2.986-2.865V5.5h1.72v1.418c1.206.135 2.096.603 2.76 1.357l-1.373 1.237c-.362-.408-.845-.74-1.569-.905v2.503c2.112.483 3.122 1.342 3.122 2.88 0 1.479-.935 2.73-2.94 3.062z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Finanza y administración</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Legal%20%26%20Compliance"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0h2v4h10v2H13v13h6v3H5v-3h6V6H1V4h10V0z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.5 6l4.5 4.5H1L5.5 6zM18.5 6l4.5 4.5h-9L18.5 6zM18.5 17a4.5 4.5 0 004.5-4.5h-9a4.5 4.5 0 004.5 4.5zM5.5 17a4.5 4.5 0 004.5-4.5H1A4.5 4.5 0 005.5 17z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Legalidad y cumplimiento</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Marketing"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.002 7H16l4-4v16l-4-4h-4v6H7v-6H4.002V7z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Marketing</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Operations%2C%20Strategy%20%26%20Project%20Management"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8 3h-3.6v2.027c-.66.17-1.285.431-1.858.77L6.91 4.363 4.363 6.91l1.434 1.433a7.157 7.157 0 00-.77 1.858H3v3.6h2.027c.17.66.431 1.285.77 1.858L4.363 17.09l2.546 2.546 1.433-1.434c.573.339 1.197.6 1.858.77V21h3.6v-2.027a7.157 7.157 0 001.858-.77l1.433 1.434 2.546-2.546-1.434-1.434a7.16 7.16 0 00.77-1.857H21v-3.6h-2.027a7.158 7.158 0 00-.77-1.858l1.434-1.433-2.546-2.546-1.434 1.434a7.156 7.156 0 00-1.857-.77V3zm-4.5 9a2.7 2.7 0 115.4 0 2.7 2.7 0 01-5.4 0z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">
                    Operaciones, estrategia y gestión de proyectos
                  </div>
                </div>
              </a>
              <a
                href="/careers/department?name=Product%20%26%20Design"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.522 3h2.829v2.828l-7.778 7.779-2.829-2.829L12.522 3zM3.684 11.84l2.828 2.828-2.121 2.121-2.829-2.828 2.122-2.121zM3 21L21 3v18H3zm7.243-3L18 10.243V18h-7.757z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Producto y Diseño</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Quantitative%20Strategy"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 5H3v14h18V5zm-7.5 10.267l5.5-5.5L17.23 8l-3.73 3.731-3-3L5 14.231 6.768 16l3.732-3.732 3 3z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Estrategia cuantitativa</div>
                </div>
              </a>
              <a
                href="/careers/department?name=Security%20%26%20IT%20Helpdesk"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 8v2H5v11h14V10h-2V8A5 5 0 007 8zm7.5 2V8a2.5 2.5 0 00-5 0v2h5zm-1 8v-5h-3v5h3z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">
                    Servicio de asistencia de seguridad y TI
                  </div>
                </div>
              </a>
              <a
                href="/careers/department?name=HR"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.746 16a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5zm0 2.5c1.506 0 2.912-.43 4.101-1.173L18.52 21l2.475-2.475-3.673-3.673a7.75 7.75 0 10-6.577 3.648zm0-11.694a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm-3.25 6a1.5 1.5 0 011.5-1.5h3.5a1.5 1.5 0 011.5 1.5v1h-6.5v-1z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Recursos humanos (HR)</div>
                </div>
              </a>
              <a
                href="/careers/commitment?type=Early%20Careers"
                className="bn-flex items-center gap-4 rounded-xl border border-solid border-line py-[28px] px-6 text-PrimaryText transition-colors hover:border-textPrimary"
              >
                <svg
                  className="bn-svg h-8 w-8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.02 6.682a4.501 4.501 0 018.925.818 4.5 4.5 0 01-8.926.818H9.491V6.682h2.528zm7.17 1.636a2.865 2.865 0 01-5.49 0h5.49zm0-1.636H13.7a2.865 2.865 0 015.49 0zM10.718 21a4.5 4.5 0 10-2.046-8.51v-.08a2.864 2.864 0 10-2.863 2.863h.578A4.5 4.5 0 0010.717 21zm0-1.636a2.864 2.864 0 002.52-4.226L9.356 19.02c.405.22.869.344 1.362.344zm1.363-5.383l-3.882 3.882a2.864 2.864 0 013.882-3.882zm-6.272-2.799a1.227 1.227 0 100 2.454h1.227V12.41c0-.678-.55-1.227-1.227-1.227z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.786 18.512l4.469-4.672c-2-1.925-4.313-.282-5.219.78-.562 1.738.203 3.418.75 3.892zM6.583 10.652c.812.075.64 2.021.453 2.985-.948.26-2.981.437-3.531-.938-.688-1.719 2.062-2.14 3.078-2.047zM19.945 7.146h-6.312c-.907-1.64 1.875-2.796 3.312-3.125 1.15-.262 2.48 1.974 3 3.125z"
                    fill="currentColor"
                  />
                </svg>
                <div className="bn-flex gap-4">
                  <div className="t-subtitle6">Primeros empleos</div>
                </div>
              </a>
            </div>
          </div>
          <div className="mt-20 pc:mt-[120px]">
            <div className="mb-10">
              <div className="t-headline1 text-t-primary">
                Trabaja con nosotros
              </div>
            </div>
            <div
              className="bn-grid grid-cols-1 gap-6 pc:grid-cols-3"
              style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <div className="bn-flex flex-col">
                <div className="h-[280px] rounded-xl border border-solid border-line py-6">
                  <div
                    className="bn-lazy-img data-lazy-load h-full w-full bg-contain py-6"
                    data-src="https://bin.bnbstatic.com/static/images/careers/innovation.svg"
                  />
                </div>
                <div className="t-headline5 mobile:t-headline3 mt-6">
                  Prospera con SuiteiOS
                </div>
                <div className="t-body1-1 mt-2 text-t-third">
                  ¿Cuál es nuestro secreto en el éxito? Nuestra gente. Unidos
                  por un ADN en común de personas sumamente talentosas,
                  trabajadoras y apasionadas dentro de un equipo reconocido a
                  nivel mundial.
                </div>
              </div>
              <div className="bn-flex flex-col">
                <div className="h-[280px] rounded-xl border border-solid border-line py-6">
                  <div
                    className="bn-lazy-img data-lazy-load h-full w-full bg-contain py-6"
                    data-src="https://bin.bnbstatic.com/static/images/careers/grow.svg"
                  />
                </div>
                <div className="t-headline5 mobile:t-headline3 mt-6">
                  Inspirando mediante la innovación
                </div>
                <div className="t-body1-1 mt-2 text-t-third">
                  Adopta los futuros desafíos. Abarca el crecimiento exponencial
                  respecto a la adopción de la tecnología Blockchain,
                  criptomonedas y contabilidad distribuida de la mano con la
                  empresa líder del ecosistema cripto.
                </div>
              </div>
              <div className="bn-flex flex-col">
                <div className="h-[280px] rounded-xl border border-solid border-line py-6">
                  <div
                    className="bn-lazy-img data-lazy-load h-full w-full bg-contain py-6"
                    data-src="https://bin.bnbstatic.com/static/images/careers/thrive-2.svg"
                  />
                </div>
                <div className="t-headline5 mobile:t-headline3 mt-6">
                  Contribuye al crecimiento en SuiteiOS
                </div>
                <div className="t-body1-1 mt-2 text-t-third">
                  Transforma nuestros productos y servicios con tu experiencia y
                  se reconocido por tu éxito. Conéctate a un entorno de
                  expansión que te recompensará en un crecimiento personal.
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 pc:mt-[120px]">
            <div className="mb-10">
              <div className="t-headline1 text-t-primary">
                ¿Por qué deberías trabajar con nosotros?
              </div>
              <div className="t-body1-1 mt-4 text-t-third">
                Estamos orgullosos de ofrecer beneficios únicos y competitivos
                que te permitirán ejercer tu carrera de una forma saludable y
                satisfactoria en SuiteiOS
              </div>
            </div>
            <div style={{fontFamily:'Plex'}} className="bn-flex flex-wrap justify-between gap-x-6 gap-y-16">
              <div className="bn-flex min-w-fit flex-col gap-10">
                <svg
                  className="bn-svg h-[90px] w-[90px]"
                  viewBox="0 0 90 90"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="a"
                    maskUnits="userSpaceOnUse"
                    x={0}
                    y={0}
                    width={90}
                    height={90}
                  >
                    <circle cx={45} cy={45} r={45} fill="#E6E8EA" />
                  </mask>
                  <g mask="url(#a)">
                    <circle cx={45} cy={45} r={45} fill="#FAFAFA" />
                    <path
                      d="m70.872 124.81 32.301-8.083c4.772-1.194 6.037-7.382 2.117-10.354l-30.6-23.201-14.894-.205"
                      stroke="#E8A37D"
                      strokeWidth="14.752"
                    />
                    <path
                      d="m62.975 125.032-33.33-8.299c-4.789-1.193-6.048-7.41-2.1-10.373L58.03 83.486l14.894-.205"
                      stroke="#E8A37D"
                      strokeWidth="14.752"
                    />
                    <path
                      d="m41.437 96.457 18.931-14.419 12.395.221 18.03 13.522"
                      stroke="#14151A"
                      strokeWidth="18.48"
                    />
                    <path
                      d="M77.832 61.935v12.769h-22.94V61.935c0-6.334 5.135-11.47 11.47-11.47 6.335 0 11.47 5.136 11.47 11.47Z"
                      fill="#F8D33A"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M62.116 61.254a6.035 6.035 0 0 0-6.978-9.848l-20.713 14.68-12.11-16.54-2.09 1.53-.526-.48-15.944.288a.393.393 0 0 0-.322.608l1.063 1.627a7.85 7.85 0 0 0 4.638 3.314l4.012 1.02 11.63 15.885c3.846 5.251 11.193 6.446 16.504 2.682l20.836-14.766Z"
                      fill="#E8A37D"
                    />
                    <path
                      d="m18.515 47.927 3.242 4.185"
                      stroke="#E8A37D"
                      strokeWidth="3.925"
                      strokeLinecap="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M71.629 61.26a6.035 6.035 0 0 1 6.97-9.853L97.94 65.09l11.611-16.49 1.73 1.218.491-.498 15.936-.582a.393.393 0 0 1 .355.588l-.973 1.683a7.853 7.853 0 0 1-4.45 3.562l-3.249 1.018-11.671 16.576c-3.794 5.389-11.243 6.674-16.624 2.867L71.63 61.261Z"
                      fill="#E8A37D"
                    />
                    <path
                      d="m59.392 30.111-5.07 6.733 22.761.48-5.296-7.213c-5.318-5.498-10.48-2.29-12.395 0Z"
                      fill="#202630"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M65.558 42.984a6.417 6.417 0 0 1-12.272-2.626 6.416 6.416 0 0 1 12.272-2.627 6.417 6.417 0 0 1 12.27 2.627 6.416 6.416 0 0 1-12.27 2.626Z"
                      fill="#202630"
                    />
                    <circle cx="65.839" cy="37.193" r="7.654" fill="#E8A37D" />
                    <mask
                      id="b"
                      maskUnits="userSpaceOnUse"
                      x={58}
                      y={29}
                      width={16}
                      height={16}
                    >
                      <ellipse
                        cx="65.84"
                        cy="37.182"
                        rx="7.662"
                        ry="7.654"
                        transform="matrix(0 -.99898 1.00102 0 28.687 102.918)"
                        fill="#935A30"
                      />
                    </mask>
                    <g mask="url(#b)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M65.855 34.296a5.3 5.3 0 1 1 0-4.831 5.3 5.3 0 1 1 0 4.831Z"
                        fill="#202630"
                      />
                    </g>
                    <path
                      d="m85.493 63.678-10.376-7.341H58.616l-12.248 8.677"
                      stroke="#F8D33A"
                      strokeWidth="12.07"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                  </g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.958 43.713c7.011 0 12.695-5.684 12.695-12.696 0-7.011-5.684-12.696-12.695-12.696-7.012 0-12.696 5.684-12.696 12.696s5.684 12.696 12.696 12.696Zm0-18.338-5.643 5.642 5.643 5.643 5.642-5.643-5.642-5.642Z"
                    fill="url(#c)"
                  />
                  <defs>
                    <linearGradient
                      id="c"
                      x1="32.958"
                      y1="43.713"
                      x2="32.958"
                      y2="18.321"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F0B90B" />
                      <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="bn-flex flex-col gap-4">
                  <div className="t-subtitle1">Salario competitivo</div>
                  <div className="t-subtitle1">Opción de pago en cripto</div>
                  <div className="t-subtitle1">Horas de trabajo flexibles</div>
                </div>
              </div>
              <div className="bn-flex min-w-fit flex-col gap-10">
                <svg
                  className="bn-svg h-[90px] w-[90px]"
                  viewBox="0 0 90 90"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_3093_14140"
                    maskUnits="userSpaceOnUse"
                    x={0}
                    y={0}
                    width={90}
                    height={90}
                  >
                    <path
                      d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45Z"
                      fill="#E6E8EA"
                    />
                  </mask>
                  <g mask="url(#mask0_3093_14140)">
                    <path
                      d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45Z"
                      fill="#FAFAFA"
                    />
                    <path
                      d="M62.9511 48.5944C65.7936 48.5944 68.0979 46.29 68.0979 43.4475C68.0979 40.605 65.7936 38.3007 62.9511 38.3007L62.9511 48.5944ZM37.5417 41.4188L33.1657 44.1281L37.5417 41.4188ZM62.9511 38.3007L41.1839 38.3006L41.1839 48.5943L62.9511 48.5944L62.9511 38.3007ZM41.9177 38.7094L30.9554 21.0035L22.2033 26.4222L33.1657 44.1281L41.9177 38.7094ZM41.1839 38.3006C41.4828 38.3006 41.7604 38.4553 41.9177 38.7094L33.1657 44.1281C34.8848 46.9048 37.9181 48.5943 41.1839 48.5943L41.1839 38.3006Z"
                      fill="#935A30"
                    />
                    <path
                      d="M28.801 31.2858C30.4545 30.2117 31.6135 28.5247 32.0231 26.596C32.4328 24.6673 32.0595 22.6549 30.9853 21.0015C29.9112 19.348 28.2242 18.189 26.2955 17.7794C24.3669 17.3697 22.3544 17.743 20.701 18.8172L24.751 25.0515L28.801 31.2858Z"
                      fill="#935A30"
                    />
                    <path
                      d="M21.8705 23.1128L24.3378 26.9108"
                      stroke="#935A30"
                      strokeWidth="3.08736"
                      strokeLinecap="round"
                    />
                    <path
                      d="M43.2182 67.2877L25.1721 67.2877C22.7339 67.2877 20.5638 68.8336 19.7672 71.1381L14.6932 85.8163L8.28827 104.345"
                      stroke="#935A30"
                      strokeWidth="12.5812"
                    />
                    <path
                      d="M60.5588 67.2913L41.687 67.2913C38.5286 67.2913 35.9683 69.8516 35.9683 73.01L35.9683 98.5575"
                      stroke="#B97030"
                      strokeWidth="12.5812"
                    />
                    <path
                      d="M74.1703 47.9544V61.0239L54.8038 61.024V47.9544C54.8038 42.6065 59.1392 38.2712 64.487 38.2711C69.8349 38.2711 74.1703 42.6065 74.1703 47.9544Z"
                      fill="#AEB4BC"
                    />
                    <path
                      d="M44.5699 61.0009H74.1699C74.1699 67.9491 68.5372 73.5818 61.5889 73.5818H49.7179L44.5699 61.0009Z"
                      fill="#14151A"
                    />
                    <path
                      d="M92.2703 77.8542L89.8002 74.0582"
                      stroke="#B97030"
                      strokeWidth="3.08736"
                      strokeLinecap="round"
                    />
                    <path
                      d="M68.3292 39.0392C65.6715 38.031 62.6997 39.3682 61.6915 42.0259C60.6833 44.6837 62.0206 47.6555 64.6783 48.6637L68.3292 39.0392ZM84.1803 50.5568L82.3548 55.3691L84.1803 50.5568ZM86.9446 54.562L92.0915 54.5619L86.9446 54.562ZM64.6783 48.6637L82.3548 55.3691L86.0057 45.7446L68.3292 39.0392L64.6783 48.6637ZM81.7978 54.5621L81.7981 74.8686L92.0918 74.8684L92.0915 54.5619L81.7978 54.5621ZM82.3548 55.3691C82.0195 55.2419 81.7978 54.9206 81.7978 54.5621L92.0915 54.5619C92.0914 50.644 89.6689 47.1342 86.0057 45.7446L82.3548 55.3691Z"
                      fill="#B97030"
                    />
                    <path
                      d="M89.1974 68.1861C87.2257 68.1861 85.3347 68.9693 83.9405 70.3635C82.5463 71.7578 81.7631 73.6487 81.7631 75.6204C81.7631 77.5921 82.5463 79.4831 83.9405 80.8773C85.3347 82.2715 87.2257 83.0547 89.1974 83.0547L89.1974 75.6204V68.1861Z"
                      fill="#B97030"
                    />
                    <path
                      d="M68.3091 39.0458C65.6498 38.0417 62.6801 39.3836 61.6761 42.0429C60.672 44.7022 62.0139 47.672 64.6732 48.676L68.3091 39.0458ZM64.6732 48.676L79.8689 54.413L83.5048 44.7828L68.3091 39.0458L64.6732 48.676Z"
                      fill="#B97030"
                    />
                    <ellipse
                      cx="62.8677"
                      cy="28.2881"
                      rx="7.13888"
                      ry="7.13161"
                      transform="scale(1.00102 0.998981) rotate(-90 62.8677 28.2881)"
                      fill="#935A30"
                    />
                    <mask
                      id="mask1_3093_14140"
                      maskUnits="userSpaceOnUse"
                      x={55}
                      y={21}
                      width={16}
                      height={15}
                    >
                      <ellipse
                        cx="62.8685"
                        cy="28.2854"
                        rx="7.13888"
                        ry="7.13161"
                        transform="scale(1.00102 0.998981) rotate(-90 62.8685 28.2854)"
                        fill="#935A30"
                      />
                    </mask>
                    <g mask="url(#mask1_3093_14140)">
                      <path
                        d="M62.0377 17.813H73.2166V36.2024L62.0377 29.0725V17.813Z"
                        fill="#F0B90B"
                      />
                    </g>
                    <circle
                      cx="72.3123"
                      cy="25.9345"
                      r="3.9169"
                      transform="rotate(45 72.3123 25.9345)"
                      fill="#F0B90B"
                    />
                    <circle
                      cx="78.3923"
                      cy="30.5702"
                      r="4.77756"
                      transform="rotate(45 78.3923 30.5702)"
                      fill="#F0B90B"
                    />
                    <path
                      d="M42.2233 82.9435H104.505V117.004H42.2233V82.9435Z"
                      fill="#AEB4BC"
                    />
                    <path
                      d="M104.505 73.2126H42.2238V82.9441H104.505V73.2126Z"
                      fill="url(#paint0_linear_3093_14140)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M57.0297 79.5177V76.6389H54.1551V79.5177H57.0297Z"
                      fill="#02C076"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M52.5 79.5177V76.6389H49.6254V79.5177H52.5Z"
                      fill="#F0B90B"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M47.9724 79.5177V76.6389H45.0979V79.5177H47.9724Z"
                      fill="#F84960"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M101.631 76.6385V79.5173H59.905V76.6385H101.631Z"
                      fill="#F5F5F5"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M45.0984 89.9102V87.0314H101.631V89.9102H45.0984Z"
                      fill="url(#paint1_linear_3093_14140)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_3093_14140"
                      x1="42.2238"
                      y1="82.9319"
                      x2="103.712"
                      y2="82.9319"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#5E6673" />
                      <stop offset={1} stopColor="#76808F" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_3093_14140"
                      x1="45.1504"
                      y1="90.0776"
                      x2="91.7571"
                      y2="90.0679"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.588542" stopColor="#76808F" />
                      <stop offset={1} stopColor="#AEB4BC" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="bn-flex flex-col gap-4">
                  <div className="t-subtitle1">
                    Trabajo a distancia, en diversos roles
                  </div>
                  <div className="t-subtitle1">
                    Días festivos patrocinados por la empresa
                  </div>
                  <div className="t-subtitle1">
                    Actividades constructivas para el equipo
                  </div>
                  <div className="t-subtitle1">
                    Y otros beneficios y ventajas
                  </div>
                </div>
              </div>
              <div className="bn-flex min-w-fit flex-col gap-10">
                <svg
                  className="bn-svg h-[90px] w-[90px]"
                  viewBox="0 0 90 90"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_3093_14195"
                    maskUnits="userSpaceOnUse"
                    x={0}
                    y={0}
                    width={90}
                    height={90}
                  >
                    <path
                      d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45Z"
                      fill="#FCEA9C"
                    />
                  </mask>
                  <g mask="url(#mask0_3093_14195)">
                    <path
                      d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45Z"
                      fill="#FAFAFA"
                    />
                    <path
                      d="M64.6262 161.112V84.2395L53.4054 84.2395L23.0718 162.598"
                      stroke="#76808F"
                      strokeWidth="16.3489"
                    />
                    <path
                      d="M50.1137 66.5776C53.8074 66.5776 56.8018 63.5832 56.8018 59.8894C56.8018 56.1956 53.8074 53.2012 50.1137 53.2012L50.1137 66.5776ZM11.4169 25.7L18.6042 56.3837L31.628 53.3331L24.4408 22.6493L11.4169 25.7ZM31.4617 66.5776L50.1137 66.5776L50.1137 53.2012L31.4617 53.2012L31.4617 66.5776ZM18.6042 56.3837C20.0029 62.3551 25.3287 66.5776 31.4617 66.5776L31.4617 53.2012C31.5411 53.2012 31.6099 53.2558 31.628 53.3331L18.6042 56.3837Z"
                      fill="#935A30"
                    />
                    <path
                      d="M53.7125 53.2012C57.4063 53.2012 60.4007 56.1956 60.4007 59.8894C60.4007 63.5832 57.4063 66.5776 53.7125 66.5776L53.7125 53.2012ZM53.7125 66.5776L34.6337 66.5776L34.6337 53.2012L53.7125 53.2012L53.7125 66.5776Z"
                      fill="#0B0E11"
                    />
                    <path
                      d="M72.8094 53.1572V80.7574H45.9821V53.1572H72.8094Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M77.2788 55.5577C74.6669 52.9458 70.4322 52.9458 67.8203 55.5577C65.2084 58.1696 65.2084 62.4043 67.8203 65.0162L86.8654 84.0613L70.8001 100.127L72.9146 102.241L72.7467 102.419L72.7803 120.6C72.7809 120.942 73.158 121.15 73.4474 120.967L75.4165 119.726C77.2607 118.563 78.6012 116.751 79.1727 114.647L80.6553 109.188L96.4447 93.399C101.602 88.2419 101.602 79.8806 96.4447 74.7235L77.2788 55.5577Z"
                      fill="#935A30"
                    />
                    <path
                      d="M76.7775 55.1547C74.1622 52.5461 69.9275 52.5516 67.319 55.1669C64.7104 57.7821 64.7159 62.0168 67.3312 64.6254L76.7775 55.1547ZM67.3312 64.6254L82.9343 80.1883L92.3806 70.7176L76.7775 55.1547L67.3312 64.6254Z"
                      fill="black"
                    />
                    <path
                      d="M22.2479 31.0643L26.2922 33.3992L22.0347 40.9028L21.1802 37.7132L17.9904 38.5679L22.2479 31.0643Z"
                      fill="#AEB4BC"
                    />
                    <rect
                      x="29.7134"
                      y="9.10544"
                      width="14.5938"
                      height="22.7663"
                      transform="rotate(30 29.7134 9.10544)"
                      fill="url(#paint0_linear_3093_14195)"
                    />
                    <path
                      d="M31.5563 35.1094L29.4886 33.9156L39.7042 16.2216L41.772 17.4154L31.5563 35.1094Z"
                      fill="url(#paint1_linear_3093_14195)"
                    />
                    <rect
                      x="42.3501"
                      y="16.4027"
                      width="1.1675"
                      height="22.7663"
                      transform="rotate(30 42.3501 16.4027)"
                      fill="url(#paint2_linear_3093_14195)"
                    />
                    <path
                      d="M28.2419 8.23639L40.2476 15.1678L28.8644 34.884L16.8588 27.9525L28.2419 8.23639Z"
                      fill="#F8D33A"
                    />
                    <path
                      d="M26.3864 7.16406L29.7566 9.1099L18.3735 28.8261L15.0032 26.8802L26.3864 7.16406Z"
                      fill="url(#paint3_linear_3093_14195)"
                    />
                    <path
                      d="M26.0819 26.0401L26.6137 25.1189L26.0086 24.7695L25.4768 25.6907L24.4833 25.1172L25.0152 24.196L24.1121 23.6746L27.543 17.732L28.4461 18.2534L28.978 17.3322L29.9714 17.9058L29.4396 18.827L30.0447 19.1763L30.5765 18.2551L31.57 18.8287L31.0225 19.777C31.7262 20.3518 31.8545 21.1725 31.3852 21.9853C30.9107 22.8071 30.337 22.9456 29.494 22.5913L29.4627 22.6455C30.2585 23.1651 30.5785 23.9038 30.0415 24.8341C29.5148 25.7462 28.4641 26.127 27.6072 25.6925L27.0753 26.6137L26.0819 26.0401ZM28.2036 19.5704L27.4266 20.9161L28.971 21.8077C29.3142 22.0058 29.6262 21.9451 29.8243 21.6019L29.9964 21.3039C30.1945 20.9607 30.0911 20.6602 29.7479 20.462L28.2036 19.5704ZM26.8218 21.9637L26.0345 23.3274L27.7956 24.3442C28.1387 24.5423 28.465 24.4778 28.6631 24.1346L28.8352 23.8366C29.0333 23.4934 28.9261 23.1786 28.5829 22.9805L26.8218 21.9637Z"
                      fill="black"
                    />
                    <path
                      d="M18.7597 16.7115L22.0698 18.9027"
                      stroke="#935A30"
                      strokeWidth="4.35032"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12.2331 22.7195L16.9314 15.585L21.9778 18.5867L17.4099 25.7212C15.6305 28.5006 14.1472 28.2009 12.7551 27.4614C10.5799 25.9388 11.363 23.9811 12.2331 22.7195Z"
                      fill="#935A30"
                    />
                    <path
                      d="M59.0508 56.3086L62.0697 53.1572L64.5106 53.1572L59.0723 58.5955L53.595 53.1726L55.8052 53.1726L59.0508 56.3086Z"
                      fill="#F8D33A"
                    />
                    <rect
                      width="4.42862"
                      height="4.42862"
                      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 67.8284 66.2133)"
                      fill="#F0B90B"
                    />
                    <circle
                      cx="8.63443"
                      cy="8.63443"
                      r="8.63443"
                      transform="matrix(-1 0 0 1 68.1106 31.2184)"
                      fill="#935A30"
                    />
                    <mask
                      id="mask1_3093_14195"
                      maskUnits="userSpaceOnUse"
                      x={50}
                      y={31}
                      width={19}
                      height={18}
                    >
                      <circle
                        cx="8.63443"
                        cy="8.63443"
                        r="8.63443"
                        transform="matrix(-0.965926 -0.258819 -0.258819 0.965926 70.052 33.7491)"
                        fill="#E5663F"
                      />
                    </mask>
                    <g mask="url(#mask1_3093_14195)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M56.0439 35.4582C49.9023 33.8126 46.2576 27.4998 47.9032 21.3582C49.5489 15.2167 55.8616 11.572 62.0032 13.2176C68.1448 14.8632 71.7895 21.176 70.1438 27.3176C69.761 28.7465 69.1254 30.0402 68.2982 31.1636C69.4436 30.9885 70.6432 31.0423 71.8341 31.3615C76.4403 32.5957 79.1738 37.3303 77.9396 41.9364C76.7054 46.5426 71.9708 49.2761 67.3646 48.0419C62.7584 46.8077 60.0249 42.0731 61.2591 37.4669C61.4537 36.7409 61.7352 36.0614 62.0894 35.4366C60.1762 35.9671 58.1004 36.0092 56.0439 35.4582Z"
                        fill="black"
                      />
                    </g>
                    <path
                      d="M71.6134 29.9909L62.0356 24.4612L58.067 31.335L67.6122 37.0494L71.6134 29.9909Z"
                      fill="black"
                    />
                    <rect
                      width="22.8477"
                      height="0.878708"
                      transform="matrix(-0.866025 -0.5 -0.5 0.866025 77.1537 32.1825)"
                      fill="black"
                    />
                    <path
                      d="M72.0793 29.2441L77.1517 32.1737L77.1516 44.0292L74.6155 41.493L72.0792 44.0292L72.0793 29.2441Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_3093_14195"
                      x1="37.0103"
                      y1="9.10544"
                      x2="37.0103"
                      y2="31.8717"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F0B90B" />
                      <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_3093_14195"
                      x1="38.1456"
                      y1="18.9626"
                      x2="29.3359"
                      y2="34.2216"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset={1} stopColor="#F5F5F5" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_3093_14195"
                      x1="42.9338"
                      y1="16.4027"
                      x2="42.9338"
                      y2="39.169"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F0B90B" />
                      <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_3093_14195"
                      x1="28.0715"
                      y1="8.13698"
                      x2="16.6884"
                      y2="27.8531"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F0B90B" />
                      <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="bn-flex flex-col gap-4">
                  <div className="t-subtitle1">
                    Programas de aprendizaje y desarrollo
                  </div>
                  <div className="t-subtitle1">Clases de idiomas gratuitos</div>
                  <div className="t-subtitle1">Soporte para reubicación</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 pc:mt-[120px]">
            <div className="mb-10 pc:mb-16">
              <div className="t-headline1 text-t-primary">
                ¿Cómo es el proceso de contrato?
              </div>
              <div className="t-body1-1 mt-4 text-t-third">
                En promedio, el proceso de entrevista varía de 2 a 4 semanas con
                4 entrevistas.
              </div>
            </div>
            <div
              className="bn-grid grid-cols-1 flex-col justify-between gap-x-6 gap-y-10 noMobile:grid-cols-4"
              style={{ gridTemplateColumns: "repeat(2, 1fr)", fontFamily:'Plex' }}
            >
              <div className="bn-flex flex-col gap-2">
                <div className="t-title1 noMobile:t-title2 text-line">01</div>
                <div className="t-subtitle1">Solicitud de aplicación</div>
              </div>
              <div className="bn-flex flex-col gap-2">
                <div className="t-title1 noMobile:t-title2 text-line">02</div>
                <div className="t-subtitle1">Entrevistas</div>
              </div>
              <div className="bn-flex flex-col gap-2">
                <div className="t-title1 noMobile:t-title2 text-line">03</div>
                <div className="t-subtitle1">Oferta</div>
              </div>
              <div className="bn-flex flex-col gap-2">
                <div className="t-title1 noMobile:t-title2 text-line">04</div>
                <div className="t-subtitle1">Incorporación</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
</div>

    <Footer/>
    </>
  );
}

export default Careers;