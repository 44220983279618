import React, { useEffect, useState } from 'react';

import imgLogo from "./assets/img/suiteios_logo.png"
import Header from './Header';

function Dashboard() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const element = document.getElementById('15ojvzg');
    if (element) {
      if (isMobile) {
        element.remove();  // Llama a remove() correctamente
      } else {
      }
    }
  }, [isMobile]);
    const styles = {
        '--color-sell': '#F6465D',
        '--color-textSell': '#F6465D',
        '--color-sellHover': '#FF707E',
        '--color-depthSellBg': '#35141D',
        '--color-buy': '#0ECB81',
        '--color-textBuy': '#0ECB81',
        '--color-buyHover': '#32D993',
        '--color-depthBuyBg': '#102821',
        '--color-line': '#2B3139',
        '--color-outlineHover': '#6A4403',
        '--color-primary': '#FCD535',
        '--color-primaryHover': '#F0B90B',
        '--color-error': '#F6465D',
        '--color-errorBg': '#35141D',
        '--color-success': '#0ECB81',
        '--color-successBg': '#102821',
        '--color-disable': '#474D57',
        '--color-iconNormal': '#848E9C',
        '--color-textWhite': '#FFFFFF',
        '--color-textGray': '#EAECEF',
        '--color-textBlack': '#0B0E11',
        '--color-textPrimary': '#EAECEF',
        '--color-textSecondary': '#B7BDC6',
        '--color-textThird': '#848E9C',
        '--color-textDisabled': '#5E6673',
        '--color-textBrand': '#F0B90B',
        '--color-textToast': '#A37200',
        '--color-bg': '#181A20',
        '--color-bg1': '#181A20',
        '--color-bg2': '#0B0E11',
        '--color-bg3': '#2B3139',
        '--color-bg4': '#5E6673',
        '--color-bg6': '#202630',
        '--color-bg7': '#191A1F',
        '--color-popupBg': '#1E2329',
        '--color-badgeBg': '#2D2A20',
        '--color-selectedBg': '#1E2026',
        '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
        '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
        '--color-BasicBg': '#181A20',
        '--color-SecondaryBg': '#0B0E11',
        '--color-Input': '#2B3139',
        '--color-InputLine': '#474D57',
        '--color-CardBg': '#1E2329',
        '--color-Vessel': '#1E2329',
        '--color-Line': '#2B3139',
        '--color-DisableBtn': '#2B3139',
        '--color-DisabledText': '#5E6673',
        '--color-DisableText': '#5E6673',
        '--color-TertiaryText': '#848E9C',
        '--color-SecondaryText': '#B7BDC6',
        '--color-PrimaryText': '#EAECEF',
        '--color-RedGreenBgText': '#FFFFFF',
        '--color-EmphasizeText': '#FF693D',
        '--color-TextOnGray': '#EAECEF',
        '--color-TextOnYellow': '#202630',
        '--color-IconNormal': '#848E9C',
        '--color-LiteBg1': '#202630',
        '--color-LiteBg2': '#191A1F',
        '--color-BtnBg': '#FCD535',
        '--color-PrimaryYellow': '#F0B90B',
        '--color-TextLink': '#F0B90B',
        '--color-TextToast': '#A37200',
        '--color-DepthSellBg': '#35141D',
        '--color-SellHover': '#FF707E',
        '--color-Sell': '#F6465D',
        '--color-TextSell': '#F6465D',
        '--color-DepthBuyBg': '#102821',
        '--color-BuyHover': '#32D993',
        '--color-Buy': '#2EBD85',
        '--color-TextBuy': '#2EBD85',
        '--color-Error': '#F6465D',
        '--color-SuccessBg': '#102821',
        '--color-Success': '#2EBD85',
        '--color-TagBg': '#474D57',
        '--color-Grid': '#2B3139',
        '--color-Placeholder': '#474D57',
        '--color-ToastBg': '#707A8A',
        '--color-TwoColorIcon': '#CACED3',
        '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
        '--color-BadgeBg': 'rgba(240,185,11,0.1)'
      };
  return (
    <>
    <Header/>
<div id="__APP_SIDEBAR">
  <div className="theme-root dark _CWC" style={{styles}}>
    <div id="common-widget-sidebar-wrap" className="hidden-in-bnc-app">
      <div className="sidebar-h5-banner w-full">
        <div className="bn-flex items-center">
          <svg
            size={24}
            className="bn-svg sidebar-text-active sidebar-icon-size mr-2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 12v8h5.5v-6h5v6H20v-8l-8-8-8 8z"
              fill="currentColor"
            />
          </svg>
          <div className="sidebar-text-active typography-subtitle1">
            Panel de control
          </div>
        </div>
        <svg
          size={24}
          className="bn-svg sidebar-icon-size transition rotate-0"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div className="sidebar-container hide-container">
        <div className="menu-wrap">
          <div className="sidebar-menu-item">
            <a
              className="sidebar-link sidebar-base-nav-link sidebar-base-nav-link-active text-active"
              href="/es/my/dashboard"
              rel="noopener noreferrer"
            >
              <div className="bn-flex sidebar-base-nav-link-text text-active">
                <svg
                  size={24}
                  className="bn-svg icon-active left-icon-pc sidebar-icon-size shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 12v8h5.5v-6h5v6H20v-8l-8-8-8 8z"
                    fill="currentColor"
                  />
                </svg>
                <div className="sidebar-menu-item-text typography-subtitle1 text-active">
                  Panel de control
                </div>
              </div>
            </a>
          </div>
          <div
            style={{ flexDirection: "column", marginBottom: 8 }}
            className="bn-flex"
          >
            <div className="cursor-pointer">
              <div className="sidebar-menu-item">
                <a className="sidebar-link sidebar-base-nav-link">
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <svg
                      size={24}
                      className="bn-svg icon-normal left-icon-pc sidebar-icon-size shrink-0"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 8.5A4.5 4.5 0 018.5 4H20v16H8.5A4.5 4.5 0 014 15.5v-7zM8.5 7H17v3H8.5a1.5 1.5 0 110-3zm4.5 6h4v4h-4v-4z"
                        fill="currentColor"
                      />
                    </svg>
                    <div className="sidebar-menu-item-text typography-subtitle1">
                      Activos
                    </div>
                  </div>
                  <svg
                    size={20}
                    className="bn-svg icon-normal rotate-0 icon-pc sidebar-icon-size hover:icon-active"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div style={{ display: "none" }}>
              <div style={{ flexDirection: "column" }} className="bn-flex">
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/overview"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_overview"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Visión general
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/main"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_fiatspot"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Spot
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/margin"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_margin"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Margen
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/futures"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_futures"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Futures
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/eoptions"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_options"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Options
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/trading-bots"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_tradingbots"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Bots de trading
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/earn"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_earn"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Earn
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/funding"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_funding"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Fondos
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/copy-trader"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_copy_trading"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Copy trading
                    </div>
                  </div>
                </a>
                <div className="bn-flex" style={{ flexDirection: "column" }}>
                  <div className="cursor-pointer">
                    <div className="sidebar-menu-item">
                      <a className="sidebar-link sidebar-base-nav-link">
                        <div className="bn-flex sidebar-base-nav-link-text">
                          <div className="sidebar-menu-item-text typography-subtitle1 not-clone-icon">
                            Billetera de terceros
                          </div>
                        </div>
                        <svg
                          size={20}
                          className="bn-svg icon-normal rotate-0 icon-pc sidebar-icon-size hover:icon-active"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div style={{ display: "none" }}>
                    <div
                      className="bn-flex"
                      style={{ flexDirection: "column" }}
                    >
                      <a
                        className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                        href="/es/my/wallet/account/partner/SuiteiOSTR/transfer"
                        rel="noopener noreferrer"
                        id="SuiteiOS_main_sidebar_SuiteiOStr"
                      >
                        <div className="bn-flex sidebar-base-nav-link-text">
                          <div className="typography-subtitle1 sidebar-submenu-item-text">
                            SuiteiOS TR
                          </div>
                        </div>
                      </a>
                      <a
                        className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                        href="/es/my/wallet/account/partner/tokocrypto/transfer"
                        rel="noopener noreferrer"
                        id="SuiteiOS_main_sidebar_tokocrypto"
                      >
                        <div className="bn-flex sidebar-base-nav-link-text">
                          <div className="typography-subtitle1 sidebar-submenu-item-text">
                            Tokocrypto
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ flexDirection: "column", marginBottom: 8 }}
            className="bn-flex"
          >
            <div className="cursor-pointer">
              <div className="sidebar-menu-item">
                <a className="sidebar-link sidebar-base-nav-link">
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <svg
                      size={24}
                      className="bn-svg icon-normal left-icon-pc sidebar-icon-size shrink-0"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.5 3v18h4.91A7.5 7.5 0 0118.5 9.365V7l-4-4h-10zm16 13a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-4.79-2.875h-2v4l3.031 1.75 1-1.732-2.031-1.173v-2.845z"
                        fill="currentColor"
                      />
                    </svg>
                    <div className="sidebar-menu-item-text typography-subtitle1">
                      Órdenes
                    </div>
                  </div>
                  <svg
                    size={20}
                    className="bn-svg icon-normal rotate-0 icon-pc sidebar-icon-size hover:icon-active"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div style={{ display: "none" }}>
              <div style={{ flexDirection: "column" }} className="bn-flex">
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/orders/exchange"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_orders_spot"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Orden spot
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/orders/futures"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_orders_futures"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Futures Order
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/orders/p2p"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_orders_c2c"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Orden P2P
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/history/deposit-crypto"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_transaction_history"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Historial de transacciones
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/exchange/buysell-history"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_orders_fiat"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Orden fiat
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/orders/convert/history"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_convert_history"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Historial de conversiones
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/orders/payment"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_orders_payment"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Historial de pagos
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="sidebar-menu-item">
            <a
              className="sidebar-link sidebar-base-nav-link"
              href="/es/rewards-hub"
              rel="noopener noreferrer"
            >
              <div className="bn-flex sidebar-base-nav-link-text">
                <svg
                  className="bn-svg icon-normal left-icon-pc sidebar-icon-size shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 5h18v4a3 3 0 100 6v4H3v-4a3 3 0 100-6V5zm12.5 2.5H13v9h2.5v-9z"
                    fill="currentColor"
                  />
                </svg>
                <div className="sidebar-menu-item-text typography-subtitle1">
                  Portal de recompensas
                </div>
              </div>
            </a>
          </div>
          <div className="sidebar-menu-item">
            <a
              className="sidebar-link sidebar-base-nav-link"
              href="/es/activity/referral"
              rel="noopener noreferrer"
            >
              <div className="bn-flex sidebar-base-nav-link-text">
                <svg
                  className="bn-svg icon-normal left-icon-pc sidebar-icon-size shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 8.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM2 17a3 3 0 013-3h5a3 3 0 013 3v3H2v-3zm14.5-1v-3h-3v-3h3V7h3v3h3v3h-3v3h-3z"
                    fill="currentColor"
                  />
                </svg>
                <div className="sidebar-menu-item-text typography-subtitle1">
                  Referido
                </div>
              </div>
            </a>
          </div>
          <div
            className="bn-flex"
            style={{ flexDirection: "column", marginBottom: 8 }}
          >
            <div className="cursor-pointer">
              <div className="sidebar-menu-item">
                <a className="sidebar-link sidebar-base-nav-link">
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <svg
                      className="bn-svg icon-normal left-icon-pc sidebar-icon-size shrink-0"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 8a4 4 0 11-8 0 4 4 0 018 0zm-8 6a4 4 0 00-4 4v2h16v-2a4 4 0 00-4-4H8z"
                        fill="currentColor"
                      />
                    </svg>
                    <div className="sidebar-menu-item-text typography-subtitle1">
                      Cuenta
                    </div>
                  </div>
                  <svg
                    size={20}
                    className="bn-svg icon-normal rotate-0 icon-pc sidebar-icon-size hover:icon-active"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div style={{ display: "none" }}>
              <div className="bn-flex" style={{ flexDirection: "column" }}>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/settings/profile"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_identification"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Identificación
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/security"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_security"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Seguridad
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/payment/c2c"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_payment"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Pago
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/settings/api-management"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_api_management"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Gestión de API
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/wallet/account/statement"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_account_statement"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Extracto de cuenta
                    </div>
                  </div>
                </a>
                <a
                  className="sidebar-link sidebar-base-nav-link sidebar-submenu-item"
                  href="/es/my/financial-reports"
                  rel="noopener noreferrer"
                  id="SuiteiOS_main_sidebar_tax"
                >
                  <div className="bn-flex sidebar-base-nav-link-text">
                    <div className="typography-subtitle1 sidebar-submenu-item-text">
                      Informes financieros
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="sidebar-menu-item">
            <a
              className="sidebar-link sidebar-base-nav-link"
              href="/es/my/sub-account/apply-sub-account"
              rel="noopener noreferrer"
            >
              <div className="bn-flex sidebar-base-nav-link-text">
                <svg
                  className="bn-svg icon-normal left-icon-pc sidebar-icon-size shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 8.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM3 17a3 3 0 013-3h5a3 3 0 013 3v3H3v-3zM21 5h-5v3h5V5zm0 5.002h-5v3h5v-3zm-5 5.002h5v3h-5v-3z"
                    fill="currentColor"
                  />
                </svg>
                <div className="sidebar-menu-item-text typography-subtitle1">
                  Subcuentas
                </div>
              </div>
            </a>
          </div>
          <div className="sidebar-menu-item">
            <a
              className="sidebar-link sidebar-base-nav-link"
              href="/es/my/settings/preference"
              rel="noopener noreferrer"
            >
              <div className="bn-flex sidebar-base-nav-link-text">
                <svg
                  className="bn-svg icon-normal left-icon-pc sidebar-icon-size shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8 3h-3.6v2.027c-.66.17-1.285.431-1.858.77L6.91 4.363 4.363 6.91l1.434 1.433a7.157 7.157 0 00-.77 1.858H3v3.6h2.027c.17.66.431 1.285.77 1.858L4.363 17.09l2.546 2.546 1.433-1.434c.573.339 1.197.6 1.858.77V21h3.6v-2.027a7.157 7.157 0 001.858-.77l1.433 1.434 2.546-2.546-1.434-1.434a7.16 7.16 0 00.77-1.857H21v-3.6h-2.027a7.158 7.158 0 00-.77-1.858l1.434-1.433-2.546-2.546-1.434 1.434a7.156 7.156 0 00-1.857-.77V3zm-4.5 9a2.7 2.7 0 115.4 0 2.7 2.7 0 01-5.4 0z"
                    fill="currentColor"
                  />
                </svg>
                <div className="sidebar-menu-item-text typography-subtitle1">
                  Configuración
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="__APP">
  <div className="theme-root dark" style={{styles}}>
    <div className="bn-flex dashboard-v3-wrap mb-[80px] flex-col">
      <div className="w-full">
        <div className="bn-flex relative mb-10 min-h-[60px] justify-between pt-6 mobile:px-4 pc:mb-4 pc:min-h-[104px] pc:items-start pc:justify-start pc:px-0">
          <div className="bn-flex items-center pc:items-start">
            <div className="bn-flex relative shrink-0">
              <img
                alt="usr avatar"
                src="https://public.nftstatic.com/static/nft/res/nft-cex/S3/1695716378565_p7gg128kqzin6mp0ezo3tlwjbbmqblp9.png"
                className="object-cover cursor-pointer flex items-center pc:items-start  w-[56px] h-[56px] pc:min-w-[64px] pc:min-h-[64px] rounded-[10px]"
              />
              <div className="bn-flex mobile:hidden w-[56px] h-[56px] pc:min-w-[64px] pc:min-h-[64px] rounded-[10px] group absolute top-0 flex cursor-pointer items-center justify-center hover:bg-[#181A20] hover:bg-opacity-80">
                <svg
                  className="bn-svg text-t-gray opacity-0 group-hover:opacity-100"
                  size={24}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.336 4.776l3.89 3.889-1.768 1.767-3.89-3.889 1.768-1.767zm-3.181 3.181l3.889 3.89-5.129 5.128H20v3H7.915l-.004.003H4.022V16.09l8.133-8.132z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
            <div className="bn-flex flex-col pl-4 pc:pl-6">
              <div
                id="dashboard-userinfo-nickname"
                style={{fontFamily:'PlexSemiBold'}}
                className="mx-w-[171px] mb-2 line-clamp-1 min-h-[24px]  min-w-[200px] overflow-hidden truncate whitespace-normal break-all font-semibold leading-[24px] pc:mb-3"
              >
                sfab22
              </div>
              <div className="bn-flex h-[28px] flex-row gap-1">
                <div className="user-info-iconwarp" id="BABENTRY">
                  <div className="bn-tooltips-wrap">
                    <div className="bn-tooltips-ele">
                      <svg
                        className="bn-svg"
                        size={16}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.293 15.182l4.243-4.243-1.415-1.414-2.828 2.829-1.414-1.415-1.415 1.414 2.829 2.829z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.5 3h5L21 9.5v5L14.5 21h-5L3 14.5v-5L9.5 3zm7.5 9a5 5 0 11-10 0 5 5 0 0110 0z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div
                      className="bn-bubble bn-bubble__gray data-font-14 bn-tooltips"
                      style={{
                        transform: "translate(-50%, 0px)",
                        top: "100%",
                        left: "50%"
                      }}
                    >
                      <div
                        className="bn-bubble-arrow"
                        style={{
                          transform: "translate(-50%, 0px) rotate(45deg)",
                          top: 9,
                          left: "50%"
                        }}
                      />
                      <div
                        className="bn-bubble-content"
                        style={{ marginTop: 12 }}
                      >
                        <div className="typography-Body3">
                          Actualmente no tienes ningún token SuiteiOS Account
                          Bound.&nbsp;
                          <a className="text-t-brand underline" href="/babt">
                            Obtener token BAB.
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_CWC" />
                <div className="_CWC">
                  <div className="user-info-iconwarp">
                    <div className="bn-tooltips-wrap">
                      <div className="bn-tooltips-ele">
                        <svg
                          size={24}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="bn-svg"
                        >
                          <path
                            d="M3.044 3.576l6.95 9.293L3 20.424h1.574l6.123-6.615 4.947 6.615H21l-7.34-9.815 6.509-7.033h-1.574l-5.639 6.092L8.4 3.576H3.044zm2.314 1.16H7.82l10.866 14.528h-2.46L5.358 4.736z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div
                        className="bn-bubble bn-bubble__gray data-font-14 bn-tooltips"
                        style={{
                          transform: "translate(-50%, 0px)",
                          top: "100%",
                          left: "50%"
                        }}
                      >
                        <div
                          className="bn-bubble-arrow"
                          style={{
                            transform: "translate(-50%, 0px) rotate(45deg)",
                            top: 9,
                            left: "50%"
                          }}
                        />
                        <div
                          className="bn-bubble-content"
                          style={{ marginTop: 12 }}
                        >
                          <p className="typegraphy-body3">Link Twitter</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="split-line mobile:hidden" />
          <div className="bn-flex gap-3 pc:hidden">
            <div className="bn-flex userinfo-mobile_icon">
              <svg
                className="bn-svg userinfo-mobile_iconsvg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.336 4.776l3.89 3.889-1.768 1.767-3.89-3.889 1.768-1.767zm-3.181 3.181l3.889 3.89-5.129 5.128H20v3H7.915l-.004.003H4.022V16.09l8.133-8.132z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="bn-flex userinfo-mobile_icon">
              <svg
                fill="iconNormal"
                size={22}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="bn-svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <div id='15ojvzg' className="bn-flex flex-wrap pc:max-w-[570px] pc:flex-row pc:flex">
            <div className="flex-col mr-0 flex-row justify-between pb-4 text-sm leading-5 pc:mr-[40px] pc:min-h-[44px] pc:flex-col pc:justify-normal pc:last:mr-[0px] min-w-[115px]">
              <div className="bn-flex mb-0 text-iconNormal pc:mb-1">
                ID de usuario
              </div>
              <div className="flex-col">
                <div className="bn-flex items-center">
                  <div className='text-icon2'>792085694</div>
                  <div className="css-1f9551p">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      id="dashboard-userinfo-copy-userid"
                      className="ml-0 flex cursor-pointer text-iconNormal css-woosrk"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col mr-0 flex-row justify-between pb-4 text-sm leading-5 pc:mr-[40px] pc:min-h-[44px] pc:flex-col pc:justify-normal pc:last:mr-[0px] min-w-[100px]">
              <div className="bn-flex mb-0 text-iconNormal pc:mb-1">
                Nivel VIP
              </div>
              <div className="bn-flex">
                <a
                  id="SuiteiOS_dashboard_v1_person_vip_level"
                  href="/fee/trading"
                  target="_blank"
                  className="cursor-pointer text-t-primary"
                  rel="noreferrer"
                >
                  <div className="text-icon2 bn-flex group items-center">
                    Usuario habitual
                    <svg
                      className="bn-svg arrow text-base text-iconNormal group-hover:text-t-primary"
                      size={16}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
            <div className="flex-col mr-0 flex-row justify-between pb-4 text-sm leading-5 pc:mr-[40px] pc:min-h-[44px] pc:flex-col pc:justify-normal pc:last:mr-[0px] min-w-[60px]">
              <div className="bn-flex mb-0 text-iconNormal pc:mb-1">
                Tipo de usuario
              </div>
              <div className="bn-flex text-icon2 ">
                <div>Información Personal</div>
              </div>
            </div>
            <div className="flex-col mr-0 flex-row justify-between pb-4 text-sm leading-5 pc:mr-[40px] pc:min-h-[44px] pc:flex-col pc:justify-normal pc:last:mr-[0px] min-w-[60px]">
              <div className="flex-col mb-0 text-iconNormal pc:mb-1">
                Siguiendo
              </div>
              <div className="bn-flex">
                <div
                  id="SuiteiOS_dashboard_v1_following"
                  className="text-icon2 bn-flex group items-center text-t-primary"
                >
                  0
                </div>
              </div>
            </div>
            <div className="flex-col mr-0 flex-row justify-between pb-4 text-sm leading-5 pc:mr-[40px] pc:min-h-[44px] pc:flex-col pc:justify-normal pc:last:mr-[0px] min-w-[60px]">
              <div className="text-icon2 bn-flex mb-0 text-iconNormal pc:mb-1">
                Seguidores
              </div>
              <div className="bn-flex">
                <a
                  id="SuiteiOS_dashboard_v1_person_follower"
                  href="/es/feed/profile/792085694/followers"
                  target="_blank"
                  className="cursor-pointer text-t-primary"
                  rel="noreferrer"
                >
                  <div className="bn-flex group items-center">
                    0
                    <svg
                      className="bn-svg arrow text-base text-iconNormal group-hover:text-t-primary"
                      size={16}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pc:px-0 mobile:px-4 " />
      <div className="w-full pc:px-0 mobile:px-4 mb-16 pc:mb-0">
        <div className="pc:p-xl mb-xl pc:border border-line rounded-xl">
          <div className="flex justify-between tablet:flex-col mobile:flex-col pc:mb-3">
            <div>
              <div className="text-t-primary">
                <div className="mb-2xs flex items-center">
                  <div style={{fontSize:'20px'}} className="pc:subtitle1 subtitle3">Balance estimado</div>
                  <div
                    id="dashboard-asset-balance-switch-hidden"
                    className="text-iconNormal px-4xs hover:text-t-primary cursor-pointer"
                  >
                    <svg
                      className="bn-svg text-base"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 14.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.555 6.31L1 12l5.555 5.69a7.572 7.572 0 0010.89 0L23 12l-5.555-5.69a7.572 7.572 0 00-10.89 0zM17 12a5 5 0 11-10 0 5 5 0 0110 0z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex cursor-pointer items-end">
                  <div className="typography-Headline4 "  style={{fontFamily:'PlexSemiBold'}}>
                    <div className="hover:text-t-secondary" style={{fontFamily:'PlexSemiBold'}}>0,03562814</div>
                  </div>
                  <div className="css-ybbx55">
                    <div className="css-yx20x2">
                      <div data-bn-type="text" className="css-1ydyagz">
                        USDT
                      </div>
                      <div className="css-164hzqc">
                        <svg
                          size={16}
                          className="bn-svg arrow"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body3 mt-2">
                  <div className="hover:text-t-secondary" style={{fontFamily:'Plex'}}> ≈ $0.03562814</div>
                </div>
              </div>
              <div style={{fontFamily:'PlexRegular'}} className="body3 mt-2 flex items-center">
                <div>PnL de hoy</div>
                <div className="bn-tooltips-wrap mr-2">
                  <div className="bn-tooltips-ele">
                    <svg
                      className="bn-svg ml-4xs text-iconNormal w-3 cursor-pointer align-middle text-base"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div
                    className="bn-bubble bn-bubble__gray data-font-14 bn-tooltips"
                    style={{
                      transform: "translate(0px, 0px)",
                      bottom: "100%",
                      left: 0
                    }}
                  >
                    <div
                      className="bn-bubble-arrow"
                      style={{
                        transform: "translate(0px, 0px) rotate(-135deg)",
                        bottom: 9,
                        left: 9
                      }}
                    />
                    <div
                      className="bn-bubble-content"
                      style={{ marginBottom: 12 }}
                    >
                      <div className="whitespace-pre-line">
                        «PnL del día de hoy = Total de activos actuales - Total
                        de activos iniciales en el día de hoy - Transferencias y
                        depósitos netos en el día de hoy. La fecha sirve
                        únicamente como referencia y no se garantiza la
                        precisión total de los datos».
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="dashboard-assets-balance-today-pnl-val"
                  className="text-t-buy"
                >
                  + $0,00(2.13%)
                </div>
              </div>
            </div>
            <div className="mobile:mt-xl tablet:mt-xl">
              <div className="mobile:justify-between flex items-center">
                <div className="bn-flex mr-3 mobile:flex-1 mobile:[&>*]:w-full">
                  <div className="_CWC">
                    <div>
                      <button
                        id="dashboard-assets-deposit-btn"
                        className="bn-button bn-button__secondary data-size-small w-full"
                      >
                        Depositar
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  id="dashboard-assets-withdraw-btn"
                  className="bn-button bn-button__secondary data-size-small mr-3 mobile:flex-1"
                >
                  Retirar
                </button>
                <button
                  id="dashboard-assets-cash-in-btn"
                  className="bn-button bn-button__secondary data-size-small mobile:flex-1"
                >
                  Donar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="uikit-carousel css-192757y">
          <div className="slick-slider slick-initialized">
            <div className="slick-list">
              <div className="slick-track" style={{ width: 572, opacity: 1 }}>
                <div
                  data-index={0}
                  className="slick-slide slick-active slick-current"
                  tabIndex={-1}
                  aria-hidden="false"
                  style={{
                    outline: "none",
                    width: 572,
                    position: "relative",
                    left: 0,
                    opacity: 1,
                    transition: "opacity 500ms, visibility 500ms"
                  }}
                >
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
      <div className="markets-panel mb-16 rounded-xl border-line pc:mb-xl pc:border pc:p-xl">
  <div className="mobile:px-4 pc:px-0">
    <div className="mb-4 flex justify-between align-middle">
      <div className="bn-flex subtitle1 pc:headline6">
        <div className="mr-6 cursor-pointer mainTitle">iPhone</div>
        <div className="mainTitle cursor-pointer text-t-third hover:text-t-primary">
          iPad
        </div>
        <div className="mainTitle ml-6 cursor-pointer text-t-third hover:text-t-primary">
          MacBook
        </div>
      </div>
      <div
        className="subtitle4 group flex cursor-pointer items-center"
        id="dashboard-markets-link"
      >
        Más
        <svg
          className="bn-svg group-hover:text-t-primary text-iconNormal h-4 w-4"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div className="overflow-x-auto pc:pl-0">
      <div className="mobile:mb-4 mb-m flex items-center">
        <div
          id="dashboard-market-tab-holding"
          className="mr-xl cursor-pointer text-t-primary flex flex-col items-center last:mr-0 pc:last:pr-0 tablet:last:pr-6 mobile:last:pr-4 font-[500] whitespace-nowrap"
        >
          <div className="subtitle3 mb-[5px] hover:text-t-primary">Usuales</div>
          <div className="bg-primary h-[3px] transition-all duration-150 ease-linear w-4" />
        </div>
        <div
          id="dashboard-market-tab-hot"
          className="mr-xl cursor-pointer text-t-third flex flex-col items-center last:mr-0 pc:last:pr-0 tablet:last:pr-6 mobile:last:pr-4 font-[500] whitespace-nowrap"
        >
          <div className="subtitle3 mb-[5px] hover:text-t-primary">
            Populares
          </div>
          <div className="bg-primary h-[3px] transition-all duration-150 ease-linear w-0" />
        </div>
        <div
          id="dashboard-market-tab-newListing"
          className="mr-xl cursor-pointer text-t-third flex flex-col items-center last:mr-0 pc:last:pr-0 tablet:last:pr-6 mobile:last:pr-4 font-[500] whitespace-nowrap"
        >
          <div className="subtitle3 mb-[5px] hover:text-t-primary">
            Nueva inclusión
          </div>
          <div className="bg-primary h-[3px] transition-all duration-150 ease-linear w-0" />
        </div>
        <div
          id="dashboard-market-tab-favorites"
          className="mr-xl cursor-pointer text-t-third flex flex-col items-center last:mr-0 pc:last:pr-0 tablet:last:pr-6 mobile:last:pr-4 font-[500] whitespace-nowrap"
        >
          <div className="subtitle3 mb-[5px] hover:text-t-primary">
            Favoritos
          </div>
          <div className="bg-primary h-[3px] transition-all duration-150 ease-linear w-0" />
        </div>
        <div
          id="dashboard-market-tab-topGainers"
          className="mr-xl cursor-pointer text-t-third flex flex-col items-center last:mr-0 pc:last:pr-0 tablet:last:pr-6 mobile:last:pr-4 font-[500] whitespace-nowrap"
        >
          <div className="subtitle3 mb-[5px] hover:text-t-primary">
            Principales
          </div>
          <div className="bg-primary h-[3px] transition-all duration-150 ease-linear w-0" />
        </div>
        <div
          id="dashboard-market-tab-topVolume"
          className="mr-xl cursor-pointer text-t-third flex flex-col items-center last:mr-0 pc:last:pr-0 tablet:last:pr-6 mobile:last:pr-4 font-[500] whitespace-nowrap"
        >
          <div className="subtitle3 mb-[5px] hover:text-t-primary">
            Volumen en 24&nbsp;h
          </div>
          <div className="bg-primary h-[3px] transition-all duration-150 ease-linear w-0" />
        </div>
      </div>
    </div>
    <div className="pc:px-0 mobile:px-4">
      <div>
        <div className="flex flex-wrap">
          <div
            className="overview-module-hover py-[12px] w-[22%] text-iconNormal flex items-center cursor-pointer justify-start hover:text-t-primary pr-4 last-of-type:pr-0"
            id="asset-table-list-asset"
          >
            <div
              className="caption2 pc:caption1 text-xs font-normal"
              id="table-dashboard-markets-table-asset"
            >
              Moneda
            </div>
            <svg
              className="bn-svg module-hover-icon text-[16px]"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.75 8.849V10.5h6.5V8.85L12 5.348l-3.25 3.5zm6.5 6.303V13.5h-6.5v1.652l3.25 3.5 3.25-3.5z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div
            className="overview-module-hover py-[12px] w-[22%] text-iconNormal flex items-center cursor-pointer justify-end hover:text-t-primary pr-4 last-of-type:pr-0"
            id="asset-table-list-amount"
          >
            <div
              className="caption2 pc:caption1 text-xs font-normal"
              id="table-dashboard-markets-table-amount"
            >
              Precio para el cliente
            </div>
            <svg
              className="bn-svg module-hover-icon text-[16px]"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.75 8.849V10.5h6.5V8.85L12 5.348l-3.25 3.5zm6.5 6.303V13.5h-6.5v1.652l3.25 3.5 3.25-3.5z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div
            className="overview-module-hover py-[12px] w-[22%] text-iconNormal flex items-center cursor-default justify-end hover:text-t-primary pr-4 last-of-type:pr-0"
            id="asset-table-list-close"
          >
            <div className="caption2 pc:caption1 text-xs font-normal">
              <div className="bn-flex cursor-pointer">
                Precio de desbloqueo
                <div className="bn-flex ml-1 items-center">
                  <svg
                    size={14}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bn-svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            className="overview-module-hover py-[12px] w-[22%] text-iconNormal flex items-center cursor-pointer justify-end hover:text-t-primary pr-4 last-of-type:pr-0"
            id="asset-table-list-differenceNumber"
          >
            <div
              className="caption2 pc:caption1 text-xs font-normal"
              id="table-dashboard-markets-table-differenceNumber"
            >
              24H change
            </div>
            <svg
              className="bn-svg module-hover-icon text-[16px]"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.75 8.849V10.5h6.5V8.85L12 5.348l-3.25 3.5zm6.5 6.303V13.5h-6.5v1.652l3.25 3.5 3.25-3.5z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div
            className="overview-module-hover py-[12px] w-[12%] text-iconNormal flex items-center cursor-default justify-end hover:text-t-primary pr-4 last-of-type:pr-0"
            id="asset-table-list-undefined"
          >
            <div className="caption2 pc:caption1 text-xs font-normal">
              Desbloquear
            </div>
          </div>
        </div>
        <div className="-mx-s px-s max-h-[548px] overflow-x-hidden overflow-y-scroll coin-view-pc">
          <div className="px-s -mx-s hover:bg-bg2 rounded-m flex h-[64px] py-[8px] cursor-pointer flex-wrap">
            <div className="flex w-[22%] items-center pr-4 last-of-type:pr-0 justify-start">
              <div className="flex items-center">
                <img
                  className="bn-lazy-img data-round h-[20px] w-[20px] pc:h-[24px] pc:w-[24px]"
                  src="https://bin.bnbstatic.com/image/admin_mgs_image_upload/20220218/94863af2-c980-42cf-a139-7b9f462a36c2.png"
                  data-src="https://bin.bnbstatic.com/image/admin_mgs_image_upload/20220218/94863af2-c980-42cf-a139-7b9f462a36c2.png"
                />
                <div className="ml-2xs flex-1">
                  <div className="typography-Body3 mobile:subtitle3 pc:text-base pc:font-medium">
                    iPhone 15
                  </div>
                  <div className="body3 block truncate text-t-third pc:max-w-[100px]">
                  iPhone 15
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-[22%] items-center pr-4 last-of-type:pr-0 justify-end">
              <div className="text-right">
                <div className="body2">0</div>
                <div className="body3 mt-1 text-t-third">$0</div>
              </div>
            </div>
            <div className="flex w-[22%] items-center pr-4 last-of-type:pr-0 justify-end">
              <div className="text-right">
                <div className="body2 hover:text-t-brand">$ 0</div>
                <div className="body3 mt-1 text-t-third pc:mt-0">
                  <div>--</div>
                </div>
              </div>
            </div>
            <div className="flex w-[22%] items-center pr-4 last-of-type:pr-0 justify-end">
              <div className="text-right">
                <div className="text-t-sell body3 tablet:text-base pc:text-base">
                  -0.23%
                </div>
              </div>
            </div>
            <div className="flex w-[12%] items-center pr-4 last-of-type:pr-0 justify-end">
              <div className="text-right">
                <div className="text-sm text-t-textLink underline hover:text-btnBg">
                  Desbloquear
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden" style={{ height: 1 }} />
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
      <div>
        <span />
      </div>
    </div>
  </div>
</div>

</>

  );
}

export default Dashboard;