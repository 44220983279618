import React from 'react';

import googleIcon from "./assets/img/google-icon.png";
import appleIcon from "./assets/img/apple-icon.png";

import imgLogo from "./assets/img/suiteios_logo.png";
import PageTitle from '../Home/PageTitle';

function StaySigned(props) {
    const themeStyles = {
        '--color-sell': '#F6465D',
        '--color-textSell': '#F6465D',
        '--color-sellHover': '#FF707E',
        '--color-depthSellBg': '#35141D',
        '--color-buy': '#0ECB81',
        '--color-textBuy': '#0ECB81',
        '--color-buyHover': '#32D993',
        '--color-depthBuyBg': '#102821',
        '--color-line': '#2B3139',
        '--color-outlineHover': '#6A4403',
        '--color-primary': '#FCD535',
        '--color-primaryHover': '#F0B90B',
        '--color-error': '#F6465D',
        '--color-errorBg': '#35141D',
        '--color-success': '#0ECB81',
        '--color-successBg': '#102821',
        '--color-disable': '#474D57',
        '--color-iconNormal': '#848E9C',
        '--color-textWhite': '#FFFFFF',
        '--color-textGray': '#EAECEF',
        '--color-textBlack': '#0B0E11',
        '--color-textPrimary': '#EAECEF',
        '--color-textSecondary': '#B7BDC6',
        '--color-textThird': '#848E9C',
        '--color-textDisabled': '#5E6673',
        '--color-textBrand': '#F0B90B',
        '--color-textToast': '#A37200',
        '--color-bg': '#181A20',
        '--color-bg1': '#181A20',
        '--color-bg2': '#0B0E11',
        '--color-bg3': '#2B3139',
        '--color-bg4': '#5E6673',
        '--color-bg6': '#202630',
        '--color-bg7': '#191A1F',
        '--color-popupBg': '#1E2329',
        '--color-badgeBg': '#2D2A20',
        '--color-selectedBg': '#1E2026',
        '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
        '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
        '--color-BasicBg': '#181A20',
        '--color-SecondaryBg': '#0B0E11',
        '--color-Input': '#2B3139',
        '--color-InputLine': '#474D57',
        '--color-CardBg': '#1E2329',
        '--color-Vessel': '#1E2329',
        '--color-Line': '#2B3139',
        '--color-DisableBtn': '#2B3139',
        '--color-DisabledText': '#5E6673',
        '--color-DisableText': '#5E6673',
        '--color-TertiaryText': '#848E9C',
        '--color-SecondaryText': '#B7BDC6',
        '--color-PrimaryText': '#EAECEF',
        '--color-RedGreenBgText': '#FFFFFF',
        '--color-EmphasizeText': '#FF693D',
        '--color-TextOnGray': '#EAECEF',
        '--color-TextOnYellow': '#202630',
        '--color-IconNormal': '#848E9C',
        '--color-LiteBg1': '#202630',
        '--color-LiteBg2': '#191A1F',
        '--color-BtnBg': '#FCD535',
        '--color-PrimaryYellow': '#F0B90B',
        '--color-TextLink': '#F0B90B',
        '--color-TextToast': '#A37200',
        '--color-DepthSellBg': '#35141D',
        '--color-SellHover': '#FF707E',
        '--color-Sell': '#F6465D',
        '--color-TextSell': '#F6465D',
        '--color-DepthBuyBg': '#102821',
        '--color-BuyHover': '#32D993',
        '--color-Buy': '#2EBD85',
        '--color-TextBuy': '#2EBD85',
        '--color-Error': '#F6465D',
        '--color-SuccessBg': '#102821',
        '--color-Success': '#2EBD85',
        '--color-TagBg': '#474D57',
        '--color-Grid': '#2B3139',
        '--color-Placeholder': '#474D57',
        '--color-ToastBg': '#707A8A',
        '--color-TwoColorIcon': '#CACED3',
        '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
        '--color-BadgeBg': 'rgba(240,185,11,0.1)',
      };
  return (
    <>
    <PageTitle title="Mantenerse Conectado | SuiteiOS"/>
    <div style={themeStyles} className='theme-root dark'>
    <div id="__APP">
  <div id="wrap_app" className="bn-flex css-lcpaow">
    <main className="main css-5oj4n">
      <div className="content-layout">
        <div
          className="content-card "
          style={{ border: "1px solid var(--color-line)" }}
        >
          <div className="icon-wrap">
            <img
              className="default-icon block"
              src={imgLogo}
            />
          </div>
          <div className="card-page-title mb-2 md:mb-4">
            Mantener la sesión iniciada
          </div>
          <div className="mb-[32px] typography-body2 md:typography-body1-1 text-[--color-SecondaryText]">
            Al hacer clic en «Sí», puedes mantener la sesión iniciada durante un
            máximo de 5&nbsp;días en este dispositivo. Para anular esta acción,
            cierra la sesión de tu cuenta de SuiteiOS en este dispositivo.
          </div>
          <div style={{fontFamily:'PlexRegular'}} className="bn-checkbox bn-checkbox__square data-size-md stay-signed-in-checkbox mb-[32px] typography-body3 text-[--color-PrimaryText]">
            <div className="bn-checkbox-icon">
              <svg
                fill="BasicBg"
                viewBox="0 0 24 25"
                xmlns="http://www.w3.org/2000/svg"
                className="bn-svg"
              >
                <path
                  d="M19.357 4.687L9.301 14.743l-4.656-4.657-3.03 3.031L9.3 20.804 22.388 7.717l-3.03-3.03z"
                  fill="currentColor"
                />
              </svg>
            </div>
            No volver a mostrar este mensaje en este dispositivo
          </div>
          <button className="bn-button bn-button__primary data-size-large w-full mb-[16px]">
            Sí
          </button>
          <button
            className="bn-button bn-button__secondary data-size-large w-full"
            data-e2e="btn-not-now"
          >
            Ahora no
          </button>
        </div>
      </div>
    </main>
    <div className="flex flex-col items-center">
      <div className="flex items-center justify-center h-[70px] w-[425px] text-[--color-textSecondary]">
        <div className="bn-flex css-1kps7g2">
          <div className="bn-tooltips-wrap css-m0nbpx">
            <div className="bn-tooltips-ele">
              <div
                id="header_menu_current_lng"
                data-e2e="btn-language-icon"
                className="bn-flex css-dpvb9s"
              >
                <svg
                  className="bn-svg css-1s0vphz"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.23 20.403a9.011 9.011 0 005.684-7.153h-3.942c-.147 2.86-.793 5.388-1.741 7.153zm-.757-7.153c-.178 4.102-1.217 7.25-2.473 7.25-1.256 0-2.295-3.148-2.473-7.25h4.946zm0-2.5H9.527C9.705 6.648 10.744 3.5 12 3.5c1.256 0 2.295 3.148 2.473 7.25zm2.499 0h3.942a9.01 9.01 0 00-5.683-7.153c.948 1.765 1.594 4.293 1.741 7.153zm-9.936 0c.147-2.862.793-5.392 1.743-7.156a9.01 9.01 0 00-5.693 7.156h3.95zm0 2.5h-3.95a9.01 9.01 0 005.693 7.157c-.95-1.765-1.596-4.295-1.743-7.157z"
                    fill="currentColor"
                  />
                </svg>
                <div className="footer-text inline text-[--color-textSecondary] hover:text-[--color-textBrand] css-vurnku">
                  Español (Uruguay)
                </div>
              </div>
            </div>
            <div
              className="bn-bubble bn-bubble__unset shadow data-font-14 bn-tooltips"
              style={{
                transform: "translate(-50%, 0px)",
                bottom: "100%",
                left: "50%"
              }}
            >
              <div className="bn-bubble-content" style={{ marginBottom: 4 }}>
                <div className="bn-flex css-1tzfn88">
                  <div className="header-lngCur-content onlyone onlyoneLng  css-vurnku">
                    <div className="header-lngCur-item css-vurnku">
                      <div className="header-select-options-group-flat language-select css-vurnku">
                        <div className="typography-Body3 color-t_third css-vurnku">
                          Idioma
                        </div>
                        <div className="bn-textField bn-textField__line data-size-small data-gap-size-normal">
                          <input
                            data-e2e="input-search-language"
                            placeholder="Search"
                            className="bn-textField-input"
                            spellCheck="false"
                            autoComplete=""
                            defaultValue=""
                          />
                        </div>
                        <div className="header-select-options-group-flat-options  css-jmlio4">
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4 active css-vurnku"
                            data-e2e="link-language-es"
                          >
                            Español (España)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-ar"
                          >
                            العربية
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-ar-BH"
                          >
                            العربية (البحرين)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-bg"
                          >
                            български
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-cs"
                          >
                            Čeština
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-de"
                          >
                            Deutsch (Schweiz)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-da-DK"
                          >
                            Dansk
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en"
                          >
                            English
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-es-LA"
                          >
                            Español (Latinoamérica)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-AU"
                          >
                            English (Australia)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-IN"
                          >
                            English (India)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-NG"
                          >
                            English (Nigeria)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-es-MX"
                          >
                            Español (México)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-el"
                          >
                            Ελληνικά
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-es-AR"
                          >
                            Español (Argentina)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-KZ"
                          >
                            English (Kazakhstan)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-ZA"
                          >
                            English (South Africa)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-NZ"
                          >
                            English (New Zealand)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-BH"
                          >
                            English (Bahrain)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-AE"
                          >
                            English (UAE)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-en-JP"
                          >
                            English (Japan)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-fr"
                          >
                            Français
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-fr-AF"
                          >
                            Français (Afrique)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-hu"
                          >
                            magyar nyelv
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-it"
                          >
                            Italiano
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-id"
                          >
                            Bahasa Indonesia
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-ja"
                          >
                            日本語
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-kk-KZ"
                          >
                            Қазақша (Қазақстан)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-kz"
                          >
                            Қазақша
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-lv"
                          >
                            latviešu valoda
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-lo-LA"
                          >
                            ລາວ
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-pl"
                          >
                            Polski
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-pt-BR"
                          >
                            Português (Brasil)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-pt"
                          >
                            Português
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-ru"
                          >
                            Русский
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-ro"
                          >
                            Română
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-ru-UA"
                          >
                            Русский (Украина)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-ru-KZ"
                          >
                            Русский (Казахстан)
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-sv"
                          >
                            Svenska
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-sk"
                          >
                            Slovenčina
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-sl"
                          >
                            Slovenščina
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-si-LK"
                          >
                            සිංහල
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-tr"
                          >
                            Türkçe
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-uk-UA"
                          >
                            Українська
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-vi"
                          >
                            Tiếng Việt
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-zh-CN"
                          >
                            简体中文
                          </div>
                          <div
                            data-bn-type="text"
                            className="header-select-options typography-Subtitle4  css-vurnku"
                            data-e2e="link-language-zh-TC"
                          >
                            繁體中文
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mx-4 footer-text cursor-pointer hover:text-[--color-textBrand]"
          id="Cookie_Preference"
          data-ot-trigger="true"
          data-e2e="btn-one-trust-cookie"
        >
          Cookies
        </div>
        <div className="mx-4 footer-text cursor-pointer hover:text-[--color-textBrand]">
          Términos
        </div>
        <div className="mx-4 footer-text cursor-pointer hover:text-[--color-textBrand]">
          Privacidad
        </div>
      </div>
    </div>
  </div>
</div>
</div>

    </>
  );
}

export default StaySigned;