import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const SendOrder = () => {
  const [selectedMethod, setSelectedMethod] = useState(''); // Almacena el método de pago seleccionado
  const [deviceChoosed, setDeviceChoosed] = useState(''); // Almacena el dispositivo seleccionado
  const [bypassPrice, setBypassPrice] = useState(''); // Almacena el precio del bypass
  const [imei, setImei] = useState(''); // Almacena el precio del bypass
  const [loading, setLoading] = useState(true); // Estado para manejar la carga de la solicitud
  const navigate = useNavigate(); // Hook para navegar entre rutas

  useEffect(() => {
    const fetchData = async () => {
        const savedMethod = localStorage.getItem('PaymentMethod');
        const device = localStorage.getItem('deviceChoosed');
        const price = localStorage.getItem('bypassPrice');
        const imei = localStorage.getItem('imei');
      
        if (savedMethod) {
          setSelectedMethod(savedMethod);
          setDeviceChoosed(device);
          setBypassPrice(price);
          setImei(imei);
        }
      
        // Espera a que los valores se establezcan en los estados
        await new Promise(resolve => setTimeout(resolve, 100)); // Pequeña pausa para asegurar que los estados se actualicen
      
        const uniqueOrderID = uuidv4(); // Genera un ID único
        localStorage.setItem('orderID', uniqueOrderID); // Guarda el ID en localStorage

      const orderData = {
        id: uniqueOrderID,
        product_name: device,
        price: price, 
        imei: imei
      };

      try {
        const response = await axios.post('https://www.suiteios.com/placeorder.php', orderData);
        if (response.data.status === 'success') {
          console.log("Orden enviada correctamente a la DB");
          window.location.href = '/successpayment'; // Redirige a la página de éxito
        } else {
          console.error("Error en el pedido:", response.data.message);
        }
      } catch (error) {
        console.error("Error al realizar el pedido:", error);
      } finally {
        setLoading(false); // Termina el estado de carga
      }
    };

    fetchData(); // Ejecuta la función de obtención de datos
  }, []); // El array vacío asegura que esto solo se ejecute una vez al montar el componente

  return (
    <div>
      {loading && <p>Loading...</p>} {/* Opcional: Muestra un mensaje de carga mientras se realiza la solicitud */}
    </div>
  );
};

export default SendOrder;
