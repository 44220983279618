import React from 'react';
import Header from './Header';

import Footer from './Footer';
import PageTitle from './PageTitle';

function TermsAndConditions() {
  const themeStyles = {
      '--color-sell': '#F6465D',
      '--color-textSell': '#F6465D',
      '--color-sellHover': '#FF707E',
      '--color-depthSellBg': '#35141D',
      '--color-buy': '#0ECB81',
      '--color-textBuy': '#0ECB81',
      '--color-buyHover': '#32D993',
      '--color-depthBuyBg': '#102821',
      '--color-line': '#2B3139',
      '--color-outlineHover': '#6A4403',
      '--color-primary': '#FCD535',
      '--color-primaryHover': '#F0B90B',
      '--color-error': '#F6465D',
      '--color-errorBg': '#35141D',
      '--color-success': '#0ECB81',
      '--color-successBg': '#102821',
      '--color-disable': '#474D57',
      '--color-iconNormal': '#848E9C',
      '--color-textWhite': '#FFFFFF',
      '--color-textGray': '#EAECEF',
      '--color-textBlack': '#0B0E11',
      '--color-textPrimary': '#EAECEF',
      '--color-textSecondary': '#B7BDC6',
      '--color-textThird': '#848E9C',
      '--color-textDisabled': '#5E6673',
      '--color-textBrand': '#F0B90B',
      '--color-textToast': '#A37200',
      '--color-bg': '#181A20',
      '--color-bg1': '#181A20',
      '--color-bg2': '#0B0E11',
      '--color-bg3': '#2B3139',
      '--color-bg4': '#5E6673',
      '--color-bg6': '#202630',
      '--color-bg7': '#191A1F',
      '--color-popupBg': '#1E2329',
      '--color-badgeBg': '#2D2A20',
      '--color-selectedBg': '#1E2026',
      '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
      '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
      '--color-BasicBg': '#181A20',
      '--color-SecondaryBg': '#0B0E11',
      '--color-Input': '#2B3139',
      '--color-InputLine': '#474D57',
      '--color-CardBg': '#1E2329',
      '--color-Vessel': '#1E2329',
      '--color-Line': '#2B3139',
      '--color-DisableBtn': '#2B3139',
      '--color-DisabledText': '#5E6673',
      '--color-DisableText': '#5E6673',
      '--color-TertiaryText': '#848E9C',
      '--color-SecondaryText': '#B7BDC6',
      '--color-PrimaryText': '#EAECEF',
      '--color-RedGreenBgText': '#FFFFFF',
      '--color-EmphasizeText': '#FF693D',
      '--color-TextOnGray': '#EAECEF',
      '--color-TextOnYellow': '#202630',
      '--color-IconNormal': '#848E9C',
      '--color-LiteBg1': '#202630',
      '--color-LiteBg2': '#191A1F',
      '--color-BtnBg': '#FCD535',
      '--color-PrimaryYellow': '#F0B90B',
      '--color-TextLink': '#F0B90B',
      '--color-TextToast': '#A37200',
      '--color-DepthSellBg': '#35141D',
      '--color-SellHover': '#FF707E',
      '--color-Sell': '#F6465D',
      '--color-TextSell': '#F6465D',
      '--color-DepthBuyBg': '#102821',
      '--color-BuyHover': '#32D993',
      '--color-Buy': '#2EBD85',
      '--color-TextBuy': '#2EBD85',
      '--color-Error': '#F6465D',
      '--color-SuccessBg': '#102821',
      '--color-Success': '#2EBD85',
      '--color-TagBg': '#474D57',
      '--color-Grid': '#2B3139',
      '--color-Placeholder': '#474D57',
      '--color-ToastBg': '#707A8A',
      '--color-TwoColorIcon': '#CACED3',
      '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
      '--color-BadgeBg': 'rgba(240,185,11,0.1)',
    };
  return (
    <>
      <PageTitle title="Terminos & Condiciones | SuiteiOS"/>
    <Header/>
    <div style={{themeStyles}} className='theme-root dark _CWC'> 
    <div id="__APP">
  <div className="css-o0ej6l">
    <style dangerouslySetInnerHTML={{ __html: "" }} />
    <div id="iris-render">
      <div
        id="iris-root"
        iris-file-id="clqag1ta0h5hqgp938tg"
        iris-file-version={2}
        iris-req-id="78a276fd-6b9b-4f93-b2e9-5cf419087c1a"
        iris-project-name="muses-ui"
      >
        <div>
          <div className="iris-o3g9qf">
            <div className="iris-xuqs4t">
              <div
                className="richtext-container iris-fbxu07"
                style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
              >
                <h1
                  data-bn-type="text"
                  className="css-kvhcte iris-kvhcte"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>Condiciones de Uso de SuiteiOS</strong>
                  </span>
                </h1>
                <p className=" richtext-paragraph  css-dv08cu richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Última actualización: 28 de agosto de 2024.
                  </span>
                </p>
                <p className="iris-kvhcte richtext-paragraph  iris-zwb0rk" />
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Estas Condiciones de Uso de SuiteiOS regulan el uso de SuiteiOS y su sitio web. Al acceder a SuiteiOS.com, aceptas todos los términos y condiciones en su totalidad. Si no aceptas estos términos, no utilices SuiteiOS.com. Cliente, Tú y Tu se refieren a ti, la persona que accede a este sitio web.
Compañía, Nosotros y Nuestro se refieren a SuiteiOS.
Parte y Partes se refieren tanto al Cliente como a SuiteiOS.
                  </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>1. Requisitos</strong>
                  </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                   SuiteiOS no es responsable de información incorrecta proporcionada por ti. Verificar la validez de los datos es tu responsabilidad.
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  > <br />
                    Al utilizar nuestros servicios, aceptas recibir correos electrónicos de SuiteiOS. No somos responsables si no recibes estos correos debido a filtros de spam.
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  ><br />
                    No asumimos responsabilidad por pagos incorrectos enviados a una ubicación errónea por información incorrecta proporcionada por ti.
                  </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>2. Aceptación de Condiciones y Responsabilidades</strong>
                    <br />
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <br />
                    SuiteiOS ofrece un bypass de la pantalla de bloqueo de activación de iCloud. Esto no es un desbloqueo permanente. Si restauras o borras el dispositivo, el bloqueo volverá a aparecer.
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <br />
                    No garantizamos que SuiteiOS funcione en futuras actualizaciones de iOS, iPadOS o macOS. SuiteiOS no realiza cambios permanentes en el sistema operativo del dispositivo.
                    </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <br />
                    Utilizas SuiteiOS bajo tu propio riesgo. No asumimos responsabilidad por el funcionamiento incorrecto del dispositivo Apple o Mac al utilizar SuiteiOS. Se requiere una conexión a internet.
                    </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>3. Limitación de Responsabilidad</strong>
                    <br />
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <br />
                    * Daños físicos al dispositivo, como pantallas rotas o botones defectuosos.
                    </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <br />
                    * Problemas de hardware en dispositivos bloqueados con iCloud.
                    </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <br />
                    * Pérdida de datos al usar SuiteiOS.
                    </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <br />
                    * Uso de SuiteiOS en dispositivos robados o para fines comerciales.
                    </span>
                </p>
                <p className='richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6'>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    SuiteiOS fue desarrollada con fines educativos y para uso personal, no comercial ni lucrativo. No asumimos ninguna responsabilidad por cualquier daño o uso incorrecto del servicio, ni por la manera en que decidas usar tu dispositivo (ya sea para uso personal o para la venta). SuiteiOS no está destinado a la venta o distribución.
                  </span> <br /><br />
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
Nos reservamos el derecho de terminar o suspender tu acceso a SuiteiOS en caso de incumplimiento de estos Términos, cualquier otra política o procedimiento establecido por SuiteiOS. SuiteiOS puede terminar este acuerdo a su entera discreción.

</span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>4. Uso del servicio</strong>
                  </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                   SuiteiOS proporciona una licencia para el uso del servicio con el propósito de realizar un bypass de la pantalla de bloqueo de activación de iCloud para un solo dispositivo. Al completar la compra en SuiteiOS.com, aceptas adherirte a los siguientes términos en cuanto al uso de SuiteiOS:
                  </span> <br />
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  > <br />
* No puedes vender, alquilar, prestar o transferir este servicio a otros bajo ningún medio o forma.
</span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  ><br />
* El servicio se proporciona exclusivamente con fines educativos y para uso personal. No somos responsables por el uso del servicio para cualquier otro propósito o por cualquier daño que pueda ocurrir al usar el servicio.

                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  ><br />
* El uso de esta plataforma en dispositivos robados está estrictamente prohibido.
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  ><br />
* SuiteiOS no se hace responsable de la pérdida de datos.
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  ><br />
* Debes leer y aceptar todos los términos y condiciones antes de utilizar SuiteiOS.
                  </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>5. Política de Reembolso</strong>
                  </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  > 
Si experimentas un problema con el servicio y el soporte no puede resolverlo, puedes solicitar un reembolso dentro de los primeros 7 días. Nos reservamos el derecho de evaluar la solicitud de reembolso.
</span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>6. Soporte Técnico</strong>
                  </span>
                </p>
                <p className=" richtext-paragraph  css-ayuuf6 richtext-paragraph  iris-ayuuf6">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  > 
Brindamos soporte técnico conforme a estos Términos. Puedes enviar solicitudes por correo electrónico o a través de nuestro sistema de tickets. Nos esforzamos por responder dentro de las 24 horas.
</span>
                </p>
                <p className=" richtext-paragraph  iris-zwb0rk" />
                <p className=" richtext-paragraph  iris-zwb0rk" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    <Footer/>
    </>
  );
}

export default TermsAndConditions;