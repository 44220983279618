import React from 'react';
import Header from './Header';
import Content from './Content';
import Nav from './Nav';
import Footer from './Footer';
import PageTitle from './PageTitle';

function Index(props) {
  return (
    <>
    <PageTitle title="iCloud Lock Bypass - iPhone, iPad, Mac | SuiteiOS"/>
    <Header/>
    <Content/>
    <Footer/>
    </>
  );
}

export default Index;