import React, { useEffect, useState } from 'react';
import Header from '../Home/Header';

import Footer from '../Home/Footer';
import ChooseDevice from './ChooseDevice';

import icon1 from "../PlaceOrder/assets/img/card-icon.png";
import icon2 from "../PlaceOrder/assets/img/crypto-suiteios-icon.png";
import icon3 from "../PlaceOrder/assets/img/efective_icon.png";
import icon4 from "../PlaceOrder/assets/img/eur_both_new.svg";
import icon5 from "../PlaceOrder/assets/img/eur_SuiteiOS_offer.svg";
import icon6 from "../PlaceOrder/assets/img/eur_SuiteiOS_support.svg";

import xv_icon from "../PlaceOrder/assets/img/xv_icon.png"
import PageTitle from '../Home/PageTitle';

function CheckDetails() {
  const [selectedMethod, setSelectedMethod] = useState(''); // Almacena el método de pago seleccionado
  const [deviceChoosed, setDeviceChoosed] = useState(''); // Almacena el método de pago seleccionado
  const [bypassPrice, setBypassPrice] = useState(''); // Almacena el método de pago seleccionado

  const [value, setValue] = useState('');
  // Maneja el click en una opción de pago
  useEffect(() => {
    const savedMethod = localStorage.getItem('PaymentMethod');
    const deviceChoosed = localStorage.getItem('deviceChoosed');
    const bypassPrice = localStorage.getItem('bypassPrice');
    if (savedMethod) {
      setSelectedMethod(savedMethod);
      setDeviceChoosed(deviceChoosed);
      setBypassPrice(bypassPrice);
    }
  }, []);


  useEffect(() => {
    // Recuperar el valor almacenado en localStorage cuando el componente se monta
    const storedValue = localStorage.getItem('imei');
    if (storedValue) {
      setValue(storedValue);
    }
  }, []);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    // Almacenar el valor en localStorage
    localStorage.setItem('imei', newValue);
  };
  const themeStyles = {
      '--color-sell': '#F6465D',
      '--color-textSell': '#F6465D',
      '--color-sellHover': '#FF707E',
      '--color-depthSellBg': '#35141D',
      '--color-buy': '#0ECB81',
      '--color-textBuy': '#0ECB81',
      '--color-buyHover': '#32D993',
      '--color-depthBuyBg': '#102821',
      '--color-line': '#2B3139',
      '--color-outlineHover': '#6A4403',
      '--color-primary': '#FCD535',
      '--color-primaryHover': '#F0B90B',
      '--color-error': '#F6465D',
      '--color-errorBg': '#35141D',
      '--color-success': '#0ECB81',
      '--color-successBg': '#102821',
      '--color-disable': '#474D57',
      '--color-iconNormal': '#848E9C',
      '--color-textWhite': '#FFFFFF',
      '--color-textGray': '#EAECEF',
      '--color-textBlack': '#0B0E11',
      '--color-textPrimary': '#EAECEF',
      '--color-textSecondary': '#B7BDC6',
      '--color-textThird': '#848E9C',
      '--color-textDisabled': '#5E6673',
      '--color-textBrand': '#F0B90B',
      '--color-textToast': '#A37200',
      '--color-bg': '#181A20',
      '--color-bg1': '#181A20',
      '--color-bg2': '#0B0E11',
      '--color-bg3': '#2B3139',
      '--color-bg4': '#5E6673',
      '--color-bg6': '#202630',
      '--color-bg7': '#191A1F',
      '--color-popupBg': '#1E2329',
      '--color-badgeBg': '#2D2A20',
      '--color-selectedBg': '#1E2026',
      '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
      '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
      '--color-BasicBg': '#181A20',
      '--color-SecondaryBg': '#0B0E11',
      '--color-Input': '#2B3139',
      '--color-InputLine': '#474D57',
      '--color-CardBg': '#1E2329',
      '--color-Vessel': '#1E2329',
      '--color-Line': '#2B3139',
      '--color-DisableBtn': '#2B3139',
      '--color-DisabledText': '#5E6673',
      '--color-DisableText': '#5E6673',
      '--color-TertiaryText': '#848E9C',
      '--color-SecondaryText': '#B7BDC6',
      '--color-PrimaryText': '#EAECEF',
      '--color-RedGreenBgText': '#FFFFFF',
      '--color-EmphasizeText': '#FF693D',
      '--color-TextOnGray': '#EAECEF',
      '--color-TextOnYellow': '#202630',
      '--color-IconNormal': '#848E9C',
      '--color-LiteBg1': '#202630',
      '--color-LiteBg2': '#191A1F',
      '--color-BtnBg': '#FCD535',
      '--color-PrimaryYellow': '#F0B90B',
      '--color-TextLink': '#F0B90B',
      '--color-TextToast': '#A37200',
      '--color-DepthSellBg': '#35141D',
      '--color-SellHover': '#FF707E',
      '--color-Sell': '#F6465D',
      '--color-TextSell': '#F6465D',
      '--color-DepthBuyBg': '#102821',
      '--color-BuyHover': '#32D993',
      '--color-Buy': '#2EBD85',
      '--color-TextBuy': '#2EBD85',
      '--color-Error': '#F6465D',
      '--color-SuccessBg': '#102821',
      '--color-Success': '#2EBD85',
      '--color-TagBg': '#474D57',
      '--color-Grid': '#2B3139',
      '--color-Placeholder': '#474D57',
      '--color-ToastBg': '#707A8A',
      '--color-TwoColorIcon': '#CACED3',
      '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
      '--color-BadgeBg': 'rgba(240,185,11,0.1)',
    };


  // Estado para manejar la visibilidad del componente ChooseDevice
  const [ChooseDeviceisVisible, setChooseDeviceIsVisible] = useState(false);

  // Función para alternar la visibilidad
  const toggleVisibilityChooseDevice = () => {
    setChooseDeviceIsVisible(!ChooseDeviceisVisible);
  };
  const handleClose = () => {
    setChooseDeviceIsVisible(false);
  };
  return (
    <>
    <PageTitle title="Revisar Orden | SuiteiOS"/>
    <Header/>
    <div style={{themeStyles}} className='theme-root dark _CWC'> 
    <div id="__APP" style={{fontFamily:'Plex'}}>
  <div id="fiat-app" className="bn-flex flex-col min-h-fit md:min-h-screen">

    <div className="bn-flex flex-col items-center bg-nc-BasicBg">
      <div className="w-full max-w-[1200px] px-[15px] md:px-[24px] min1200:px-0">
        <div
          className="text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] text-nc-PrimaryText font-[600] py-[16px] md:py-[24px] "
          slotname="left-bar"
        >
          Seleccionar Dispositivo & Metodo de Pago
        </div>
        <div className="content-max-width pt-[16px] md:pt-[24px] mb-[0px] md:mb-[80px]">
          <div className="relative">
            <div className="css-1r0rsfx">
            <div className="css-10b9w81" bis_skin_checked={1}>
  <div className="css-1l36wyk" bis_skin_checked={1}>
    <div
      className="bn-flex justify-between mb-[4px] items-center"
      bis_skin_checked={1}
    >
      <div
        id="tooltip_amount_input_label"
        className="t-subtitle2 text-nc-SecondaryText md:text-nc-PrimaryText"
        bis_skin_checked={1}
      >
        IMEI/SN
      </div>
    </div>
    <div className="bn-tooltips-wrap w-full" bis_skin_checked={1}>
      <div className="bn-tooltips-ele" bis_skin_checked={1}>
        <div
          className="bn-textField bn-textField__default data-success data-size-huge data-gap-size-normal w-full"
          bis_skin_checked={1}
        >
          <input
            inputMode="decimal"
            placeholder="Límite 15-36"
            type="decimal"
            className="bn-textField-input"
            infotext=""
            availableamount={720}
            spellCheck="false"
            autoComplete=""
            defaultValue=""
            suggestedlimit="[object Object]"
      value={value}
      onChange={handleChange}
          />
          <div className="bn-textField-suffix" bis_skin_checked={1}>
            <div
              className="bn-flex items-center gap-[10px]"
              bis_skin_checked={1}
            >
              <div
                className="t-subtitle1 text-nc-PrimaryText"
                bis_skin_checked={1}
              >
                IMEI
              </div>
            </div>
          </div>
        </div>
        <div
          id="amount_input_hidden"
          className="max-w-[100%] invisible absolute"
          bis_skin_checked={1}
        />
      </div>
      <div
        className="bn-bubble bn-bubble__gray data-font-14 bn-tooltips fiat_custom_tooltip_input"
        style={{ transform: "translateX(63px)", bottom: "100%", left: 0 }}
        bis_skin_checked={1}
      >
        <div
          className="bn-bubble-arrow"
          bis_skin_checked={1}
          style={{
            transform: "translate(0px, 0px) rotate(-135deg)",
            bottom: 9,
            left: 9
          }}
        />
        <div
          className="bn-bubble-content"
          bis_skin_checked={1}
          style={{ marginBottom: 12 }}
        >
          =0
        </div>
      </div>
    </div>
    <div className="mt-[4px] t-caption1 text-nc-Error" bis_skin_checked={1}>
      <div className="css-1py8xe8" bis_skin_checked={1} />
    </div>
    <div className="py-[8px] mt-6 md:py-0 mb-0 md:mb-[16px]" bis_skin_checked={1}>
      <div className="bn-flex justify-between gap-[16px] " bis_skin_checked={1}>
        <div
        style={{fontFamily:'PlexRegular'}}
          className="text-nc-SecondaryText md:text-nc-TertiaryText t-body2"
          bis_skin_checked={1}
        >
          Método de pago
        </div>
        <div
          className="text-nc-PrimaryText text-right break-all flex-1 t-subtitle1"
          bis_skin_checked={1}
        >
          <div className="bn-flex justify-end" bis_skin_checked={1}>
            <img
            src={icon1}
              className="w-[24px] h-[24px] css-ip7hva"
            />
            {selectedMethod}
          </div>
        </div>
      </div>
    </div>
    <div className="py-[8px] md:py-0 mb-0 md:mb-[16px]" bis_skin_checked={1}>
      <div className="bn-flex justify-between gap-[16px] " bis_skin_checked={1}>
        <div
          className="text-nc-SecondaryText md:text-nc-TertiaryText t-body2"
          bis_skin_checked={1}
        >
          <div className="bn-flex items-center gap-[4px]" bis_skin_checked={1}>
            <div 
        style={{fontFamily:'PlexRegular'}} bis_skin_checked={1}>Precio a pagar</div>
          </div>
        </div>
        <div
          className="text-nc-PrimaryText text-right break-all flex-1 t-subtitle1"
          bis_skin_checked={1}
        >
          <div className="bn-flex justify-end" bis_skin_checked={1}>
            <div
              className="bn-flex items-end md:items-center flex-col-reverse md:flex-row"
              bis_skin_checked={1}
            >
              <div bis_skin_checked={1}>{bypassPrice} UYU</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="py-[8px] md:py-0 mb-0 md:mb-[16px]" bis_skin_checked={1}>
      <div className="bn-flex justify-between gap-[16px] " bis_skin_checked={1}>
        <div
        style={{fontFamily:'PlexRegular'}}
          className="text-nc-SecondaryText md:text-nc-TertiaryText t-body2"
          bis_skin_checked={1}
        >
          Recibes
        </div>
        <div
          className="text-nc-PrimaryText text-right whitespace-nowrap overflow-hidden text-ellipsis t-subtitle4"
          bis_skin_checked={1}
        >
          <div
            className="bn-flex items-end md:items-center flex-col-reverse md:flex-row"
            bis_skin_checked={1}
          >
            <div bis_skin_checked={1}>Desbloqueo {deviceChoosed}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="css-1tas10q" bis_skin_checked={1}>
    <div className="css-uliqdc" bis_skin_checked={1}>
      <div className="css-1kh6z8b" bis_skin_checked={1}>
      </div>
    </div>
  </div>
  <div className="css-vurnku" bis_skin_checked={1} />
                
                <div className="sticky w-full bottom-0 left-0 py-[24px] bg-nc-BasicBg">
                  <a href="./placeorder" className='css-1x13kif2 css-1x13kif'>
                    <span className="css-vurnku">
                       Anterior
                    </span>
                    </a>
                </div>
                <div className="sticky w-full bottom-0 left-0 py-[24px] bg-nc-BasicBg">
                  <a href="./sendordertodb" className='css-1x13kif css-1x13kif'>
                    <span className="css-vurnku">
                       Continuar
                    </span>
                  </a>
                </div>
              </div>
              <div className="w-[240px] lg:w-[383px] faqwidth">
                <div className="flex-1">
                  <div id='faq' className="mb-[0px] md:mb-[32px] w-full">
                    <div className="bn-flex justify-between flex-wrap relative overflow-hidden items-center mb-[0px] md:mb-[8px] py-[16px] md:py-[0px]">
                      <span className="t-subtitle4">Preguntas frecuentes</span>
                      <a
                        target="_blank"
                        href="/support/faq"
                        className="t-subtitle3 text-nc-PrimaryText"
                      >
                        <div className="bn-flex items-center">
                          <div style={{fontFamily:'Plex'}}>Ver más</div>
                          <svg
                            className="bn-svg w-[16px] h-[16px]"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                    <div>
                      <div className="p-[12px] mx-[-12px] rounded-[8px] ">
                        <div className="bn-flex t-subtitle2 text-nc-PrimaryText cursor-pointer justify-between md:hover:text-nc-TextLink">
                          <div className="bn-flex mr-[12px] flex-1 gap-[12px] flex-row">
                            <div className="bn-flex rounded-[8px] border border-solid border-nc-Line w-[24px] h-[24px] justify-center items-center flex-none">
                              1
                            </div>
                            <div style={{fontFamily:'Plex'}}>
                            ¿Cómo puedo realizar un depósito para desbloquear mi dispositivo iCloud?
                            </div>
                          </div>
                          <svg
                            className="bn-svg w-[16px] h-[16px] mt-[3px]"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.379 8.5l-1.94 1.94a6.45 6.45 0 109.122 9.12l1.939-1.939-2.121-2.121-1.94 1.94a3.45 3.45 0 01-4.878-4.88L8.5 10.622 6.379 8.5zM12.56 6.56a3.45 3.45 0 014.88 4.88l-1.94 1.939 2.121 2.121 1.94-1.94a6.45 6.45 0 10-9.122-9.12L8.5 6.378 10.621 8.5l1.94-1.94z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.81 16.31l-2.12-2.12 6.5-6.5 2.12 2.12-6.5 6.5z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="p-[12px] mx-[-12px] rounded-[8px] ">
                        <div className="bn-flex t-subtitle2 text-nc-PrimaryText cursor-pointer justify-between md:hover:text-nc-TextLink">
                          <div className="bn-flex mr-[12px] flex-1 gap-[12px] flex-row">
                            <div className="bn-flex rounded-[8px] border border-solid border-nc-Line w-[24px] h-[24px] justify-center items-center flex-none">
                              2
                            </div>
                            <div style={{fontFamily:'Plex'}}>¿Cuánto tiempo tardará en desbloquearse mi dispositivo después de realizar el depósito?
                            </div>
                          </div>
                          <svg
                            className="bn-svg w-[16px] h-[16px] mt-[3px]"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div style={{fontFamily:'Plex'}} className="pt-[8px] t-caption1 text-nc-SecondaryText hidden">
                          Normalmente, en 5&nbsp;minutos. En algunas ocasiones,
                          es posible que tarde hasta 24&nbsp;horas.
                        </div>
                      </div>
                      <div className="p-[12px] mx-[-12px] rounded-[8px] ">
                        <div className="bn-flex t-subtitle2 text-nc-PrimaryText cursor-pointer justify-between md:hover:text-nc-TextLink">
                          <div className="bn-flex mr-[12px] flex-1 gap-[12px] flex-row">
                            <div className="bn-flex rounded-[8px] border border-solid border-nc-Line w-[24px] h-[24px] justify-center items-center flex-none">
                              3
                            </div>
                            <div>
                            ¿Qué debo hacer si tengo problemas con el depósito o no veo el cargo en mi cuenta?
                            </div>
                          </div>
                          <svg
                            className="bn-svg w-[16px] h-[16px] mt-[3px]"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.379 8.5l-1.94 1.94a6.45 6.45 0 109.122 9.12l1.939-1.939-2.121-2.121-1.94 1.94a3.45 3.45 0 01-4.878-4.88L8.5 10.622 6.379 8.5zM12.56 6.56a3.45 3.45 0 014.88 4.88l-1.94 1.939 2.121 2.121 1.94-1.94a6.45 6.45 0 10-9.122-9.12L8.5 6.378 10.621 8.5l1.94-1.94z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.81 16.31l-2.12-2.12 6.5-6.5 2.12 2.12-6.5 6.5z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ animation: "fadein-delay 1.5s linear" }}>
              <div className="bn-flex bg-nc-BasicBg justify-center flex-wrap">
                <div className="css-1vr73xp">
                  <div className="css-zljdsa">
                    <div className="css-1bglx66" style={{fontFamily:'Plex'}}>
                      Financia tu desbloqueo con divisas fiat
                    </div>
                    <div className="css-vcjzue">
                      <div className="css-sknawq">
                        <img
                          src={icon4}
                          className="css-i7xqly"
                        />
                        <div data-bn-type="text" className="css-1ooevko">
                          Fácil tanto para usuarios nuevos como experimentados.
                        </div>
                        <div data-bn-type="text" className="css-14w8ddf">
                          Verás que, gracias a nuestras opciones de desbloqueo
                          con criptomonedas y divisas fiat, disfrutarás de una experiencia
                          fluida y podrás empezar a utilizar tu dispositivo tan solo 24 horas
                          después de realizar tu depósito.
                        </div>
                      </div>
                      <div className="css-sknawq">
                        <img
                          src={icon5}
                          className="css-i7xqly"
                        />
                        <div data-bn-type="text" className="css-1ooevko">
                          SuiteiOS ofrece la posibilidad de realizar depósitos
                          con criptomonedas
                        </div>
                        <div data-bn-type="text" className="css-14w8ddf">
                        Depósitos con criptomonedas y 
                        financiamiento en euros (EUR), dólares estadounidenses (USD) y otras divisas fiat, 
                        todo con comisiones mínimas.
                        </div>
                      </div>
                      <div className="css-sknawq">
                        <img
                          src={icon6}
                          className="css-i7xqly"
                        />
                        <div data-bn-type="text" className="css-1ooevko">
                          SuiteiOS admite las siguientes divisas
                        </div>
                        <div data-bn-type="text" className="css-14w8ddf">
                          USD, EUR, UYU, ARS, $USDT, $USDC
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            </div>
            <div />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    <Footer/>
    {ChooseDeviceisVisible && <ChooseDevice onClose={handleClose} />}
    </>
  );
}

export default CheckDetails;