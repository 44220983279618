import {React, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Index from './es/Home/Index';
import Login from './es/Auth/Login';
import Register from './es/Auth/Register';
import About from './es/Home/About';
import Careers from './es/Home/Careers';
import Newsroom from './es/Home/Newsroom';
import PrivacyPolicy from './es/Home/PrivacyPolicy';
import TermsAndConditions from './es/Home/TermsAndConditions';
import PlaceOrder from './es/Home/PlaceOrder';
import StaySigned from './es/Auth/StaySigned';
import Dashboard from './es/Panel/Dashboard';
import CheckDetails from './es/PlaceOrder/CheckDetails';
import SuccessPayment from './es/PlaceOrder/SuccessPayment';
import SendOrder from './es/Backend/SendOrder';

function App() {

  useEffect(() => {
    const cargarEstilos = async () => {
      if (window.location.pathname === '/dashboard') {
        await import("./es/Panel/assets/css/main.css");
        await import("./es/Panel/assets/css/sidebar.css");
      }
      if (window.location.pathname === '/stay-signed') {
        await import("./es/Auth/assets/css/main.css");
        await import("./es/Auth/assets/css/staysigned.css");
      }
      if (window.location.pathname === '/login' || window.location.pathname === '/register'|| window.location.pathname === '/footer') {
        await import("./es/Auth/assets/css/main.css");
      } 
      if (window.location.pathname === "/about"){
        await import ("./es/Home/assets/css/main.css")
        await import ("./es/About/assets/css/about.css")
      }
      if (window.location.pathname === "/careers"){
        await import ("./es/Home/assets/css/main.css")
        await import ("./es/Careers/assets/css/careers.css")
      }
      if (window.location.pathname === "/newsroom"){
        await import ("./es/Home/assets/css/main.css")
        await import ("./es/Newsroom/assets/css/newsroom.css")
      }
      if (window.location.pathname === "/privacy"){
        await import ("./es/Home/assets/css/main.css")
        await import ("./es/PrivacyPolicy/assets/css/privacypolicy.css")
      }
      if (window.location.pathname === "/terms"){
        await import ("./es/Home/assets/css/main.css")
        await import ("./es/TermsAndConditions/assets/css/termsandconditions.css")
      }
      if (window.location.pathname === "/placeorder" || window.location.pathname === "/checkdetails"){
        await import ("./es/Home/assets/css/main.css")
        await import ("./es/PlaceOrder/assets/css/placeorder.css")
        await import ("./es/PlaceOrder/assets/css/choosedevice.css")
      }
      if (window.location.pathname === "/successpayment"){
        await import ("./es/Home/assets/css/main.css")
        await import ("./es/PlaceOrder/assets/css/successpayment.css")
      }
      else {
        await import("./es/Home/assets/css/main.css");
      }
    };

    cargarEstilos();
  }, []);

  return (
    <Router>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/stay-signed" element={<StaySigned />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/about" element={<About />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/newsroom" element={<Newsroom />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/placeorder" element={<PlaceOrder />} />
      <Route path="/checkdetails" element={<CheckDetails />} />
      <Route path="/successpayment" element={<SuccessPayment />} />
      <Route path="/sendordertodb" element={<SendOrder />} />
    </Routes>
  </Router>
  );
}

export default App;
