import React, { useEffect, useState } from 'react';

import xv_icon from "./assets/img/xv_icon.png";
import xiv_icon from "./assets/img/xiv_icon.png";
import xiii_icon from "./assets/img/xiii_icon.png";
import xii_icon from "./assets/img/xii_icon.png";
import xi_icon from "./assets/img/xi_icon.png";
import x_icon from "./assets/img/x_icon.png";

const ChooseDevice = ({ onClose }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const element = document.getElementById('15ojvzg');
    if (element) {
      if (isMobile) {
        element.classList.add('css-15o3vzg'); // Añadir clase CSS específica para móviles
        element.classList.remove('css-15ojvzg'); // Eliminar clase CSS específica
      } else {
        element.classList.add('css-15ojvzg'); // Añadir clase CSS específica para escritorio
        element.classList.remove('css-15o3vzg'); // Eliminar clase CSS específica
      }
    }
  }, [isMobile]);
  const themeStyles = {
      '--color-sell': '#F6465D',
      '--color-textSell': '#F6465D',
      '--color-sellHover': '#FF707E',
      '--color-depthSellBg': '#35141D',
      '--color-buy': '#0ECB81',
      '--color-textBuy': '#0ECB81',
      '--color-buyHover': '#32D993',
      '--color-depthBuyBg': '#102821',
      '--color-line': '#2B3139',
      '--color-outlineHover': '#6A4403',
      '--color-primary': '#FCD535',
      '--color-primaryHover': '#F0B90B',
      '--color-error': '#F6465D',
      '--color-errorBg': '#35141D',
      '--color-success': '#0ECB81',
      '--color-successBg': '#102821',
      '--color-disable': '#474D57',
      '--color-iconNormal': '#848E9C',
      '--color-textWhite': '#FFFFFF',
      '--color-textGray': '#EAECEF',
      '--color-textBlack': '#0B0E11',
      '--color-textPrimary': '#EAECEF',
      '--color-textSecondary': '#B7BDC6',
      '--color-textThird': '#848E9C',
      '--color-textDisabled': '#5E6673',
      '--color-textBrand': '#F0B90B',
      '--color-textToast': '#A37200',
      '--color-bg': '#181A20',
      '--color-bg1': '#181A20',
      '--color-bg2': '#0B0E11',
      '--color-bg3': '#2B3139',
      '--color-bg4': '#5E6673',
      '--color-bg6': '#202630',
      '--color-bg7': '#191A1F',
      '--color-popupBg': '#1E2329',
      '--color-badgeBg': '#2D2A20',
      '--color-selectedBg': '#1E2026',
      '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
      '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
      '--color-BasicBg': '#181A20',
      '--color-SecondaryBg': '#0B0E11',
      '--color-Input': '#2B3139',
      '--color-InputLine': '#474D57',
      '--color-CardBg': '#1E2329',
      '--color-Vessel': '#1E2329',
      '--color-Line': '#2B3139',
      '--color-DisableBtn': '#2B3139',
      '--color-DisabledText': '#5E6673',
      '--color-DisableText': '#5E6673',
      '--color-TertiaryText': '#848E9C',
      '--color-SecondaryText': '#B7BDC6',
      '--color-PrimaryText': '#EAECEF',
      '--color-RedGreenBgText': '#FFFFFF',
      '--color-EmphasizeText': '#FF693D',
      '--color-TextOnGray': '#EAECEF',
      '--color-TextOnYellow': '#202630',
      '--color-IconNormal': '#848E9C',
      '--color-LiteBg1': '#202630',
      '--color-LiteBg2': '#191A1F',
      '--color-BtnBg': '#FCD535',
      '--color-PrimaryYellow': '#F0B90B',
      '--color-TextLink': '#F0B90B',
      '--color-TextToast': '#A37200',
      '--color-DepthSellBg': '#35141D',
      '--color-SellHover': '#FF707E',
      '--color-Sell': '#F6465D',
      '--color-TextSell': '#F6465D',
      '--color-DepthBuyBg': '#102821',
      '--color-BuyHover': '#32D993',
      '--color-Buy': '#2EBD85',
      '--color-TextBuy': '#2EBD85',
      '--color-Error': '#F6465D',
      '--color-SuccessBg': '#102821',
      '--color-Success': '#2EBD85',
      '--color-TagBg': '#474D57',
      '--color-Grid': '#2B3139',
      '--color-Placeholder': '#474D57',
      '--color-ToastBg': '#707A8A',
      '--color-TwoColorIcon': '#CACED3',
      '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
      '--color-BadgeBg': 'rgba(240,185,11,0.1)',
    };

    const devices = [
      { name: "iPhone 15", price: "13.000", icon: xv_icon },
      { name: "iPhone 15 Pro", price: "15.500", icon: xv_icon },
      { name: "iPhone 15 Pro Max", price: "16.500", icon: xv_icon },
      { name: "iPhone 15 Plus", price: "14.500", icon: xv_icon },
      { name: "iPhone 14", price: "11.800", icon: xiv_icon },
      { name: "iPhone 14 Pro", price: "12.500", icon: xiv_icon },
      { name: "iPhone 14 Pro Max", price: "13.500", icon: xiv_icon },
      { name: "iPhone 14 Plus", price: "12.500", icon: xiv_icon  },
      { name: "iPhone 13", price: "9.500", icon: xiii_icon  },
      { name: "iPhone 13 Pro", price: "10.500" , icon: xiii_icon },
      { name: "iPhone 13 Pro Max", price: "12.000", icon: xiii_icon  },
      { name: "iPhone 13 Mini", price: "8.500", icon: xiii_icon },
      { name: "iPhone 12", price: "8.000", icon: xii_icon },
      { name: "iPhone 12 Pro", price: "8.500", icon: xii_icon },
      { name: "iPhone 12 Pro Max", price: "8.900", icon: xii_icon },
      { name: "iPhone 12 Mini", price: "7.000", icon: xii_icon },
      { name: "iPhone 11", price: "6.500", icon: xi_icon },
      { name: "iPhone 11 Pro", price: "7.000", icon: xi_icon },
      { name: "iPhone 11 Pro Max", price: "7.500", icon: xi_icon },
    ];
    
    function handleDeviceClick(device) {
      // Guardar en localStorage
      localStorage.setItem('deviceChoosed', device.name);
      localStorage.setItem('bypassPrice', device.price);
      alert(`Dispositivo seleccionado: ${device.name}, Precio: ${device.price}`);
    }
  return (
    <>
    <div className="css-1u2pn8e">
  <div id='15ojvzg' className="css-15ojvzg">
    <div className="css-zn6id3" 
        onClick={onClose}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        className="with-pinter css-c3912b"
      >
        <path
          d="M6.697 4.575L4.575 6.697 9.88 12l-5.304 5.303 2.122 2.122L12 14.12l5.303 5.304 2.122-2.122L14.12 12l5.304-5.303-2.122-2.122L12 9.88 6.697 4.575z"
          fill="currentColor"
        />
      </svg>
      <div data-bn-type="text" className="css-ebmvyo">
        Seleccionar dispositivo
      </div>
      <div className=" css-1sk5iok">
        <div className="bn-input-prefix css-vurnku">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            className="css-jh5qln"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 6a5 5 0 110 10 5 5 0 010-10zm0-3a8 8 0 017.021 11.838l3.07 3.07-1.59 1.591-1.591 1.591-3.07-3.07A8 8 0 1111 3z"
              fill="currentColor"
            />
          </svg>
        </div>
        <input
          data-bn-type="input"
          placeholder="Buscar"
          className="coinSearch css-1mizem0"
          defaultValue=""
        />
        <div className="bn-input-suffix css-vurnku">
          <div className="clearBtn css-ajzf21">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="css-1i2l2t0"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5.326 13.909l-1.429 1.417L12 13.429l-3.897 3.897-1.429-1.417 3.909-3.898-3.909-3.908 1.429-1.417L12 10.583l3.897-3.897 1.429 1.417-3.897 3.908 3.897 3.898z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div className="scroll-view css-10avnzz">
    {devices.map((device, index) => (
      <div key={index} className="with-pinter clearfix css-jqhwq" onClick={() => handleDeviceClick(device)}>
        <img src={device.icon} className="css-oqth55" />
        <div className="css-8m5p0v" style={{fontFamily:'Plex'}}>
          <div data-bn-type="text" className="css-e17nie">
            {device.name}
          </div>
          <div data-bn-type="text" style={{fontFamily:'PlexRegular'}} className="css-mb0a1g">
            {device.price}
          </div>
        </div>
      </div>
    ))}
  </div>
  </div>
  <div className="css-1x6mg2r" />
</div>

    </>
  );
}

export default ChooseDevice;