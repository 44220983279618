import React from 'react';
import Header from './Header';

import Footer from './Footer';
import PageTitle from './PageTitle';

function PrivacyPolicy() {
  const themeStyles = {
      '--color-sell': '#F6465D',
      '--color-textSell': '#F6465D',
      '--color-sellHover': '#FF707E',
      '--color-depthSellBg': '#35141D',
      '--color-buy': '#0ECB81',
      '--color-textBuy': '#0ECB81',
      '--color-buyHover': '#32D993',
      '--color-depthBuyBg': '#102821',
      '--color-line': '#2B3139',
      '--color-outlineHover': '#6A4403',
      '--color-primary': '#FCD535',
      '--color-primaryHover': '#F0B90B',
      '--color-error': '#F6465D',
      '--color-errorBg': '#35141D',
      '--color-success': '#0ECB81',
      '--color-successBg': '#102821',
      '--color-disable': '#474D57',
      '--color-iconNormal': '#848E9C',
      '--color-textWhite': '#FFFFFF',
      '--color-textGray': '#EAECEF',
      '--color-textBlack': '#0B0E11',
      '--color-textPrimary': '#EAECEF',
      '--color-textSecondary': '#B7BDC6',
      '--color-textThird': '#848E9C',
      '--color-textDisabled': '#5E6673',
      '--color-textBrand': '#F0B90B',
      '--color-textToast': '#A37200',
      '--color-bg': '#181A20',
      '--color-bg1': '#181A20',
      '--color-bg2': '#0B0E11',
      '--color-bg3': '#2B3139',
      '--color-bg4': '#5E6673',
      '--color-bg6': '#202630',
      '--color-bg7': '#191A1F',
      '--color-popupBg': '#1E2329',
      '--color-badgeBg': '#2D2A20',
      '--color-selectedBg': '#1E2026',
      '--color-gradientBrand': 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
      '--color-gradientPrimary': 'linear-gradient(295.27deg, #15141A 0%, #474D57 84.52%)',
      '--color-BasicBg': '#181A20',
      '--color-SecondaryBg': '#0B0E11',
      '--color-Input': '#2B3139',
      '--color-InputLine': '#474D57',
      '--color-CardBg': '#1E2329',
      '--color-Vessel': '#1E2329',
      '--color-Line': '#2B3139',
      '--color-DisableBtn': '#2B3139',
      '--color-DisabledText': '#5E6673',
      '--color-DisableText': '#5E6673',
      '--color-TertiaryText': '#848E9C',
      '--color-SecondaryText': '#B7BDC6',
      '--color-PrimaryText': '#EAECEF',
      '--color-RedGreenBgText': '#FFFFFF',
      '--color-EmphasizeText': '#FF693D',
      '--color-TextOnGray': '#EAECEF',
      '--color-TextOnYellow': '#202630',
      '--color-IconNormal': '#848E9C',
      '--color-LiteBg1': '#202630',
      '--color-LiteBg2': '#191A1F',
      '--color-BtnBg': '#FCD535',
      '--color-PrimaryYellow': '#F0B90B',
      '--color-TextLink': '#F0B90B',
      '--color-TextToast': '#A37200',
      '--color-DepthSellBg': '#35141D',
      '--color-SellHover': '#FF707E',
      '--color-Sell': '#F6465D',
      '--color-TextSell': '#F6465D',
      '--color-DepthBuyBg': '#102821',
      '--color-BuyHover': '#32D993',
      '--color-Buy': '#2EBD85',
      '--color-TextBuy': '#2EBD85',
      '--color-Error': '#F6465D',
      '--color-SuccessBg': '#102821',
      '--color-Success': '#2EBD85',
      '--color-TagBg': '#474D57',
      '--color-Grid': '#2B3139',
      '--color-Placeholder': '#474D57',
      '--color-ToastBg': '#707A8A',
      '--color-TwoColorIcon': '#CACED3',
      '--color-ErrorBg': 'rgba(246, 70, 93, 0.1)',
      '--color-BadgeBg': 'rgba(240,185,11,0.1)',
    };
  return (
    <>    
    <PageTitle title="Politica de Privacidad | SuiteiOS"/>
    <Header/>
    <div style={{themeStyles}} className='theme-root dark _CWC'> 
    <div id="__APP" style={{fontFamily:'PlexBold'}}>
  <div className="css-o0ej6l">
    <style dangerouslySetInnerHTML={{ __html: "" }} />
    <div id="iris-render">
      <div
        id="iris-root"
        iris-file-id="cmb7q9q7hmn2nniisnj0"
        iris-file-version={3}
        iris-req-id="e951a4e3-c678-4908-a74a-94b63ececf0f"
        iris-project-name="muses-ui"
      >
        <div>
          <div className="iris-o3g9qf">
            <div className="iris-xuqs4t">
              <div
                className="richtext-container iris-fbxu07"
                style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
              >
                <h1
                  data-bn-type="text"
                  id="Aviso-de-privacidad-de-SuiteiOS"
                  className="iris-kvhcte"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Aviso de privacidad de SuiteiOS
                  </span>
                </h1>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Última actualización: 28 de agosto de 2024.
                  </span>
                </p>
                <p className="iris-kvhcte richtext-paragraph richtext-paragraph-empty iris-1v5ii38" />
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    En este Aviso de Privacidad se describe cómo SuiteiOS recopila y procesa tu información personal a través de nuestro sitio web y nuestras aplicaciones. SuiteiOS se refiere a un ecosistema compuesto por el sitio web de SuiteiOS (cuyo nombre de dominio es,&nbsp;
                  </span>
                  <a
                    data-bn-type="link"
                    href="https://www.%25suffixorigin%25/es-AR"
                    target="_blank"
                    className="richtext-link iris-r0junr"
                  >
                    www.suiteios.com/
                  </a>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    ), aplicaciones móviles, clientes y otros servicios asociados desarrollados para ofrecer soluciones de desbloqueo de dispositivos. Este aviso de privacidad se aplica a todas las plataformas, servicios, y aplicaciones ofrecidos dentro del ecosistema SuiteiOS.
                  </span>
                </p>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Al utilizar los servicios de SuiteiOS, das tu consentimiento para que recopilemos, almacenemos, procesemos y transfiramos tu información personal conforme a lo descrito en este Aviso de Privacidad.
                  </span>
                </p>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    SuiteiOS es el responsable del tratamiento de los datos personales recopilados y procesados en relación con la prestación de nuestros servicios de desbloqueo.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>
                      1. ¿Qué datos personales recopila y procesa SuiteiOS? ¿Por qué procesamos tus datos personales?
                    </strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    SuiteiOS solo recopila y utiliza datos personales necesarios para fines específicos. En esta tabla puedes ver (i) la categoría de datos personales que recopilamos, (ii) la finalidad con la que procesamos la información personal, (iii) la base legal que utilizamos para tratar tus datos personales:
                  </span>
                </p>
                <div className="iris-pqikcn">
                  <div className="iris-106k4ya">
                    <div className="bn-table bn-table--border bn-table-ping-right">
                      <div className="bn-table-container">
                        <div className="bn-table-content">
                          <table style={{ tableLayout: "auto" }}>
                            <colgroup />
                            <thead className="bn-table-thead">
                              <tr>
                                <th className="bn-table-cell">
                                  <p className=" richtext-paragraph  iris-1v5ii38">
                                    <span
                                      data-bn-type="text"
                                      className="richtext-text iris-1iqe90x"
                                    >
                                      <strong>
                                        ¿Qué información personal recopila y
                                        procesa SuiteiOS?
                                      </strong>
                                    </span>
                                  </p>
                                </th>
                                <th className="bn-table-cell">
                                  <p className=" richtext-paragraph  iris-1v5ii38">
                                    <span
                                      data-bn-type="text"
                                      className="richtext-text iris-1iqe90x"
                                    >
                                      <strong>
                                      Finalidad del Tratamiento	
                                      </strong>
                                    </span>
                                  </p>
                                </th>
                                <th className="bn-table-cell">
                                  <p className=" richtext-paragraph  iris-1v5ii38">
                                    <span
                                      data-bn-type="text"
                                      className="richtext-text iris-1iqe90x"
                                    >
                                      <strong>
                                        Fundamento legal para el uso de
                                        información personal
                                      </strong>
                                    </span>
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bn-table-tbody">
                              <tr
                                data-row-key={0}
                                className="bn-table-row bn-table-row-level-0"
                              >
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Dirección de correo electrónico
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Nombre
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Número de teléfono
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Nacionalidad
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - ID de dispositivo
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Para operar nuestros servicios, procesar tus órdenes, y comunicarnos contigo en referencia a los servicios contratados.	
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          <strong>
                                            Tratamiento realizado por el
                                            contrato
                                          </strong>
                                        </span>
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          . Necesidad contractual para la prestación del servicio.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                data-row-key={1}
                                className="bn-table-row bn-table-row-level-0"
                              >
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Identificación del dispositivo (IMEI, UDID, Serial).
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Información del dispositivo bloqueado (modelo, iOS version).
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Recibo de compra del dispositivo bloqueado donde incluya
                                          (modelo, IMEI, Serial (Opcional), Nombre completo).
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Para proporcionar el servicio de desbloqueo solicitado, verificar la legitimidad de la solicitud y evitar fraudes.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          <strong>
                                            Nuestro interés legítimo
                                          </strong>
                                        </span>
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          &nbsp;Necesidad contractual para la prestación del servicio y cumplimiento de obligaciones legales.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                data-row-key={2}
                                className="bn-table-row bn-table-row-level-0"
                              >
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Dirección IP
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Datos de inicio de sesión
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Ubicación del dispositivo
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Proteger la seguridad de la plataforma, mejorar el rendimiento de nuestros servicios y prevenir fraudes.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          <strong>
                                            Tratamientos realizados por interés
                                            legítimo y el legítimo interés de
                                            nuestros usuarios
                                          </strong>
                                        </span>
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          &nbsp;Interés legítimo en la protección de la seguridad y mejora continua del servicio.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                data-row-key={2}
                                className="bn-table-row bn-table-row-level-0"
                              >
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Historial de transacciones
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Datos de pago
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Historial de servicios contratados
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Para gestionar tus pagos, prevenir fraudes, cumplir con obligaciones fiscales, y mejorar la experiencia de usuario mediante recomendaciones personalizadas.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          <strong>
                                          Nuestro interés legítimo
                                          </strong>
                                        </span>
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          &nbsp;Necesidad contractual y cumplimiento de obligaciones legales.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                data-row-key={3}
                                className="bn-table-row bn-table-row-level-0"
                              >
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Datos de uso de la aplicación
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Preferencias del usuario
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Métricas de errores técnicos
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          - Para mejorar nuestros servicios, corregir errores, personalizar la experiencia de usuario y ofrecer recomendaciones basadas en tus preferencias.	
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="bn-table-cell">
                                  <div className="iris-1yx6mni">
                                    <div className="iris-u1wi5n">
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          <strong>
                                            Nuestro interés legítimo
                                          </strong>
                                        </span>
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          &nbsp;Interés legítimo en la mejora de servicios y personalización de la experiencia de usuario.
                                        </span>
                                      </p>
                                      <p className=" richtext-paragraph  iris-1v5ii38">
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          <strong>Su consentimiento</strong>
                                        </span>
                                        <span
                                          data-bn-type="text"
                                          className="richtext-text iris-1iqe90x"
                                        >
                                          &nbsp;cuando se lo solicitamos para
                                          procesar su información personal con
                                          un fin específico que le hemos
                                          informado. Cuando otorga su
                                          consentimiento para algún fin, puede
                                          retirarlo en cualquier momento e
                                          interrumpiremos el procesamiento de
                                          sus datos para ese fin.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h3
                  data-bn-type="text"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>
                      2. ¿Quién puede usar los servicios de SuiteiOS?
                    </strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    SuiteiOS está destinado exclusivamente para personas mayores de 18 años. No permitimos que ninguna persona menor de edad utilice nuestros servicios.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="3.-¿Qué-sucede-con-las-cookies-y-otros-identificadores?"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>
                      3. ¿Qué sucede con las cookies y otros identificadores?
                    </strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    SuiteiOS utiliza cookies y otras tecnologías de seguimiento para mejorar la experiencia del usuario, proporcionar servicios más personalizados y analizar el uso de nuestros servicios.
                  </span>
                </p>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Dependiendo de las leyes aplicables en la región en la que
                    se encuentra, el cartel sobre las cookies aparecerá en su
                    navegador cuando accede a la pagina de SuiteiOS y le indicará
                    cómo aceptar, rechazar o seleccionar sus preferencias para
                    las cookies.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>
                      4. ¿SuiteiOS comparte mis datos personales con terceros?
                    </strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    SuiteiOS no vende tus datos personales a terceros. Sin embargo, podemos compartir información personal en las siguientes circunstancias:
                  </span>
                </p>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    style={{fontFamily:'Plex'}}
                    className="richtext-text iris-1iqe90x"
                  >
                    <u>Proveedores de servicios externos:</u>
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    &nbsp;Contratamos a terceros para que realicen funciones en nuestro nombre, como el procesamiento de pagos, análisis de datos, soporte técnico, marketing, entre otros. Estos proveedores solo tienen acceso a los datos personales necesarios para llevar a cabo sus funciones, y deben cumplir con nuestras políticas de privacidad y las leyes aplicables.
                  </span>
                </p>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    style={{fontFamily:'Plex'}}
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <u>Transferencias comerciales:</u>
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    &nbsp;En caso de venta o compra de empresas o servicios, los datos de los usuarios pueden transferirse como parte del activo comercial. Estas transferencias estarán sujetas a los términos de este Aviso de Privacidad.
                  </span>
                </p>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    style={{fontFamily:'Plex'}}
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <u>Protección de SuiteiOS y otros:</u>
                  </span>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    &nbsp;Podemos divulgar información personal cuando creemos que es necesario para cumplir con la ley, proteger nuestros derechos, propiedades o la seguridad de nuestros usuarios y terceros.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="5.-Transferencias-internacionales-de-datos-personales"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>
                      5. Transferencias internacionales de datos personales
                    </strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Podemos transferir tus datos fuera del Espacio Económico Europeo (EEE). SuiteiOS implementa medidas adecuadas, como cláusulas contractuales tipo, para garantizar que dicha transferencia cumpla con las normas de protección de datos aplicables.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="6.-¿Cómo-protegemos-tus-datos-personales?"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>6. ¿Cómo protegemos tus datos personales?</strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Tomamos la seguridad de tus datos personales muy en serio. Utilizamos medidas físicas, electrónicas y administrativas diseñadas para proteger tus datos personales frente a accesos no autorizados. Te recomendamos que utilices una contraseña única y segura para tu cuenta de SuiteiOS y que cierres la sesión al terminar de usar un dispositivo compartido.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="7.-¿Cómo-usamos-tus-datos-personales-para-finalidad-de-Marketing?"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>
                      7. ¿Cómo usamos tus datos personales para finalidad de
                      Marketing?
                    </strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Podemos utilizar tus datos personales para proporcionarte recomendaciones personalizadas y mejorar tu experiencia en SuiteiOS. Puedes optar por no recibir comunicaciones de marketing en cualquier momento, ajustando tus preferencias en la configuración de tu cuenta o contactándonos directamente.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="8.-¿A-qué-información-puedo-tener-acceso?"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>8. ¿A qué información puedo tener acceso?</strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Puede acceder a su información, incluido nombre, dirección,
                    opciones de pago, información del perfil e historial de
                    transacciones comunicándose con nuestro equipo.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="9.-¿Qué-derechos-tengo?"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>9. ¿Qué derechos tengo?</strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Tienes varios derechos con respecto a tus datos personales, incluyendo:
                  </span>
                </p>
                <ul className="css-hmwteh iris-197cloy">
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>Derecho de acceso:</strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;tiene derecho de obtener la confirmación de que
                        sus datos son procesados y recibir una copia de estos,
                        así como obtener cierta información relacionada con su
                        procesamiento.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>Derecho de rectificación:</strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;puede solicitar la rectificación de los datos
                        personales que sean inexactos, así como solicitar que se
                        agregue información adicional. Asimismo, puede cambiar
                        su información personal en su Cuenta en cualquier
                        momento.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>Derecho de eliminación:</strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;puede, en algunos casos, solicitar que se eliminen
                        sus datos.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>Derecho de objeción:</strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;puede objetar, por motivos relacionados con su
                        situación particular, el procesamiento de sus datos. Por
                        ejemplo, tiene derecho de objetar la prospección
                        comercial.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>
                          Derecho de limitación del procesamiento:
                        </strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;en determinadas circunstancias, tiene el derecho
                        de limitar el procesamiento de sus datos.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>Derecho de portabilidad:</strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;en algunos casos, puede solicitar recibir los
                        datos que nos haya proporcionado en un formato
                        estructurado, de uso común y legible por máquina o,
                        cuando sea posible, solicitar que comuniquemos sus datos
                        en su nombre directamente a otro controlador de datos.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>Derecho de retiro del consentimiento:</strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;para el procesamiento que exige su consentimiento,
                        tiene el derecho de retirar este consentimiento en
                        cualquier momento. Ejercer este derecho no afecta la
                        legalidad del procesamiento basado en el consentimiento
                        otorgado antes del retiro de este último.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>
                          Derecho de definición de las instrucciones
                          relacionadas con el uso de sus datos personales post
                          mortem:
                        </strong>
                      </span>
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        &nbsp;tiene derecho de definir las instrucciones
                        relacionadas con la retención, eliminación y
                        comunicación de sus datos personales luego de su
                        fallecimiento.
                      </span>
                    </p>
                  </li>
                  <li className="iris-orkytx">
                    <p className=" richtext-paragraph  iris-1v5ii38">
                      <span
                        data-bn-type="text"
                        className="richtext-text iris-1iqe90x"
                      >
                        <strong>
                          Derecho de presentación de quejas a la autoridad de
                          protección de datos correspondiente.
                        </strong>
                      </span>
                    </p>
                  </li>
                </ul>
                <h3
                  data-bn-type="text"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>
                      10. ¿Por cuánto tiempo guarda SuiteiOS mis datos
                      personales?
                    </strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    SuiteiOS conserva tu información personal mientras sea necesario para proporcionar nuestros servicios, cumplir con los fines descritos en este Aviso de Privacidad, y conforme a los requisitos legales aplicables. Esto incluye:
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="11.-Información-de-contacto"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>11. Información de contacto</strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Si tienes preguntas o inquietudes acerca de cómo SuiteiOS recopila y procesa tu información personal, puedes ponerte en contacto con nuestro Oficial de Protección de Datos a través del correo electrónico&nbsp;
                  </span>
                  <a
                    data-bn-type="link"
                    href="mailto:dpo@suiteios.com"
                    target="_blank"
                    className="richtext-link iris-r0junr"
                  >
                    dpo@suiteios.com
                  </a>
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    . Nuestro equipo trabajará para abordar cualquier asunto o inquietud que puedas tener en relación con la protección de tus datos.
                  </span>
                </p>
                <h3
                  data-bn-type="text"
                  id="12.-Condiciones-de-uso,-avisos-y-revisiones"
                  className="iris-en3vm0"
                >
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    <strong>12. Condiciones de uso, avisos y revisiones</strong>
                  </span>
                </h3>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    El uso de los servicios de SuiteiOS está sujeto a este Aviso de Privacidad y a nuestros Términos de Uso. Si tienes alguna preocupación sobre la privacidad en SuiteiOS, te invitamos a contactarnos con una descripción detallada de tu inquietud, y haremos nuestro mejor esfuerzo para resolverla. También tienes el derecho de contactar a la autoridad de protección de datos local en tu país si consideras que tus derechos han sido vulnerados.
                  </span>
                </p>
                <p className=" richtext-paragraph  iris-1v5ii38">
                  <span
                    data-bn-type="text"
                    className="richtext-text iris-1iqe90x"
                  >
                    Dado que nuestro negocio y servicios están en constante evolución, este Aviso de Privacidad puede ser actualizado ocasionalmente. Recomendamos revisar nuestro sitio web regularmente para estar al tanto de las versiones más recientes. Si no estás de acuerdo con los cambios realizados, te solicitamos que dejes de utilizar los servicios de SuiteiOS. El uso continuado de nuestros servicios tras la publicación de una versión actualizada de este Aviso implicará tu aceptación de los cambios y tu acuerdo con el contenido actualizado. A menos que se indique lo contrario, este Aviso de Privacidad se aplica a toda la información que tengamos sobre ti y tu cuenta.
                  </span>
                </p>
                <p className=" richtext-paragraph richtext-paragraph-empty iris-1v5ii38" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    <Footer/>
    </>
  );
}

export default PrivacyPolicy;